export function useBankExts() {
  const data = {
    1: [{ 2: '0022 國庫局' }],
    2: [
      { 4: '0037 營業部' },
      { 5: '0059 公庫部' },
      { 6: '0071 館前分行' },
      { 7: '0093 台南分行' },
      { 8: '0107 台中分行' },
      { 9: '0118 高雄分行' },
      { 10: '0129 基隆分行' },
      { 11: '0130 中興新村分行' },
      { 12: '0141 嘉義分行' },
      { 13: '0152 新竹分行' },
      { 14: '0163 彰化分行' },
      { 15: '0174 屏東分行' },
      { 16: '0185 花蓮分行' },
      { 17: '0196 延平分行' },
      { 18: '0200 中山分行' },
      { 19: '0211 高雄加工出口區分行' },
      { 20: '0222 宜蘭分行' },
      { 21: '0233 台東分行' },
      { 22: '0244 澎湖分行' },
      { 23: '0255 鳳山分行' },
      { 24: '0266 桃園分行' },
      { 25: '0277 板橋分行' },
      { 26: '0288 新營分行' },
      { 27: '0299 苗栗分行' },
      { 28: '0303 豐原分行' },
      { 29: '0314 斗六分行' },
      { 30: '0325 南投分行' },
      { 31: '0336 南門分行' },
      { 32: '0347 公館分行' },
      { 33: '0358 左營分行' },
      { 34: '0369 北投分行' },
      { 35: '0370 霧峰分行' },
      { 36: '0381 金門分行' },
      { 37: '0392 馬祖分行' },
      { 38: '0406 安平分行' },
      { 39: '0417 中壢分行' },
      { 40: '0428 三重分行' },
      { 41: '0439 頭份分行' },
      { 42: '0440 前鎮分行' },
      { 43: '0451 城中分行' },
      { 44: '0462 民權分行' },
      { 45: '0473 潭子分行' },
      { 46: '0484 連城分行' },
      { 47: '0495 員林分行' },
      { 48: '0509 松江分行' },
      { 49: '0510 鼓山分行' },
      { 50: '0521 龍山分行' },
      { 51: '0532 忠孝分行' },
      { 52: '0543 信義分行' },
      { 53: '0554 復興分行' },
      { 54: '0565 三民分行' },
      { 55: '0576 台中港分行' },
      { 56: '0587 羅東分行' },
      { 57: '0598 埔里分行' },
      { 58: '0602 岡山分行' },
      { 59: '0613 新興分行' },
      { 60: '0624 苓雅分行' },
      { 61: '0646 松山分行' },
      { 62: '0657 健行分行' },
      { 63: '0668 中和分行' },
      { 64: '0679 太保分行' },
      { 65: '0680 竹北分行' },
      { 66: '0705 士林分行' },
      { 67: '0716 新莊分行' },
      { 68: '0727 大甲分行' },
      { 69: '0738 新竹科學園區分行' },
      { 70: '0749 樹林分行' },
      { 71: '0750 新店分行' },
      { 72: '0794 黎明分行' },
      { 73: '0808 民生分行' },
      { 74: '0819 永康分行' },
      { 75: '0820 三多分行' },
      { 76: '0853 台北世貿中心分行' },
      { 77: '0864 大安分行' },
      { 78: '0875 華江分行' },
      { 79: '0886 潮州分行' },
      { 80: '0897 蘇澳分行' },
      { 81: '0901 大雅分行' },
      { 82: '0912 楠梓分行' },
      { 83: '0923 台中工業區分行' },
      { 84: '1067 敦化分行' },
      { 85: '1078 南港分行' },
      { 86: '1089 和平分行' },
      { 87: '1090 水湳分行' },
      { 88: '1104 中崙分行' },
      { 89: '1115 土城分行' },
      { 90: '1159 桃園國際機場分行' },
      { 91: '1160 大昌分行' },
      { 92: '1182 五甲分行' },
      { 93: '1193 博愛分行' },
      { 94: '1207 中庄分行' },
      { 95: '1218 平鎮分行' },
      { 96: '1229 仁愛分行' },
      { 97: '1230 南崁分行' },
      { 98: '1241 圓山分行' },
      { 99: '1355 五股分行' },
      { 100: '1366 大里分行' },
      { 101: '1377 安南分行' },
      { 102: '1414 西屯分行' },
      { 103: '1425 天母分行' },
      { 104: '1436 鹿港分行' },
      { 105: '1447 內壢分行' },
      { 106: '1469 台南科學園區分行' },
      { 107: '1470 虎尾分行' },
      { 108: '1481 淡水分行' },
      { 109: '1539 內湖分行' },
      { 110: '1540 嘉北分行' },
      { 111: '1551 東港分行' },
      { 112: '1562 汐止分行' },
      { 113: '1573 梧棲分行' },
      { 114: '1595 小港分行' },
      { 115: '1609 中屏分行' },
      { 116: '1621 群賢分行' },
      { 117: '1643 北大路分行' },
      { 118: '1654 文山分行' },
      { 119: '1702 太平分行' },
      { 120: '1713 德芳分行' },
      { 121: '1724 建國分行' },
      { 122: '1768 屏東農科園區分行' },
      { 123: '1805 新園分行' },
      { 124: '1861 東桃園分行' },
      { 125: '1872 蘆洲分行' },
      { 126: '1919 高雄國際機場分行' },
      { 127: '1931 永吉簡易型分行' },
      { 128: '1942 東門簡易型分行' },
      { 129: '1953 愛國簡易型分行' },
      { 130: '1986 台電簡易型分行' },
      { 131: '2053 北府簡易型分行' },
      { 132: '2189 台北港分行' },
      { 133: '2204 臺中科學園區分行' },
      { 134: '2215 高雄科學園區分行' },
      { 135: '2237 東湖分行' },
      { 136: '2248 高榮分行' },
      { 137: '2259 南港軟體園區分行' },
      { 138: '2260 龍潭分行' },
      { 139: '2271 仁德分行' },
      { 140: '2282 林口分行' },
      { 141: '2293 木柵分行' },
      { 142: '2307 臺南創新園區分行' },
      { 143: '2363 武昌分行' },
      { 144: '2385 臺北分行' },
      { 145: '2396 金山分行' },
      { 146: '2400 信安分行' },
      { 147: '2411 劍潭分行' },
      { 148: '2422 萬華分行' },
      { 149: '2433 板新分行' },
      { 150: '2444 雙和分行' },
      { 151: '2455 南新莊分行' },
      { 152: '2466 桃興分行' },
      { 153: '2477 新明分行' },
      { 154: '2488 六家分行' },
      { 155: '2499 北臺中分行' },
      { 156: '2503 中臺中分行' },
      { 157: '2525 嘉南分行' },
      { 158: '2536 南都分行' },
      { 159: '2558 北高雄分行' },
      { 160: '2569 成功分行' },
      { 161: '2570 北花蓮分行' },
      { 162: '2709 新湖分行' },
      { 163: '2710 五福分行' },
      { 164: '2721 六甲頂分行' },
      { 165: '2787 中都分行' },
      { 166: '2798 臺北國際機場分行' },
      { 167: '2802 新莊副都心分行' },
      { 168: '2835 仁武分行' },
      { 6356: '2891 臺中國際機場分行' },
      { 6357: '2905 臺中精密園區分行' },
      { 6358: '2972 亞矽創新分行' }
    ],
    3: [
      { 170: '0027 基隆分行' },
      { 171: '0038 中和分行' },
      { 172: '0049 南港分行' },
      { 173: '0050 台北分行' },
      { 174: '0061 民權分行' },
      { 175: '0072 古亭分行' },
      { 176: '0083 長安分行' },
      { 177: '0094 士林分行' },
      { 178: '0108 三重分行' },
      { 179: '0119 宜蘭分行' },
      { 180: '0120 羅東分行' },
      { 181: '0131 桃園分行' },
      { 182: '0142 中壢分行' },
      { 183: '0153 石門分行' },
      { 184: '0164 新竹分行' },
      { 185: '0175 竹東分行' },
      { 186: '0186 花蓮分行' },
      { 187: '0197 玉里分行' },
      { 188: '0201 苗栗分行' },
      { 189: '0212 頭份分行' },
      { 190: '0223 豐原分行' },
      { 191: '0234 大甲分行' },
      { 192: '0245 台中分行' },
      { 193: '0256 南投分行' },
      { 194: '0267 員林分行' },
      { 195: '0278 斗六分行' },
      { 196: '0289 北港分行' },
      { 197: '0290 嘉義分行' },
      { 198: '0304 新營分行' },
      { 199: '0315 永康分行' },
      { 200: '0326 台南分行' },
      { 201: '0337 高雄分行' },
      { 202: '0348 岡山分行' },
      { 203: '0359 美濃分行' },
      { 204: '0360 屏東分行' },
      { 205: '0371 台東分行' },
      { 206: '0382 青年分行' },
      { 207: '0393 金門分行' },
      { 208: '0407 澎湖分行' },
      { 209: '0418 營業部' },
      { 210: '0452 和平分行' },
      { 211: '0463 潮州分行' },
      { 212: '0474 彰化分行' },
      { 213: '0485 中山分行' },
      { 214: '0496 永和分行' },
      { 215: '0500 板橋分行' },
      { 216: '0511 鳳山分行' },
      { 217: '0522 湖口分行' },
      { 218: '0533 蘇澳分行' },
      { 219: '0544 新興分行' },
      { 220: '0555 西台中分行' },
      { 221: '0566 虎尾分行' },
      { 222: '0577 仁愛分行' },
      { 223: '0588 忠孝分行' },
      { 224: '0599 中正分行' },
      { 225: '0614 新店分行' },
      { 226: '0625 北台南分行' },
      { 227: '0636 松山分行' },
      { 228: '0647 內湖分行' },
      { 229: '0658 三民分行' },
      { 230: '0669 民雄分行' },
      { 231: '0670 大社分行' },
      { 232: '0692 前鎮分行' },
      { 233: '0706 路竹分行' },
      { 234: '0717 五甲分行' },
      { 235: '0728 太平分行' },
      { 236: '0739 正濱分行' },
      { 237: '0740 敦化分行' },
      { 238: '0762 蘆洲分行' },
      { 239: '0773 北台中分行' },
      { 240: '0784 苓雅分行' },
      { 241: '0795 信義分行' },
      { 242: '0809 土城分行' },
      { 243: '0810 淡水分行' },
      { 244: '0821 草屯分行' },
      { 245: '0832 東台南分行' },
      { 246: '0843 通霄分行' },
      { 247: '0854 學甲分行' },
      { 248: '0865 新莊分行' },
      { 249: '0876 雙和分行' },
      { 250: '0898 白河分行' },
      { 251: '0902 復興分行' },
      { 252: '0913 平鎮分行' },
      { 253: '0935 文山分行' },
      { 254: '0946 中港分行' },
      { 255: '0957 東板橋分行' },
      { 256: '0968 南崁分行' },
      { 257: '0979 建國分行' },
      { 258: '0980 樹林分行' },
      { 259: '0991 東台北分行' },
      { 260: '1002 西三重分行' },
      { 261: '1013 南台中分行' },
      { 262: '1024 長春分行' },
      { 263: '1035 東新竹分行' },
      { 264: '1046 新市分行' },
      { 265: '1057 博愛分行' },
      { 266: '1068 中崙分行' },
      { 267: '1079 華江分行' },
      { 268: '1080 竹北分行' },
      { 269: '1091 安平分行' },
      { 270: '1105 嘉興分行' },
      { 271: '1116 南新莊分行' },
      { 272: '1127 三峽分行' },
      { 273: '1138 沙鹿分行' },
      { 274: '1149 南桃園分行' },
      { 275: '1150 汐止分行' },
      { 276: '1161 萬華分行' },
      { 277: '1172 小港分行' },
      { 278: '1183 新工分行' },
      { 279: '1194 烏日分行' },
      { 280: '1208 西湖分行' },
      { 281: '1219 八德分行' },
      { 282: '1220 北屯分行' },
      { 283: '1231 大安分行' },
      { 284: '1242 北中壢分行' },
      { 285: '1253 高樹分行' },
      { 286: '1264 枋寮分行' },
      { 287: '1275 豐農分行' },
      { 288: '1286 金城分行' },
      { 289: '1297 光復分行' },
      { 290: '1301 左營分行' },
      { 291: '1312 北桃園分行' },
      { 292: '1323 東港分行' },
      { 293: '1334 天母分行' },
      { 294: '1345 泰山分行' },
      { 295: '1356 中科分行' },
      { 296: '1367 大園分行' },
      { 297: '1378 楊梅分行' },
      { 298: '1389 東門分行' },
      { 299: '1404 城東分行' },
      { 300: '1415 松南分行' },
      { 301: '1426 福興分行' },
      { 302: '1437 林口分行' },
      { 303: '1448 西屯分行' },
      { 304: '1459 內壢分行' },
      { 305: '1460 竹南分行' },
      { 306: '1471 安南分行' },
      { 307: '1482 東湖分行' },
      { 308: '1493 楠梓分行' },
      { 309: '1507 大里分行' },
      { 310: '1518 大灣分行' },
      { 311: '1529 潮榮分行' },
      { 312: '1530 大發分行' },
      { 313: '1552 圓山分行' },
      { 314: '1563 工研院分行' },
      { 315: '1574 北三重分行' },
      { 316: '1585 圓通分行' },
      { 317: '1600 石牌分行' },
      { 318: '1611 南屯分行' },
      { 319: '1633 寶中分行' },
      { 320: '1644 中清分行' },
      { 321: '1655 南京東路分行' },
      { 6359: '1666 仁武分行' }
    ],
    4: [
      { 323: '0017 總行' },
      { 324: '0028 中山路分行' },
      { 325: '0039 西門分行' },
      { 326: '0040 延平分行' },
      { 327: '0051 大稻埕分行' },
      { 328: '0062 東門分行' },
      { 329: '0073 松山分行' },
      { 330: '0084 新店分行' },
      { 331: '0095 永和分行' },
      { 332: '0109 三重分行' },
      { 333: '0110 板橋分行' },
      { 334: '0121 基隆分行' },
      { 335: '0132 宜蘭分行' },
      { 336: '0143 蘇澳分行' },
      { 337: '0154 桃園分行' },
      { 338: '0165 中壢分行' },
      { 339: '0176 新竹分行' },
      { 340: '0187 苗栗分行' },
      { 341: '0198 頭份分行' },
      { 342: '0202 豐原分行' },
      { 343: '0213 沙鹿分行' },
      { 344: '0224 台中分行' },
      { 345: '0235 彰化分行' },
      { 346: '0246 員林分行' },
      { 347: '0257 南投分行' },
      { 348: '0268 斗六分行' },
      { 349: '0279 北港分行' },
      { 350: '0280 嘉義分行' },
      { 351: '0291 新營分行' },
      { 352: '0305 台南分行' },
      { 353: '0316 成功分行' },
      { 354: '0327 鳳山分行' },
      { 355: '0338 岡山分行' },
      { 356: '0349 高雄分行' },
      { 357: '0350 新興分行' },
      { 358: '0361 屏東分行' },
      { 359: '0372 潮州分行' },
      { 360: '0383 花蓮分行' },
      { 361: '0394 台東分行' },
      { 362: '0408 澎湖分行' },
      { 363: '0419 南京東路分行' },
      { 364: '0420 北高雄分行' },
      { 365: '0431 大同分行' },
      { 366: '0453 忠孝分行' },
      { 367: '0464 景美分行' },
      { 368: '0475 士林分行' },
      { 369: '0486 汐止分行' },
      { 370: '0497 新莊分行' },
      { 371: '0501 中興分行' },
      { 372: '0512 苓雅分行' },
      { 373: '0545 台北分行' },
      { 374: '0567 營業部' },
      { 375: '0578 南豐原分行' },
      { 376: '0589 羅東分行' },
      { 377: '0590 三民分行' },
      { 378: '0604 城東分行' },
      { 379: '0615 佳里分行' },
      { 380: '0626 中和分行' },
      { 381: '0637 南高雄分行' },
      { 382: '0648 南嘉義分行' },
      { 383: '0659 竹東分行' },
      { 384: '0671 東三重分行' },
      { 385: '0682 南興分行' },
      { 386: '0693 五權分行' },
      { 387: '0707 埔里分行' },
      { 388: '0718 大順分行' },
      { 389: '0729 南勢角分行' },
      { 390: '0730 朴子分行' },
      { 391: '0763 大安分行' },
      { 392: '0774 民權分行' },
      { 393: '0785 東高雄分行' },
      { 394: '0796 東台北分行' },
      { 395: '0800 城內分行' },
      { 396: '0811 建國分行' },
      { 397: '0822 圓山分行' },
      { 398: '0833 信義分行' },
      { 399: '0844 長春分行' },
      { 400: '0855 仁愛分行' },
      { 401: '0866 玉成分行' },
      { 402: '0877 古亭分行' },
      { 403: '0888 長安分行' },
      { 404: '0899 松興分行' },
      { 405: '0903 民族分行' },
      { 406: '0914 復興分行' },
      { 407: '0925 雙連分行' },
      { 408: '0936 民生分行' },
      { 409: '0947 新生分行' },
      { 410: '0958 松江分行' },
      { 411: '0969 永吉分行' },
      { 412: '0981 東新莊分行' },
      { 413: '0992 蘆洲分行' },
      { 414: '1003 前金分行' },
      { 415: '1014 成大分行' },
      { 416: '1025 大里分行' },
      { 417: '1036 海山分行' },
      { 418: '1070 南台中分行' },
      { 419: '1081 埔墘分行' },
      { 420: '1092 慈文分行' },
      { 421: '1106 北寧分行' },
      { 422: '1117 迴龍分行' },
      { 423: '1128 太平分行' },
      { 424: '1139 彰營分行' },
      { 425: '1140 彰儲分行' },
      { 426: '1151 虎尾分行' },
      { 427: '1162 南屯分行' },
      { 428: '1173 西台中分行' },
      { 429: '1184 溪湖分行' },
      { 430: '1195 烏日分行' },
      { 431: '1209 和美分行' },
      { 432: '1210 南桃園分行' },
      { 433: '1221 屏南分行' },
      { 434: '1232 東台南分行' },
      { 435: '1243 北新竹分行' },
      { 436: '1254 復旦分行' },
      { 437: '1265 竹山分行' },
      { 438: '1276 前鎮分行' },
      { 439: '1287 灣內分行' },
      { 440: '1298 路竹分行' },
      { 441: '1302 憲德分行' },
      { 442: '1313 竹北分行' },
      { 443: '1324 六合分行' },
      { 444: '1335 五洲分行' },
      { 445: '1346 臺大分行' },
      { 446: '1368 龜山分行' },
      { 447: '1379 大溪分行' },
      { 448: '1380 龍潭分行' },
      { 449: '1391 中原分行' },
      { 450: '1405 三興分行' },
      { 451: '1416 敦化分行' },
      { 452: '1427 石牌分行' },
      { 453: '1438 西屯分行' },
      { 454: '1449 雙和分行' },
      { 455: '1450 土城分行' },
      { 456: '1461 光華分行' },
      { 457: '1472 北台南分行' },
      { 458: '1483 興鳳分行' },
      { 459: '1494 北屯分行' },
      { 460: '1508 一心路分行' },
      { 461: '1519 三峽分行' },
      { 462: '1520 北嘉義分行' },
      { 463: '1531 永康分行' },
      { 464: '1542 平鎮分行' },
      { 465: '1553 大發分行' },
      { 466: '1564 內湖分行' },
      { 467: '1575 光復分行' },
      { 468: '1586 二重分行' },
      { 469: '1601 北台中分行' },
      { 470: '1656 軍功分行' },
      { 471: '1715 永安分行' },
      { 472: '1726 中權分行' },
      { 473: '1759 太原分行' },
      { 474: '1760 松竹分行' },
      { 475: '1807 進化分行(客戶移撥太原分行服務)' },
      { 476: '1852 中清分行' },
      { 477: '1885 建成分行' },
      { 478: '1900 朝馬分行' },
      { 479: '1966 東台中分行' },
      { 480: '1988 美村分行' },
      { 481: '2000 黎明分行' },
      { 482: '2033 昌平分行' },
      { 483: '2044 精武分行' },
      { 484: '2055 文心分行' },
      { 485: '2077 神岡分行' },
      { 486: '3029 彰中分行(客戶移撥彰儲分行服務)' },
      { 487: '3030 大竹分行' },
      { 488: '3074 林內分行' },
      { 489: '3100 南港分行' },
      { 490: '3111 伸港分行' },
      { 491: '3122 五股分行' },
      { 492: '3133 樹林分行' },
      { 493: '3155 林口分行' },
      { 494: '3166 左營分行' },
      { 495: '3177 泰山分行' },
      { 496: '3199 大坪林分行' },
      { 497: '3373 鶯歌分行' },
      { 498: '3395 鹿港分行' },
      { 499: '3409 新樹分行' },
      { 500: '3410 六家分行' },
      { 501: '3421 大雅分行' },
      { 502: '3443 八德分行' },
      { 503: '3454 吉林分行(客戶移撥五洲分行服務)' },
      { 504: '3465 鼓山分行' },
      { 505: '3476 大社分行' },
      { 506: '3487 南京西路分行(客戶移撥南京東路分行服務)' },
      { 507: '3498 逢甲分行' },
      { 508: '3502 立德分行' },
      { 509: '3513 林園分行' },
      { 510: '3524 高雄科技園區分行' },
      { 511: '3535 蘆竹分行' },
      { 512: '3546 北中和分行' },
      { 513: '3557 新湖分行' },
      { 514: '3568 丹鳳分行' },
      { 515: '3580 旗山分行' },
      { 516: '3591 大園分行' },
      { 517: '3605 南土城分行' },
      { 518: '3616 潭子分行' },
      { 519: '3627 后里分行' },
      { 520: '3638 長庚分行' },
      { 521: '3649 楠梓分行' },
      { 522: '5012 自強分行' },
      { 523: '5023 中山分行' },
      { 524: '5034 敦南分行' },
      { 525: '5045 中崙分行' },
      { 526: '5056 世貿分行' },
      { 527: '5067 延吉分行(客戶移撥仁愛分行服務)' },
      { 528: '5078 光復南路分行' },
      { 529: '5089 北士林分行' },
      { 530: '5090 信維分行' },
      { 531: '5104 西湖分行' },
      { 532: '5115 大湖分行' },
      { 533: '5126 大橋分行(客戶移撥大同分行服務)' },
      { 534: '5137 國醫中心分行' },
      { 535: '5159 南門分行' },
      { 536: '5160 新中分行' },
      { 537: '5171 中港分行' },
      { 538: '5182 衛道分行' },
      { 539: '5193 忠明南路分行' },
      { 540: '5207 東基隆分行' },
      { 541: '5218 赤崁分行' },
      { 542: '5229 府城分行' },
      { 543: '5230 開元分行' },
      { 544: '5241 港都分行' },
      { 545: '5252 七賢分行' },
      { 546: '5263 大港埔分行(客戶移撥東高雄服務)' },
      { 547: '5274 十全分行' },
      { 548: '5285 九如分行' },
      { 549: '5296 小港分行' },
      { 550: '5300 北三峽分行' },
      { 551: '5311 板新分行' },
      { 552: '5322 南三重分行' },
      { 553: '5333 北新分行' },
      { 554: '5344 漳和分行(客戶移撥雙和分行服務)' },
      { 555: '5355 寶橋分行' },
      { 556: '5366 新泰分行' },
      { 557: '5377 北土城分行' },
      { 558: '5388 南汐止分行' },
      { 559: '5399 北樹林分行' },
      { 560: '5403 五股工業區分行' },
      { 561: '5414 北羅東分行' },
      { 562: '5425 礁溪分行' },
      { 563: '5436 東桃園分行' },
      { 564: '5447 壢新分行' },
      { 565: '5458 南崁分行' },
      { 566: '5470 新明分行' },
      { 567: '5481 楊梅分行' },
      { 568: '5492 東台東分行' },
      { 569: '5517 東竹北分行' },
      { 570: '5528 北苗栗分行' },
      { 571: '5539 竹南分行' },
      { 572: '5540 豐中分行' },
      { 573: '5551 北大里分行' },
      { 574: '5562 東沙鹿分行' },
      { 575: '5573 草屯分行' },
      { 576: '5584 集集分行' },
      { 577: '5595 東埔里分行' },
      { 578: '5609 員新分行' },
      { 579: '5610 南彰化分行' },
      { 580: '5621 北斗分行' },
      { 581: '5632 竹塹分行' },
      { 582: '5643 新竹科學園區分行' },
      { 583: '5665 雲林分行' },
      { 584: '5687 北朴子分行' },
      { 585: '5698 北新營分行' },
      { 586: '5702 仁德分行' },
      { 587: '5713 南永康分行' },
      { 588: '5735 鳳松分行' },
      { 589: '5746 北岡山分行' },
      { 590: '5757 美濃分行' },
      { 591: '5768 大樹分行' },
      { 592: '5816 東港分行' },
      { 593: '5827 萬丹分行' },
      { 594: '5838 枋寮分行' },
      { 595: '5849 東嘉義分行' },
      { 596: '5850 北花蓮分行' },
      { 597: '5908 博愛分行(客戶移撥大直分行服務)' },
      { 598: '5919 大直分行' },
      { 599: '5942 水湳分行' },
      { 600: '6064 仁美分行' },
      { 601: '6097 社皮分行' },
      { 602: '6123 港湖分行' },
      { 603: '9021 五甲分行' },
      { 604: '9043 淡水分行' },
      { 6360: '0660 林口文化分行' }
    ],
    5: [
      { 606: '0937 營業部' },
      { 607: '0948 安和分行' },
      { 608: '1015 南港分行' },
      { 609: '1026 西門分行' },
      { 610: '1037 忠孝路分行' },
      { 611: '1059 東湖分行' },
      { 612: '1060 景美分行' },
      { 613: '1071 大直分行' },
      { 614: '1118 大稻埕分行' },
      { 615: '1130 信維分行' },
      { 616: '1211 建成分行' },
      { 617: '1299 華山分行' },
      { 618: '1314 大同分行' },
      { 619: '1325 光隆分行' },
      { 620: '1336 新生分行' },
      { 621: '1358 劍潭分行' },
      { 622: '1406 圓山分行' },
      { 623: '1417 中山分行' },
      { 624: '1428 中崙分行' },
      { 625: '1439 南京東路分行' },
      { 626: '1440 城東分行' },
      { 627: '1451 民生分行' },
      { 628: '1462 松江分行' },
      { 629: '1473 民權分行' },
      { 630: '1484 八德分行' },
      { 631: '1495 長春分行' },
      { 632: '1509 內湖分行' },
      { 633: '1510 松山分行' },
      { 634: '1521 延吉分行' },
      { 635: '1532 光復分行' },
      { 636: '1554 興雅分行' },
      { 637: '1576 永春分行' },
      { 638: '1587 內科園區分行' },
      { 639: '1598 吉林分行' },
      { 640: '1602 仁愛分行' },
      { 641: '1613 大安分行' },
      { 642: '1624 信義分行' },
      { 643: '1635 復興分行' },
      { 644: '1646 敦化分行' },
      { 645: '1657 仁和分行' },
      { 646: '1668 世貿分行' },
      { 647: '1679 木柵分行' },
      { 648: '1680 松貿分行' },
      { 649: '1691 新湖分行' },
      { 650: '1716 古亭分行' },
      { 651: '1727 南門分行' },
      { 652: '1738 公館分行' },
      { 653: '1761 和平分行' },
      { 654: '1819 萬華分行' },
      { 655: '1831 雙園分行' },
      { 656: '1901 天母分行' },
      { 657: '1912 北投分行' },
      { 658: '1923 士林分行' },
      { 659: '1934 建國分行' },
      { 660: '1945 萬隆分行' },
      { 661: '1956 石牌分行' },
      { 662: '2012 板橋分行' },
      { 663: '2023 華江分行' },
      { 664: '2034 樹林分行' },
      { 665: '2056 土城分行' },
      { 666: '2067 江子翠分行' },
      { 667: '2078 北土城分行' },
      { 668: '2089 林口工二分行' },
      { 669: '2115 三重埔分行' },
      { 670: '2126 長泰分行' },
      { 671: '2137 蘆洲分行' },
      { 672: '2148 頭前分行' },
      { 673: '2159 五股分行' },
      { 674: '2160 重陽分行' },
      { 675: '2171 五股工業區分行' },
      { 676: '2182 淡水分行' },
      { 677: '2218 新店分行' },
      { 678: '2229 大坪林分行' },
      { 679: '2300 泰山分行' },
      { 680: '2311 新莊分行' },
      { 681: '2322 鶯歌分行' },
      { 682: '2333 中和分行' },
      { 683: '2344 永和分行' },
      { 684: '2355 雙和分行' },
      { 685: '2366 連城分行' },
      { 686: '2377 瑞芳分行' },
      { 687: '2388 埔墘分行' },
      { 688: '2399 丹鳳分行' },
      { 689: '2414 基隆分行' },
      { 690: '2436 哨船頭分行' },
      { 691: '2458 汐止分行' },
      { 692: '2469 汐科分行' },
      { 693: '2470 幸福分行' },
      { 694: '2517 宜蘭分行' },
      { 695: '2610 羅東分行' },
      { 696: '2621 蘇澳分行' },
      { 697: '2713 桃園分行' },
      { 698: '2724 北桃分行' },
      { 699: '2735 中正分行' },
      { 700: '2791 大湳分行' },
      { 701: '2805 內壢分行' },
      { 702: '2816 中壢分行' },
      { 703: '2827 西壢分行' },
      { 704: '2838 平鎮分行' },
      { 705: '2849 大園分行' },
      { 706: '2850 南崁分行' },
      { 707: '2872 迴龍分行' },
      { 708: '2908 林口分行' },
      { 709: '2919 大溪分行' },
      { 710: '2920 龍潭分行' },
      { 711: '3019 新竹分行' },
      { 712: '3020 東門分行' },
      { 713: '3031 竹科分行' },
      { 714: '3112 竹東分行' },
      { 715: '3123 關西分行' },
      { 716: '3134 竹北分行' },
      { 717: '3215 苗栗分行' },
      { 718: '3318 竹南分行' },
      { 719: '3329 頭份分行' },
      { 720: '4016 台中分行' },
      { 721: '4027 南台中分行' },
      { 722: '4038 北台中分行' },
      { 723: '4049 中港分行' },
      { 724: '4050 北屯分行' },
      { 725: '4061 進化分行' },
      { 726: '4072 南屯分行' },
      { 727: '4119 豐原分行' },
      { 728: '4120 大里分行' },
      { 729: '4153 中科分行' },
      { 730: '4212 東勢分行' },
      { 731: '4223 沙鹿分行' },
      { 732: '4234 大甲分行' },
      { 733: '4245 太平分行' },
      { 734: '4256 清水分行' },
      { 735: '4267 大雅分行' },
      { 736: '4315 南投分行' },
      { 737: '4418 草屯分行' },
      { 738: '4429 埔里分行' },
      { 739: '4511 彰化分行' },
      { 740: '4614 員林分行' },
      { 741: '4625 鹿港分行' },
      { 742: '4636 溪湖分行' },
      { 743: '4647 北斗分行' },
      { 744: '4717 和美分行' },
      { 745: '5013 嘉義分行' },
      { 746: '5035 興嘉分行' },
      { 747: '5116 朴子分行' },
      { 748: '5219 斗六分行' },
      { 749: '5312 北港分行' },
      { 750: '5323 西螺分行' },
      { 751: '5334 虎尾分行' },
      { 752: '6010 台南分行' },
      { 753: '6021 運河分行(客戶移撥台南分行服務)' },
      { 754: '6043 富強分行' },
      { 755: '6054 赤崁分行' },
      { 756: '6065 竹溪分行' },
      { 757: '6076 金城分行' },
      { 758: '6087 安南分行' },
      { 759: '6113 新營分行' },
      { 760: '6216 鹽水分行' },
      { 761: '6227 麻豆分行' },
      { 762: '6238 善化分行' },
      { 763: '6249 佳里分行' },
      { 764: '6250 新化分行' },
      { 765: '6261 大灣分行' },
      { 766: '6283 南科園區分行' },
      { 767: '6294 歸仁分行' },
      { 768: '6308 永康分行' },
      { 769: '7017 高雄分行' },
      { 770: '7028 鹽埕分行' },
      { 771: '7039 新興分行' },
      { 772: '7040 三民分行' },
      { 773: '7051 苓雅分行' },
      { 774: '7062 左營分行' },
      { 775: '7073 楠梓分行' },
      { 776: '7084 五福分行' },
      { 777: '7095 十全分行' },
      { 778: '7109 前鎮分行' },
      { 779: '7110 灣內分行' },
      { 780: '7121 博愛分行' },
      { 781: '7132 鼎泰分行(客戶移撥博愛分行服務)' },
      { 782: '7143 小港分行' },
      { 783: '7202 五甲分行' },
      { 784: '7213 鳳山分行' },
      { 785: '7305 路竹分行' },
      { 786: '7316 岡山分行' },
      { 787: '7327 旗山分行' },
      { 788: '7338 林園分行' },
      { 789: '7419 屏東分行' },
      { 790: '7512 潮州分行' },
      { 791: '7523 東港分行' },
      { 792: '7534 恆春分行' },
      { 793: '7785 梓本分行' },
      { 794: '7855 萬巒分行' },
      { 795: '8014 花蓮分行' },
      { 796: '8117 台東分行' },
      { 797: '8210 澎湖分行' }
    ],
    6: [
      { 799: '1005 營業部' },
      { 800: '1016 儲蓄分行' },
      { 801: '1027 國外營業部' },
      { 802: '1038 城內分行' },
      { 803: '1049 大稻埕分行' },
      { 804: '1050 建成分行' },
      { 805: '1061 中山分行' },
      { 806: '1072 圓山分行' },
      { 807: '1083 城東分行' },
      { 808: '1094 西門分行' },
      { 809: '1108 南松山分行' },
      { 810: '1119 仁愛路分行' },
      { 811: '1120 南京東路分行' },
      { 812: '1131 新生分行' },
      { 813: '1142 大同分行' },
      { 814: '1153 松山分行' },
      { 815: '1164 中崙分行' },
      { 816: '1175 臺北南門分行' },
      { 817: '1186 公館分行' },
      { 818: '1197 信義分行' },
      { 819: '1201 忠孝東路分行' },
      { 820: '1212 和平分行' },
      { 821: '1223 雙園分行' },
      { 822: '1234 士林分行' },
      { 823: '1245 東台北分行' },
      { 824: '1256 大安分行' },
      { 825: '1267 民生分行' },
      { 826: '1278 復興分行' },
      { 827: '1289 龍江分行' },
      { 828: '1290 永吉分行' },
      { 829: '1304 敦化分行' },
      { 830: '1315 忠興分行' },
      { 831: '1326 大直分行' },
      { 832: '1337 敦和分行' },
      { 833: '1348 東湖分行' },
      { 834: '1360 東興分行' },
      { 835: '1371 北南港分行' },
      { 836: '1382 木柵分行' },
      { 837: '1393 板橋文化分行' },
      { 838: '1430 南內湖分行' },
      { 839: '1452 長安分行' },
      { 840: '1474 懷生分行' },
      { 841: '1485 中華路分行' },
      { 842: '1496 信維分行' },
      { 843: '1511 埔墘分行' },
      { 844: '1522 石牌分行' },
      { 845: '1533 瑞祥分行' },
      { 846: '1544 台大分行' },
      { 847: '1566 世貿分行' },
      { 848: '1577 萬華分行' },
      { 849: '1588 南港分行' },
      { 850: '1599 華江分行' },
      { 851: '1603 板橋分行' },
      { 852: '1614 三重分行' },
      { 853: '1625 北三重分行' },
      { 854: '1636 新莊分行' },
      { 855: '1647 永和分行' },
      { 856: '1658 中和分行' },
      { 857: '1669 新店分行' },
      { 858: '1670 淡水分行' },
      { 859: '1681 汐止分行' },
      { 860: '1692 南永和分行' },
      { 861: '1706 西三重分行' },
      { 862: '1717 南三重分行' },
      { 863: '1728 雙和分行' },
      { 864: '1739 新泰分行' },
      { 865: '1740 二重分行' },
      { 866: '1751 板新分行' },
      { 867: '1762 五股分行' },
      { 868: '1784 北投分行' },
      { 869: '1795 西湖分行' },
      { 870: '1809 積穗分行' },
      { 871: '1821 福和分行' },
      { 872: '1832 南勢角分行' },
      { 873: '1843 北蘆洲分行' },
      { 874: '1854 蘆洲分行' },
      { 875: '1865 土城分行' },
      { 876: '1876 北新分行' },
      { 877: '1898 天母分行' },
      { 878: '1902 內湖分行' },
      { 879: '1913 樹林分行' },
      { 880: '1924 樟樹灣分行' },
      { 881: '1935 泰山分行' },
      { 882: '1946 三峽分行' },
      { 883: '1957 文山分行' },
      { 884: '1968 鶯歌分行' },
      { 885: '1979 北新莊分行' },
      { 886: '1980 北土城分行' },
      { 887: '1991 林口站前分行' },
      { 888: '2002 基隆分行' },
      { 889: '2013 基隆港口分行' },
      { 890: '2116 七堵分行' },
      { 891: '2208 羅東分行' },
      { 892: '2219 宜蘭分行' },
      { 893: '2404 桃園分行' },
      { 894: '2415 中壢分行' },
      { 895: '2426 楊梅分行' },
      { 896: '2437 壢昌分行' },
      { 897: '2448 北桃園分行' },
      { 898: '2459 南崁分行' },
      { 899: '2460 平鎮分行' },
      { 900: '2471 八德分行' },
      { 901: '2482 龜山分行' },
      { 902: '2493 龍潭分行' },
      { 903: '2507 大溪分行' },
      { 904: '2518 內壢分行' },
      { 905: '2529 林口分行' },
      { 906: '2600 觀音分行' },
      { 907: '2622 大園分行' },
      { 908: '3009 新竹分行' },
      { 909: '3010 竹東分行' },
      { 910: '3021 竹科分行' },
      { 911: '3102 新豐分行' },
      { 912: '3135 六家分行' },
      { 913: '3205 竹南分行' },
      { 914: '3216 頭份分行' },
      { 915: '3227 苗栗分行' },
      { 916: '3238 竹北分行' },
      { 917: '3515 大眾分行' },
      { 918: '4006 豐原分行' },
      { 919: '4017 東勢分行' },
      { 920: '4028 清水分行' },
      { 921: '4039 西豐原分行' },
      { 922: '4202 台中分行' },
      { 923: '4224 南台中分行' },
      { 924: '4235 北台中分行' },
      { 925: '4246 台中港路分行' },
      { 926: '4257 大里分行' },
      { 927: '4268 水湳分行' },
      { 928: '4279 五權分行' },
      { 929: '4291 大甲分行' },
      { 930: '4305 太平分行' },
      { 931: '4316 中科分行' },
      { 932: '4512 沙鹿分行' },
      { 933: '5003 草屯分行' },
      { 934: '5014 南投分行' },
      { 935: '5209 彰化分行' },
      { 936: '5210 和美分行' },
      { 937: '5221 員林分行' },
      { 938: '5232 鹿港分行' },
      { 939: '5243 溪湖分行' },
      { 940: '5405 斗六分行' },
      { 941: '5416 虎尾分行' },
      { 942: '5427 西螺分行' },
      { 943: '5438 北港分行' },
      { 944: '6000 嘉義分行' },
      { 945: '6011 嘉南分行' },
      { 946: '6022 朴子分行' },
      { 947: '6206 新營分行' },
      { 948: '6217 麻豆分行' },
      { 949: '6228 永康分行' },
      { 950: '6402 台南分行' },
      { 951: '6424 東台南分行' },
      { 952: '6435 西台南分行' },
      { 953: '6446 北台南分行' },
      { 954: '6457 南都分行' },
      { 955: '6468 安南分行' },
      { 956: '6479 仁德分行' },
      { 957: '6480 新市分行' },
      { 958: '6815 金華分行' },
      { 959: '7007 高雄分行' },
      { 960: '7018 東苓分行' },
      { 961: '7029 新興分行' },
      { 962: '7030 高雄三民分行' },
      { 963: '7041 苓雅分行' },
      { 964: '7052 前鎮分行' },
      { 965: '7063 高雄博愛分行' },
      { 966: '7074 南高雄分行' },
      { 967: '7085 東高雄分行' },
      { 968: '7096 大昌分行' },
      { 969: '7100 北高雄分行' },
      { 970: '7111 楠梓分行' },
      { 971: '7122 左營分行' },
      { 972: '7199 岡山分行' },
      { 973: '7203 鳳山分行' },
      { 974: '7214 路竹分行' },
      { 975: '7225 仁武分行' },
      { 976: '7513 籬仔內分行' },
      { 977: '7524 五甲分行' },
      { 978: '7535 光華分行' },
      { 979: '7605 小港分行' },
      { 980: '7650 高雄桂林分行' },
      { 981: '8004 屏東分行' },
      { 982: '8015 內埔分行' },
      { 983: '8026 潮州分行' },
      { 984: '8130 佳冬分行' },
      { 985: '8200 花蓮分行' },
      { 986: '8303 台東分行' },
      { 987: '8886 營運總部分行' }
    ],
    7: [
      { 989: '2200 總行營業部' },
      { 990: '3003 吉林分行' },
      { 991: '3010 城內分行' },
      { 992: '4004 北台中分行' },
      { 993: '4011 南台中分行' },
      { 994: '4028 北屯分行' },
      { 995: '4035 水湳分行' },
      { 996: '4042 南屯分行' },
      { 997: '4059 台中分行' },
      { 998: '4108 基隆分行' },
      { 999: '4115 仁愛分行' },
      { 1000: '4122 東基隆分行' },
      { 1001: '4202 宜蘭分行' },
      { 1002: '4219 羅東分行' },
      { 1003: '5005 北門分行' },
      { 1004: '5012 台北分行' },
      { 1005: '5029 萬華分行' },
      { 1006: '5036 西門分行' },
      { 1007: '5043 永樂分行' },
      { 1008: '5050 建成分行' },
      { 1009: '5067 大同分行' },
      { 1010: '5074 承德分行' },
      { 1011: '5081 中山北路分行' },
      { 1012: '5109 東門分行' },
      { 1013: '5116 古亭分行' },
      { 1014: '5123 五分埔分行' },
      { 1015: '5130 大安分行' },
      { 1016: '5147 城東分行' },
      { 1017: '5154 中崙分行' },
      { 1018: '5161 雙園分行' },
      { 1019: '5178 士林分行' },
      { 1020: '5185 總部分行' },
      { 1021: '5192 松山分行' },
      { 1022: '5203 忠孝東路分行' },
      { 1023: '5210 信義分行' },
      { 1024: '5227 東台北分行' },
      { 1025: '5234 民生分行' },
      { 1026: '5241 西松分行' },
      { 1027: '5258 復興分行' },
      { 1028: '5265 台北世貿中心分行' },
      { 1029: '5272 敦化分行' },
      { 1030: '5289 建國分行' },
      { 1031: '5296 內湖分行' },
      { 1032: '5307 天母分行' },
      { 1033: '5314 中正分行' },
      { 1034: '5321 仁和分行' },
      { 1035: '5338 永春分行' },
      { 1036: '5345 光隆分行' },
      { 1037: '5352 木柵分行' },
      { 1038: '5369 新湖分行' },
      { 1039: '5376 東湖分行' },
      { 1040: '5383 南港科學園區分行' },
      { 1041: '5390 北投分行' },
      { 1042: '5401 和平分行' },
      { 1043: '5418 東興分行' },
      { 1044: '5425 龍潭分行' },
      { 1045: '5432 汐科分行' },
      { 1046: '5456 新樹分行' },
      { 1047: '5463 立德分行' },
      { 1048: '5470 泰山分行' },
      { 1049: '5487 五股工業區分行' },
      { 1050: '5494 南新莊分行' },
      { 1051: '5505 三重埔分行' },
      { 1052: '5512 北三重埔分行' },
      { 1053: '5529 新店分行' },
      { 1054: '5536 永和分行' },
      { 1055: '5543 瑞芳分行' },
      { 1056: '5550 新莊分行' },
      { 1057: '5567 樹林分行' },
      { 1058: '5574 板橋分行' },
      { 1059: '5581 三峽分行' },
      { 1060: '5598 福和分行' },
      { 1061: '5609 光復分行' },
      { 1062: '5616 東三重分行' },
      { 1063: '5623 北新分行' },
      { 1064: '5630 南三重分行' },
      { 1065: '5647 中和分行' },
      { 1066: '5654 西三重分行' },
      { 1067: '5661 汐止分行' },
      { 1068: '5678 雙和分行' },
      { 1069: '5685 淡水分行' },
      { 1070: '5692 楊梅分行' },
      { 1071: '5703 桃園分行' },
      { 1072: '5710 中壢分行' },
      { 1073: '5727 新竹分行' },
      { 1074: '5734 竹東分行' },
      { 1075: '5741 苗栗分行' },
      { 1076: '5758 新竹科學園區分行' },
      { 1077: '5765 北中壢分行' },
      { 1078: '5772 八德分行' },
      { 1079: '5789 竹南分行' },
      { 1080: '5796 南崁分行' },
      { 1081: '5807 苑裡分行' },
      { 1082: '5814 大甲分行' },
      { 1083: '5821 清水分行' },
      { 1084: '5838 豐原分行' },
      { 1085: '5845 東勢分行' },
      { 1086: '5852 霧峰分行' },
      { 1087: '5869 大雅分行' },
      { 1088: '5876 沙鹿分行' },
      { 1089: '5890 太平分行' },
      { 1090: '5901 草屯分行' },
      { 1091: '5918 南投分行' },
      { 1092: '5925 埔里分行' },
      { 1093: '5932 水裡坑分行' },
      { 1094: '5963 大肚分行' },
      { 1095: '5987 竹北分行' },
      { 1096: '6006 彰化分行' },
      { 1097: '6013 鹿港分行' },
      { 1098: '6020 員林分行' },
      { 1099: '6037 溪湖分行' },
      { 1100: '6044 北斗分行' },
      { 1101: '6051 西螺分行' },
      { 1102: '6068 和美分行' },
      { 1103: '6075 大里分行' },
      { 1104: '6099 二林分行' },
      { 1105: '6100 斗六分行' },
      { 1106: '6117 斗南分行' },
      { 1107: '6124 虎尾分行' },
      { 1108: '6131 北港分行' },
      { 1109: '6204 大林分行' },
      { 1110: '6211 嘉義分行' },
      { 1111: '6228 東嘉義分行' },
      { 1112: '6235 新營分行' },
      { 1113: '6242 永康分行' },
      { 1114: '6259 南科樹谷分行' },
      { 1115: '6266 歸仁分行' },
      { 1116: '6402 台南分行' },
      { 1117: '6419 延平分行' },
      { 1118: '6426 西台南分行' },
      { 1119: '6433 東台南分行' },
      { 1120: '6440 南台南分行' },
      { 1121: '6457 安南分行' },
      { 1122: '6506 旗山分行' },
      { 1123: '6513 岡山分行' },
      { 1124: '6520 鳳山分行' },
      { 1125: '8119 高雄分行' },
      { 1126: '8126 七賢分行' },
      { 1127: '8133 鹽埕分行' },
      { 1128: '8140 東高雄分行' },
      { 1129: '8157 南高雄分行' },
      { 1130: '8164 北高雄分行' },
      { 1131: '8171 三民分行' },
      { 1132: '8195 前鎮分行' },
      { 1133: '8206 九如路分行' },
      { 1134: '8213 新興分行' },
      { 1135: '8220 苓雅分行' },
      { 1136: '8237 大順分行' },
      { 1137: '8244 博愛分行' },
      { 1138: '8251 左營分行' },
      { 1139: '8300 屏東分行' },
      { 1140: '8317 東港分行' },
      { 1141: '8324 潮州分行' },
      { 1142: '8348 恆春分行' },
      { 1143: '8404 花蓮分行' },
      { 1144: '8508 台東分行' },
      { 1145: '9269 思源分行' },
      { 1146: '9283 土城分行' },
      { 1147: '9290 北桃園分行' },
      { 1148: '9301 新明分行' },
      { 1149: '9318 埔心分行' },
      { 1150: '9332 西屯分行' },
      { 1151: '9349 大發分行' },
      { 1152: '9356 東林口分行' },
      { 1153: '9523 土庫分行' },
      { 1154: '9547 路竹分行' },
      { 1155: '9554 蘇澳分行' },
      { 1156: '9561 北台南分行' },
      { 1157: '9603 潭子分行' },
      { 1158: '9610 北嘉義分行' },
      { 1159: '9627 北新竹分行' },
      { 1160: '9634 建興分行' },
      { 1161: '9641 三和路分行' },
      { 1162: '9658 吉成分行' },
      { 1163: '9689 林口分行' },
      { 1164: '9696 江翠分行' },
      { 1165: '9714 長安東路分行' },
      { 1166: '9721 晴光分行' },
      { 1167: '9738 大直分行' },
      { 1168: '9752 松江分行' },
      { 1169: '9790 西內湖分行' },
      { 1170: '9801 南港分行' },
      { 1171: '9818 中港分行' },
      { 1172: '9832 蘆洲分行' },
      { 1173: '9849 中華路分行' },
      { 6361: '9363 新林口分行' }
    ],
    8: [
      { 1175: '0026 營業部' },
      { 1176: '0037 儲蓄部' },
      { 1177: '0048 高雄分行' },
      { 1178: '0060 城中分行' },
      { 1179: '0082 台中分行' },
      { 1180: '0093 東台北分行' },
      { 1181: '0107 台南分行' },
      { 1182: '0118 桃園分行' },
      { 1183: '0129 北高雄分行' },
      { 1184: '0130 三重分行' },
      { 1185: '0141 板橋分行' },
      { 1186: '0152 永和分行' },
      { 1187: '0163 員林分行' },
      { 1188: '0185 信義分行' },
      { 1189: '0196 民生分行' },
      { 1190: '0200 忠孝分行' },
      { 1191: '0211 龍山分行' },
      { 1192: '0222 內湖分行' },
      { 1193: '0233 中山分行' },
      { 1194: '0244 仁愛分行' },
      { 1195: '0255 新莊分行' },
      { 1196: '0266 中壢分行' },
      { 1197: '0288 松山分行' },
      { 1198: '0299 新店分行' },
      { 1199: '0303 前金分行' },
      { 1200: '0314 松南分行' },
      { 1201: '0325 蘆洲分行' },
      { 1202: '0336 中和分行' },
      { 1203: '0347 新竹分行' },
      { 1204: '0358 中港分行' },
      { 1205: '0369 士林分行' },
      { 1206: '0370 東台南分行' },
      { 1207: '0381 永康分行' },
      { 1208: '0392 北三重分行' },
      { 1209: '0406 南京東路分行' },
      { 1210: '0417 大里分行' },
      { 1211: '0428 土城分行' },
      { 1212: '0439 世貿分行' },
      { 1213: '0440 承德分行' },
      { 1214: '0451 汐止分行' },
      { 1215: '0462 屏東分行' },
      { 1216: '0473 三民分行' },
      { 1217: '0484 華江分行' },
      { 1218: '0495 豐原分行' },
      { 1219: '0509 鳳山分行' },
      { 1220: '0510 天母分行' },
      { 1221: '0521 樹林分行' },
      { 1222: '0532 基隆分行' },
      { 1223: '0543 楊梅分行' },
      { 1224: '0554 內湖科技園區分行' },
      { 1225: '0565 北中和分行' },
      { 1226: '0576 西湖分行' },
      { 1227: '0598 二重分行' },
      { 1228: '0602 延平分行' },
      { 1229: '0613 南崁分行' },
      { 1230: '0624 南港分行' },
      { 1231: '0635 北新竹分行' },
      { 1232: '0657 宜蘭分行' },
      { 1233: '0668 文山分行' },
      { 1234: '0680 敦北分行' },
      { 1235: '0691 東高雄分行' },
      { 1236: '0705 竹北分行' },
      { 1237: '0716 松江分行' },
      { 1238: '0727 中科分行' },
      { 1239: '0738 觀音分行' },
      { 1240: '0749 北桃園分行' },
      { 1241: '0750 丹鳳分行' },
      { 1242: '0761 北新莊分行' },
      { 1243: '0772 南屯分行' },
      { 6362: '0794 林口分行' },
      { 6363: '0808 竹科分行' },
      { 6364: '0819 永吉分行' }
    ],
    9: [
      { 1245: '2009 營業部' },
      { 1246: '2032 長安東路分行' },
      { 1247: '2102 公庫處' },
      { 1248: '2205 城東分行' },
      { 1249: '2216 農安分行' },
      { 1250: '3006 士林分行' },
      { 1251: '3017 士東分行' },
      { 1252: '3028 瑞光分行' },
      { 1253: '3039 玉成分行' },
      { 1254: '3040 福港分行' },
      { 1255: '3051 忠孝分行' },
      { 1256: '3062 承德分行' },
      { 1257: '3073 龍江分行' },
      { 1258: '3109 延平分行' },
      { 1259: '3202 木柵分行' },
      { 1260: '3213 木新分行' },
      { 1261: '3305 龍山分行' },
      { 1262: '3408 八德分行' },
      { 1263: '3419 永春分行' },
      { 1264: '3420 永吉分行' },
      { 1265: '3501 中山分行' },
      { 1266: '3604 北投分行' },
      { 1267: '3615 石牌分行' },
      { 1268: '3707 大安分行' },
      { 1269: '3800 大同分行' },
      { 1270: '3903 古亭分行' },
      { 1271: '4003 雙園分行' },
      { 1272: '4014 萬華分行' },
      { 1273: '4106 建成分行' },
      { 1274: '4117 市府分行' },
      { 1275: '4209 南港分行' },
      { 1276: '4302 景美分行' },
      { 1277: '4313 興隆分行' },
      { 1278: '4405 內湖分行' },
      { 1279: '4427 文德分行' },
      { 1280: '4508 敦化分行' },
      { 1281: '4542 民生分行' },
      { 1282: '4601 信義分行' },
      { 1283: '4612 莊敬分行' },
      { 1284: '4623 新生簡易型分行' },
      { 1285: '4704 松江分行' },
      { 1286: '4807 和平分行' },
      { 1287: '4900 延吉分行' },
      { 1289: '5103 南門分行' },
      { 1290: '5206 復興分行' },
      { 1291: '5309 西松分行' },
      { 1292: '5402 長安分行' },
      { 1293: '5505 桂林分行' },
      { 1294: '5701 敦和分行' },
      { 1295: '5804 東門分行' },
      { 1296: '5907 中崙分行' },
      { 1297: '6007 基隆路分行' },
      { 1298: '6100 金華分行' },
      { 1299: '6203 松南分行' },
      { 1300: '6214 懷生分行' },
      { 1301: '6306 民權分行' },
      { 1302: '6409 吉林分行' },
      { 1303: '6502 社子分行' },
      { 1304: '6605 港都分行' },
      { 1305: '6683 西湖分行' },
      { 1306: '6694 金城分行(客戶移撥土城分行服務)' },
      { 1307: '6708 萬隆分行' },
      { 1308: '6719 中港分行' },
      { 1309: '6720 新莊分行' },
      { 1310: '6731 桃園分行' },
      { 1311: '6742 安平分行' },
      { 1312: '6764 松隆簡易型分行' },
      { 1313: '6775 埔墘分行' },
      { 1314: '6786 北中壢分行' },
      { 1315: '6797 三重分行' },
      { 1316: '6801 豐原分行' },
      { 1317: '6812 雙和分行' },
      { 1318: '6823 鼓山分行' },
      { 1319: '6845 風城分行' },
      { 1320: '6856 彰化分行' },
      { 1321: '6867 東湖分行' },
      { 1322: '6878 永和分行' },
      { 1323: '6889 岡山簡易型分行' },
      { 1324: '6890 台北一０一分行' },
      { 1325: '7015 雙連分行(客戶移撥營業部服務)' },
      { 1326: '7026 南京東路分行' },
      { 1327: '7037 敦北分行' },
      { 1328: '7048 仁愛分行' },
      { 1329: '7059 高雄分行' },
      { 1330: '7060 中正分行' },
      { 1331: '7071 台中分行' },
      { 1332: '7093 松山分行' },
      { 1333: '7107 土城分行' },
      { 1334: '7118 台南分行' },
      { 1335: '7129 鳳山分行' },
      { 1336: '7130 中壢分行' },
      { 1337: '7152 安和分行' },
      { 1338: '7163 正義分行' },
      { 1339: '7174 大湳分行' },
      { 1340: '7185 嘉義分行' },
      { 1341: '7196 苓雅分行' },
      { 1342: '7211 板橋分行' },
      { 1343: '7222 北台中分行' },
      { 1344: '7233 三民分行' },
      { 1345: '7244 建國分行' },
      { 1346: '7255 新竹分行' },
      { 1347: '7266 新店分行' },
      { 1348: '7277 天母分行' },
      { 1349: '7288 汐止分行' },
      { 1350: '7303 永康分行' },
      { 1351: '7314 城中分行' },
      { 1352: '7336 五股分行' },
      { 1353: '7347 新營分行' },
      { 1354: '7358 屏東分行' },
      { 1355: '7369 前鎮分行' },
      { 1356: '7370 敦南分行' },
      { 1357: '7381 保生分行' },
      { 1358: '7392 員林分行' },
      { 1359: '7406 羅東分行' },
      { 1360: '7417 瑞湖分行' },
      { 1361: '7428 基和簡易型分行(客戶移撥敦和分行服務)' },
      { 1362: '7439 南昌簡易型分行(客戶移撥金華分行服務)' },
      { 1363: '7451 花蓮簡易型分行' },
      { 1364: '7462 竹北分行' },
      { 1365: '7473 南台中分行' },
      { 1366: '7484 博愛分行' },
      { 1367: '7495 蘆洲分行' },
      { 1368: '7509 華江分行' },
      { 1369: '7510 大直分行' },
      { 1370: '7521 樹林分行' },
      { 1371: '7532 基隆分行' },
      { 1372: '7543 竹科分行' },
      { 1373: '7554 林口簡易型分行' },
      { 1374: '7565 新板分行' },
      { 1375: '7576 三峽簡易型分行' },
      { 5153: '7646 國美分行' },
      { 5215: '7668 北屯分行' },
      { 5252: '7587 南崁分行' },
      { 5253: '7598 東台南分行' },
      { 5254: '7602 左營分行' },
      { 5255: '7613 大里分行' },
      { 6365: '7624 得和分行' },
      { 6366: '7635 斗六分行' },
      { 6367: '7657 南勢角分行' },
      { 6368: '7679 淡水分行' },
      { 6369: '7680 西屯分行' },
      { 6370: '7691 北新分行' },
      { 6371: '7705 市政分行' },
      { 6372: '7716 北新莊分行' },
      { 6373: '7727 大雅簡易型分行' }
    ],
    10: [
      { 1377: '0017 館前分行' },
      { 1378: '0028 國外部' },
      { 1379: '0039 南京東路分行' },
      { 1380: '0051 高雄分行' },
      { 1381: '0062 台中分行' },
      { 1382: '0073 台北分行' },
      { 1383: '0095 永和分行' },
      { 1384: '0109 台南分行' },
      { 1385: '0110 忠孝分行' },
      { 1386: '0121 天母分行' },
      { 1387: '0132 西台中分行' },
      { 1388: '0143 東高雄分行' },
      { 1389: '0154 信義分行' },
      { 1390: '0165 光復分行' },
      { 1391: '0176 板橋分行' },
      { 1392: '0187 復興分行' },
      { 1393: '0198 民權分行' },
      { 1394: '0202 大安分行' },
      { 1395: '0213 三重分行' },
      { 1396: '0224 中壢分行' },
      { 1397: '0235 員林分行' },
      { 1398: '0246 新店分行' },
      { 1399: '0257 新莊分行' },
      { 1400: '0268 鳳山分行' },
      { 1401: '0279 安和分行' },
      { 1402: '0280 松江分行' },
      { 1403: '0291 苓雅分行' },
      { 1404: '0305 古亭分行' },
      { 1405: '0316 建成分行' },
      { 1406: '0327 東門分行' },
      { 1407: '0349 桃園分行' },
      { 1408: '0350 豐原分行' },
      { 1409: '0361 嘉義分行' },
      { 1410: '0372 松山分行' },
      { 1411: '0383 前金分行' },
      { 1412: '0394 北三重分行' },
      { 1413: '0408 新竹分行' },
      { 1414: '0419 永康分行' },
      { 1415: '0420 中山分行' },
      { 1416: '0431 埔墘分行' },
      { 1417: '0442 新生分行' },
      { 1418: '0453 中和分行' },
      { 1419: '0464 清水分行' },
      { 1420: '0475 彰化分行' },
      { 1421: '0486 敦北分行' },
      { 1422: '0497 中正分行' },
      { 1423: '0501 五權分行' },
      { 1424: '0512 東台南分行' },
      { 1425: '0523 新興分行' },
      { 1426: '0534 敦南分行' },
      { 1427: '0545 後埔分行' },
      { 1428: '0556 蘆洲分行' },
      { 1429: '0567 西門分行' },
      { 1430: '0578 三民分行' },
      { 1431: '0590 前鎮分行' },
      { 1432: '0604 土城分行' },
      { 1433: '0615 南高雄分行' },
      { 1434: '0626 大同分行' },
      { 1435: '0637 成功分行' },
      { 1436: '0648 中港分行' },
      { 1437: '0659 世貿分行' },
      { 1438: '0660 北桃園分行' },
      { 1439: '0671 善化分行' },
      { 1440: '0682 士林分行' },
      { 1441: '0693 雙和分行' },
      { 1442: '0707 二重分行' },
      { 1443: '0729 花蓮分行' },
      { 1444: '0730 內湖分行' },
      { 1445: '0741 華江分行' },
      { 1446: '0752 竹科分行' },
      { 1447: '0763 南門分行' },
      { 1448: '0774 八德分行' },
      { 1449: '0785 永春分行' },
      { 1450: '0796 萬華分行' },
      { 1451: '1014 屏東分行' },
      { 1452: '1025 台東分行' },
      { 1453: '1036 文山分行' },
      { 1454: '1047 新樹分行' },
      { 1455: '1058 宜蘭分行' },
      { 1456: '1069 南港分行' },
      { 1457: '1070 斗六分行' },
      { 1458: '1081 南投分行' },
      { 1459: '1092 汐止分行' },
      { 1460: '1106 大直分行' },
      { 1461: '1117 左營分行' },
      { 1462: '1128 新營分行' },
      { 1463: '1139 大甲分行' },
      { 1464: '1140 苗栗分行' },
      { 1465: '1151 北投分行' },
      { 1466: '1162 臨安分行' },
      { 1467: '1173 幸福分行' },
      { 1468: '1184 華山分行' },
      { 1469: '1195 東湖分行' },
      { 1470: '1209 岡山分行' },
      { 1471: '1210 臨沂分行' },
      { 1472: '1221 新湖分行' },
      { 1473: '1232 中崙分行' },
      { 1474: '1243 基隆分行' },
      { 1475: '1254 樹林分行' },
      { 1476: '1265 羅東分行' },
      { 1477: '1276 淡水分行' },
      { 1478: '1287 連城分行' },
      { 1479: '1298 石牌分行' },
      { 1480: '1302 南內湖分行' },
      { 1481: '1313 景美分行' },
      { 1482: '1324 大坪林分行' },
      { 1483: '1335 丹鳳分行' },
      { 1484: '1346 林口分行' },
      { 1485: '1357 南崁分行' },
      { 1486: '2011 仁愛分行' },
      { 1487: '2022 大昌分行' },
      { 1488: '2033 民生分行' },
      { 1489: '2044 篤行分行' },
      { 1490: '2055 板東分行' },
      { 1491: '2066 桃興分行' },
      { 1492: '2088 彰泰分行' },
      { 1493: '2099 嘉泰分行' },
      { 1494: '2103 竹城分行' },
      { 1495: '2114 忠誠分行' },
      { 1496: '2125 敦化分行' },
      { 1497: '2147 新泰分行' },
      { 1498: '2158 文心分行' },
      { 1499: '2169 福和分行' },
      { 1500: '2170 四維分行' },
      { 1501: '2181 營業部' },
      { 1502: '2192 豐北分行' },
      { 1503: '2206 重新分行' },
      { 1504: '2217 北中壢分行' },
      { 1505: '2228 城東分行' },
      { 1506: '2239 建國分行' },
      { 1507: '2251 學府分行' },
      { 1508: '2262 北新分行' },
      { 1509: '2273 信安分行' },
      { 1510: '2295 文德分行' },
      { 1511: '2321 中台中分行' },
      { 1512: '2332 水湳分行' },
      { 1513: '2343 市政分行' },
      { 1514: '2354 東台中分行' },
      { 1515: '2365 昌平分行' },
      { 1516: '2376 健行分行' },
      { 1517: '2398 西屯分行' },
      { 1518: '2402 南屯分行' },
      { 1519: '2413 崇德分行' },
      { 1520: '2457 香山分行' },
      { 1521: '2468 竹北分行' },
      { 1522: '2480 同德分行' },
      { 1523: '2527 秀水簡易型分行' },
      { 1524: '2549 彰美分行' },
      { 1525: '2550 太平分行' },
      { 1526: '2561 沙鹿分行' },
      { 1527: '2583 大里分行' },
      { 1528: '2608 文華簡易型分行' },
      { 1529: '2619 大雅分行' },
      { 1530: '2620 潭子簡易型分行' },
      { 1531: '2631 光華分行' },
      { 1532: '2642 西松分行' },
      { 1533: '2653 蘭雅分行' },
      { 1534: '2664 永平分行' },
      { 1535: '2675 和平分行' },
      { 1536: '2686 慶城分行' },
      { 1537: '2697 瑞湖分行' },
      { 1538: '2701 文昌分行' },
      { 1539: '2712 正義分行' },
      { 1540: '2723 新板分行' },
      { 1541: '2734 永貞分行' },
      { 1542: '2778 明誠分行' },
      { 6374: '1368 東林口分行' },
      { 6375: '1379 大湳分行' },
      { 6376: '1380 松勤分行' }
    ],
    11: [
      { 1544: '1017 營業部' },
      { 1545: '1028 公庫部' },
      { 1546: '1039 岡山本洲分行' },
      { 1547: '1040 國外部' },
      { 1548: '1051 信託部' },
      { 1549: '2003 鳳山分行' },
      { 1550: '2025 前金分行' },
      { 1551: '2036 三民分行' },
      { 1552: '2081 左營分行' },
      { 1553: '2106 小港分行' },
      { 1554: '2117 桃園分行' },
      { 1555: '2128 前鎮分行' },
      { 1556: '2139 右昌分行' },
      { 1557: '2140 灣內分行' },
      { 1558: '2151 屏東分行' },
      { 1559: '2162 桂林分行' },
      { 1560: '2173 六合分行' },
      { 1561: '2184 楠梓分行' },
      { 1562: '2195 鼓山分行' },
      { 1563: '2209 建國分行' },
      { 1564: '2210 九如分行' },
      { 1565: '2221 鹽埕簡易型分行' },
      { 1566: '2232 草衙分行' },
      { 1567: '2243 板橋分行' },
      { 1568: '2254 台南分行' },
      { 1569: '2265 三多分行' },
      { 1570: '2276 大發分行' },
      { 1571: '2287 大直分行' },
      { 1572: '2298 台中分行' },
      { 1573: '2313 旗津簡易型分行' },
      { 1574: '2324 南高雄分行' },
      { 1575: '2335 大里分行' },
      { 1576: '2346 新竹分行' },
      { 1577: '2357 市府分行' },
      { 1578: '2379 中和分行' },
      { 1579: '2380 台北分行' },
      { 1580: '2405 明誠分行' },
      { 1581: '2416 北高雄分行' },
      { 1582: '2450 博愛分行' }
    ],
    12: [
      { 1584: '0022 港都分行' },
      { 1585: '0033 楠梓分行' },
      { 1586: '0044 台中分行' },
      { 1587: '0055 忠孝分行' },
      { 1588: '0066 府城分行' },
      { 1589: '0077 國外部' },
      { 1590: '0088 台北復興分行' },
      { 1591: '0099 財富管理處' },
      { 1592: '0103 蘭雅分行' },
      { 1593: '0114 財務部' },
      { 1594: '0125 三重分行' },
      { 1595: '0136 新興分行' },
      { 1596: '0147 桃園分行' },
      { 1597: '0158 中山分行' },
      { 1598: '0169 高雄加工出口區分行' },
      { 1599: '0170 城中分行' },
      { 1600: '0181 北彰化分行' },
      { 1601: '0192 安和分行' },
      { 1602: '0206 竹科新安分行' },
      { 1603: '0217 天母分行' },
      { 1604: '0228 嘉義分行' },
      { 1605: '0239 花蓮分行' },
      { 1606: '0251 國際金融業務分行' },
      { 1607: '0262 北新竹分行' },
      { 1608: '0273 板南分行' },
      { 1609: '0284 潭子分行' },
      { 1610: '0295 桃園國際機場分行' },
      { 1611: '0309 南台北分行' },
      { 1612: '0310 敦南分行' },
      { 1613: '0321 員林分行' },
      { 1614: '0343 永和分行' },
      { 1615: '0354 豐原分行' },
      { 1616: '0365 民生分行' },
      { 1617: '0376 北台中分行' },
      { 1618: '0387 三多分行' },
      { 1619: '0398 中壢分行' },
      { 1620: '0402 三民分行' },
      { 1621: '0413 新莊分行' },
      { 1622: '0424 松南分行' },
      { 1623: '0435 大同分行' },
      { 1624: '0446 南台中分行' },
      { 1625: '0457 鳳山分行' },
      { 1626: '0468 新店分行' },
      { 1627: '0479 東高雄分行' },
      { 1628: '0480 信義分行' },
      { 1629: '0491 基隆分行' },
      { 1630: '0505 永康分行' },
      { 1631: '0516 內湖分行' },
      { 1632: '0527 岡山分行' },
      { 1633: '0538 屏東分行' },
      { 1634: '0550 松山機場分行' },
      { 1635: '0561 土城分行' },
      { 1636: '0572 大稻埕分行' },
      { 1637: '0583 頭份分行' },
      { 1638: '0594 苓雅分行' },
      { 1639: '0608 沙鹿分行' },
      { 1640: '0619 八德分行' },
      { 1641: '0620 宜蘭分行' },
      { 1642: '0631 斗六分行' },
      { 1643: '0642 南投分行' },
      { 1644: '0653 東台南分行' },
      { 1645: '0664 高雄國際機場分行' },
      { 1646: '0675 東內湖分行' },
      { 1647: '0686 太平分行' },
      { 1648: '0697 中和分行' },
      { 1649: '0701 南京東路分行' },
      { 1650: '0712 東台中分行' },
      { 1651: '0723 北高雄分行' },
      { 1652: '0745 南港分行' },
      { 1653: '0756 竹南科學園區分行' },
      { 1654: '0767 中科分行' },
      { 1655: '0778 大甲分行' },
      { 1656: '0789 成功簡易型分行' },
      { 1657: '0790 金門分行' },
      { 1658: '0804 南崁分行' },
      { 1659: '0815 中鋼簡易型分行' },
      { 1660: '0837 榮總分行' },
      { 1661: '0860 駐外交部簡易型分行' },
      { 1662: '1030 仁武簡易型分行' },
      { 1663: '1166 高雄漁港簡易型分行' },
      { 1664: '2015 金控總部分行' },
      { 1665: '2026 台北分行' },
      { 1666: '2037 新竹分行' },
      { 1667: '2048 中台中分行' },
      { 1668: '2059 高雄分行' },
      { 1669: '2060 板橋分行' },
      { 1670: '2071 桃興分行' },
      { 1671: '2082 竹北分行' },
      { 1672: '2107 敦化分行' },
      { 1673: '2129 嘉興分行' },
      { 1674: '2130 台南分行' },
      { 1675: '2141 寶成分行' },
      { 1676: '2152 竹科竹村分行' },
      { 1677: '2163 世貿分行' },
      { 1678: '2196 南三重分行' },
      { 1679: '2200 南彰化分行' },
      { 1680: '2266 城東分行' },
      { 1681: '2277 五福分行' },
      { 1682: '2288 羅東分行' },
      { 1683: '2299 大安分行' },
      { 1684: '2314 城北分行' },
      { 1685: '2325 后里分行' },
      { 1686: '2336 鹿港分行' },
      { 1687: '2347 圓山分行' },
      { 1688: '2358 思源分行' },
      { 1689: '2369 北中壢分行' },
      { 1690: '2370 內湖科學園區分行' },
      { 1691: '2381 雙和分行' },
      { 1692: '2406 衡陽分行' },
      { 1693: '2417 大里分行' },
      { 1694: '2428 台南科學園區分行' }
    ],
    13: [
      { 1696: '0012 營業部' },
      { 1697: '0034 臺中分行' },
      { 1698: '0045 高雄分行' },
      { 6377: '0067 新竹分行' },
      { 6378: '0078 嘉義分行' },
      { 6379: '0090 桃園分行' },
      { 6380: '0104 臺南分行' }
    ],
    14: [
      { 1700: '0017 台北分行' },
      { 1701: '0028 高雄分行' },
      { 1702: '0040 台中分行' }
    ],
    15: [
      { 1704: '0018 營業部' },
      { 1705: '0030 高雄分行' },
      { 1706: '0041 台中分行' },
      { 1707: '0052 新竹分行' },
      { 1708: '0063 台南分行' },
      { 1709: '0074 桃園分行' },
      { 1710: '0085 松江分行' },
      { 1711: '0096 板橋分行' },
      { 1712: '0100 文心分行' },
      { 1713: '0111 信義分行' },
      { 1714: '0122 天母分行' },
      { 1715: '0133 建成分行' },
      { 1716: '0144 中山分行' },
      { 1717: '0166 永福分行' },
      { 1718: '0177 港都分行' },
      { 1719: '0188 中港分行' },
      { 1720: '0199 襄陽分行' },
      { 1721: '0203 復興分行' },
      { 1722: '0214 新興分行' },
      { 1723: '0225 基隆分行' },
      { 1724: '0236 北桃園分行' },
      { 1725: '0247 永和分行' },
      { 1726: '0258 豐原分行' },
      { 1727: '0269 民權分行' },
      { 1728: '0270 府城分行' },
      { 1729: '0281 苓雅分行' },
      { 1730: '0292 松山分行' },
      { 1731: '0306 三重分行' },
      { 1732: '0317 員林分行' },
      { 1733: '0328 士林分行' },
      { 1734: '0339 板新分行' },
      { 1735: '0340 中壢分行' },
      { 1736: '0351 大安分行' },
      { 1737: '0362 竹城分行' },
      { 1738: '0373 嘉義分行' },
      { 1739: '0384 北台中分行' },
      { 1740: '0395 北高雄分行' },
      { 1741: '0409 新莊分行' },
      { 1742: '0410 民生分行' },
      { 1743: '0421 彰化分行' },
      { 1744: '0432 鳳山分行' },
      { 1745: '0443 新店分行' },
      { 1746: '0454 仁德分行' },
      { 1747: '0465 高美館分行' },
      { 1748: '0476 南京分行' },
      { 1749: '0487 屏東分行' },
      { 1750: '0498 清水分行' },
      { 1751: '0502 中正分行' },
      { 1752: '0513 永康分行' },
      { 1753: '0524 羅東分行' },
      { 1754: '0535 敦化分行' },
      { 1755: '0546 中和分行' },
      { 1756: '0568 岡山分行' },
      { 1757: '0579 內湖分行' },
      { 1758: '0580 南港分行' },
      { 1759: '0627 斗六分行' },
      { 1760: '0638 樹林分行' },
      { 1761: '0649 麥寮分行' },
      { 1762: '0650 土城分行' },
      { 1763: '0661 竹科分行' }
    ],
    16: [{ 1765: '0019 美國銀行台北分行' }],
    17: [
      { 1767: '0010 台北分行' },
      { 1768: '0032 高雄分行' },
      { 1769: '0043 台中分行' }
    ],
    18: [{ 1771: '0012 台北分行' }],
    19: [{ 1773: '0005 台北分行' }],
    20: [{ 1775: '0010 台北分行' }],
    21: [
      { 1777: '0019 營業部' },
      { 1778: '0020 高雄分行' },
      { 1779: '0053 松山分行' },
      { 1780: '0064 台中分行' },
      { 1781: '0075 板橋分行' },
      { 1782: '0123 竹北分行' },
      { 1783: '0167 北屯分行' },
      { 1784: '0190 鳳山分行' },
      { 1785: '0204 中港分行' },
      { 1786: '0260 信義分行' },
      { 1787: '0330 北高雄分行' },
      { 1788: '0341 台南分行' },
      { 1789: '0400 松江分行' },
      { 1790: '0411 桃園分行' }
    ],
    22: [
      { 1792: '0011 營業部' },
      { 1793: '0022 竹科分行' },
      { 1794: '0033 高雄分行' },
      { 1795: '0055 台中分行' },
      { 6381: '0066 新竹分行' },
      { 6382: '0077 忠孝敦化分行' }
    ],
    23: [
      { 1797: '0016 吉林分行' },
      { 1798: '0027 中和分行' },
      { 1799: '0038 博愛分行' },
      { 1800: '0049 北桃園分行' },
      { 1801: '0050 南崁分行' },
      { 1802: '0061 西屯分行' },
      { 1803: '0072 忠明分行' },
      { 1804: '0094 金門分行' },
      { 1805: '0108 營業部' },
      { 1806: '0119 大雅分行' },
      { 1807: '0120 仁大分行' },
      { 1808: '0201 仁愛分行' },
      { 1809: '0212 松山分行' },
      { 1810: '0223 建成分行' },
      { 1811: '0234 士林分行' },
      { 1812: '0245 永和分行' },
      { 1813: '0256 新店分行' },
      { 1814: '0267 新莊分行' },
      { 1815: '0278 化成分行' },
      { 1816: '0407 松江分行' },
      { 1817: '0500 台北分行' },
      { 1818: '0603 萬華分行' },
      { 1819: '0614 南台北分行' },
      { 1820: '0706 復興分行' },
      { 1821: '0809 中山分行' },
      { 1822: '0810 建國分行' },
      { 1823: '0821 內湖分行' },
      { 1824: '0902 南京東路分行' },
      { 1825: '1002 忠孝分行' },
      { 1826: '1013 東台北分行' },
      { 1827: '1024 世貿分行' },
      { 1828: '1035 永春分行' },
      { 1829: '1057 南港分行' },
      { 1830: '1105 松南分行' },
      { 1831: '1116 東湖分行' },
      { 1832: '1208 大安分行' },
      { 1833: '1219 雙和分行' },
      { 1834: '1220 錦和分行' },
      { 1835: '1301 五股分行' },
      { 1836: '1312 林口分行' },
      { 1837: '1404 板橋分行' },
      { 1838: '1415 樹林分行' },
      { 1839: '1426 土城分行' },
      { 1840: '1437 迴龍分行' },
      { 1841: '1448 汐止分行' },
      { 1842: '1507 基隆分行' },
      { 1843: '1518 埔墘分行' },
      { 1844: '1529 北三重分行' },
      { 1845: '1530 南三重分行' },
      { 1846: '1541 蘆洲分行' },
      { 1847: '1600 宜蘭分行' },
      { 1848: '1703 羅東分行' },
      { 1849: '1714 蘇澳分行' },
      { 1850: '2906 楊梅分行' },
      { 1851: '2917 湖口分行' },
      { 1852: '3006 桃園分行' },
      { 1853: '3017 大園分行' },
      { 1854: '3028 大溪分行' },
      { 1855: '3109 中壢分行' },
      { 1856: '3110 內壢分行' },
      { 1857: '3121 新明分行' },
      { 1858: '3132 東桃園分行' },
      { 1859: '3154 新屋分行' },
      { 1860: '3202 新竹分行' },
      { 1861: '3213 竹北分行' },
      { 1862: '3224 竹科分行' },
      { 1863: '3305 八德分行' },
      { 1864: '3327 龍潭分行' },
      { 1865: '3408 竹東分行' },
      { 1866: '3501 竹南分行' },
      { 1867: '3512 頭份分行' },
      { 1868: '3604 苗栗分行' },
      { 1869: '4601 豐原分行' },
      { 1870: '4690 后里分行' },
      { 1871: '4704 太平分行' },
      { 1872: '4807 大甲分行' },
      { 1873: '4829 沙鹿分行' },
      { 1874: '4830 烏日分行' },
      { 1875: '4900 台中分行' },
      { 1876: '4911 民權分行' },
      { 1877: '5000 興中分行' },
      { 1878: '5011 北屯分行' },
      { 1879: '5103 南投分行' },
      { 1880: '5114 草屯分行' },
      { 1881: '5206 埔里分行' },
      { 1882: '5217 潭子分行' },
      { 1883: '5309 竹山分行' },
      { 1884: '5402 彰化分行' },
      { 1885: '5413 和美分行' },
      { 1886: '5505 員林分行' },
      { 1887: '5608 北斗分行' },
      { 1888: '5619 二林分行' },
      { 1889: '6605 斗六分行' },
      { 1890: '6708 北港分行' },
      { 1891: '6719 虎尾分行' },
      { 1892: '6801 嘉義分行' },
      { 1893: '6812 民雄分行' },
      { 1894: '6904 新營分行' },
      { 1895: '6915 開元分行' },
      { 1896: '7004 永康分行' },
      { 1897: '7015 學甲分行' },
      { 1898: '7026 善化分行' },
      { 1899: '7037 永大分行' },
      { 1900: '7107 台南分行' },
      { 1901: '7118 仁德分行' },
      { 1902: '7200 成功分行' },
      { 1903: '7211 東台南分行' },
      { 1904: '7303 安平分行' },
      { 1905: '7602 花蓮分行' },
      { 1906: '7705 台東分行' },
      { 1907: '8207 東高雄分行' },
      { 1908: '8300 岡山分行' },
      { 1909: '8403 北鳳山分行' },
      { 1910: '8414 苓雅分行' },
      { 1911: '8506 高雄分行' },
      { 1912: '8517 北高雄分行' },
      { 1913: '8528 大昌分行' },
      { 1914: '8539 前鎮分行' },
      { 1915: '8609 九如分行' },
      { 1916: '8702 三民分行' },
      { 1917: '8805 鳳山分行' },
      { 1918: '8816 大發分行' },
      { 1919: '8908 屏東分行' },
      { 1920: '8919 小港分行' },
      { 1921: '8920 潮州分行' },
      { 5216: '6867 嘉新分行' },
      { 6383: '1323 東林口分行' },
      { 6384: '1482 三峽分行' }
    ],
    24: [
      { 1923: '0018 營業部' },
      { 1924: '0030 桃園分行' },
      { 1925: '0041 中壢分行' },
      { 1926: '0052 竹東分行' },
      { 1927: '0063 竹南分行' },
      { 1928: '0074 苗栗分行' },
      { 1929: '0085 新埔分行' },
      { 1930: '0096 頭份分行' },
      { 1931: '0100 大溪分行' },
      { 1932: '0111 楊梅分行' },
      { 1933: '0122 新屋分行' },
      { 1934: '0133 湖口分行' },
      { 1935: '0144 苑裡分行' },
      { 1936: '0155 大園分行' },
      { 1937: '0166 龍潭分行' },
      { 1938: '0177 竹北分行' },
      { 1939: '0199 三民分行' },
      { 1940: '0203 內壢分行' },
      { 1941: '0214 八德分行' },
      { 1942: '0225 新明分行' },
      { 1943: '0247 公館分行' },
      { 1944: '0258 通霄分行' },
      { 1945: '0269 後龍分行' },
      { 1946: '0270 龜山分行' },
      { 1947: '0281 南崁分行' },
      { 1948: '0306 關西分行' },
      { 1949: '0317 大樹林分行' },
      { 1950: '0328 光復分行' },
      { 1951: '0351 龍岡分行' },
      { 1952: '0362 山子頂分行' },
      { 1953: '0373 埔心分行' },
      { 1954: '0384 新豐分行' },
      { 1955: '0410 三義分行' },
      { 1956: '0421 新莊分行' },
      { 1957: '0454 中正分行' },
      { 1958: '0465 會稽分行' },
      { 1959: '0476 環北分行' },
      { 1960: '0513 新興分行' },
      { 1961: '0524 新社分行' },
      { 1962: '0546 科學園區分行' },
      { 1963: '0557 觀音分行' },
      { 1964: '0579 北新竹分行' },
      { 1965: '0591 平鎮分行' },
      { 1966: '0605 國外部' },
      { 1967: '0649 東內壢分行' },
      { 1968: '0672 建國分行' },
      { 1969: '0683 公西分行' },
      { 1970: '0708 莊敬分行' },
      { 1971: '0719 板橋分行' },
      { 1972: '0731 豐原分行' },
      { 1973: '0742 文心分行' },
      { 1974: '0753 內湖分行' },
      { 1975: '0764 九如分行' },
      { 1976: '0775 台南分行' },
      { 1977: '0786 嘉義分行' },
      { 1978: '0797 南屯分行' },
      { 1979: '0801 神岡分行' },
      { 1980: '0812 東寧分行' },
      { 1981: '0823 東海分行' },
      { 1982: '0867 中和分行' },
      { 1983: '0878 北屯分行' },
      { 1984: '0889 西屯分行' },
      { 1985: '0926 彰化分行' },
      { 1986: '0959 敦北分行' },
      { 1987: '0960 北高雄分行' },
      { 1988: '0971 台中分行' },
      { 1989: '0982 高雄分行' },
      { 1990: '1015 復興分行' },
      { 1991: '1037 中清分行' },
      { 1992: '1060 三多分行' },
      { 1993: '1071 東台南分行' },
      { 1994: '1082 五福分行' },
      { 1995: '1093 南京分行' },
      { 1996: '1118 信義分行' },
      { 1997: '1129 東台北分行' },
      { 1998: '1141 敦化分行' },
      { 1999: '1152 天母分行' },
      { 2000: '1163 仁愛分行' },
      { 2001: '1185 中山分行' },
      { 2002: '1196 古亭分行' },
      { 2003: '1200 大直分行' },
      { 2004: '1211 金山分行' }
    ],
    25: [
      { 2006: '0019 中正分行' },
      { 2007: '0020 營業部' },
      { 2008: '0042 南台中分行' },
      { 2009: '0053 霧峰分行' },
      { 2010: '0064 大甲分行' },
      { 2011: '0075 沙鹿分行' },
      { 2012: '0086 豐原分行' },
      { 2013: '0097 東勢分行' },
      { 2014: '0101 彰化分行' },
      { 2015: '0112 溪湖分行' },
      { 2016: '0123 員林分行' },
      { 2017: '0134 田中分行' },
      { 2018: '0145 北斗分行' },
      { 2019: '0156 二林分行' },
      { 2020: '0167 南投分行' },
      { 2021: '0189 草屯分行' },
      { 2022: '0190 竹山分行' },
      { 2023: '0204 埔里分行' },
      { 2024: '0215 鹿港分行' },
      { 2025: '0226 西台中分行' },
      { 2026: '0237 清水分行' },
      { 2027: '0248 東豐原分行' },
      { 2028: '0259 烏日分行' },
      { 2029: '0271 北台中分行' },
      { 2030: '0307 南豐原分行' },
      { 2031: '0318 和美分行' },
      { 2032: '0330 西屯分行' },
      { 2033: '0341 太平分行' },
      { 2034: '0363 大雅分行' },
      { 2035: '0374 后里分行' },
      { 2036: '0385 潭子分行' },
      { 2037: '0396 社頭分行' },
      { 2038: '0400 永靖分行' },
      { 2039: '0411 花壇分行' },
      { 2040: '0422 秀水分行' },
      { 2041: '0444 南屯分行' },
      { 2042: '0455 大肚分行' },
      { 2043: '0466 神岡分行' },
      { 2044: '0477 內新分行' },
      { 2045: '0488 伸港分行' },
      { 2046: '0499 北太平分行' },
      { 2047: '0503 台中港分行' },
      { 2048: '0514 北員林分行' },
      { 2049: '0525 南陽分行' },
      { 2050: '0536 大竹分行' },
      { 2051: '0570 埤頭分行' },
      { 2052: '0581 四民分行' },
      { 2053: '0592 軍功分行' },
      { 2054: '0617 大慶簡易型分行' },
      { 2055: '0639 北屯分行' },
      { 2056: '0662 埔心分行' },
      { 2057: '0684 台北分行' },
      { 2058: '0710 松山分行' },
      { 2059: '0721 三重分行' },
      { 2060: '0732 高雄分行' },
      { 2061: '0754 龍井分行' },
      { 2062: '0776 林口分行' },
      { 2063: '0787 虎尾分行' },
      { 2064: '0798 苑裡分行' },
      { 2065: '0813 斗南分行' },
      { 2066: '0824 竹南分行' },
      { 2067: '0846 內湖分行' },
      { 2068: '0857 鳳山分行' },
      { 2069: '0868 板橋分行' },
      { 2070: '0879 平鎮分行' },
      { 2071: '0880 新莊分行' },
      { 2072: '0891 民雄分行' },
      { 2073: '0905 桃園分行' },
      { 2074: '0916 永康分行' },
      { 2075: '0927 竹北分行' },
      { 2076: '0938 南崁分行' },
      { 2077: '0949 新竹分行' },
      { 2078: '0950 內壢分行' },
      { 2079: '0961 新豐分行' },
      { 2080: '0972 大園分行' },
      { 2081: '0983 中壢分行' },
      { 2082: '0994 龜山分行' },
      { 2083: '1005 楊梅分行' },
      { 2084: '1016 土城分行' },
      { 2085: '1038 復興分行' },
      { 2086: '1050 中山分行' },
      { 2087: '1061 大同分行' },
      { 6385: '1072 台南分行' },
      { 6386: '1083 新港分行' },
      { 6387: '1094 新店分行' },
      { 6388: '1108 燕巢分行' }
    ],
    26: [
      { 2089: '0010 營業部' },
      { 2090: '0032 台南分行' },
      { 2091: '0043 嘉義分行' },
      { 2092: '0054 新營分行' },
      { 2093: '0065 鹽水分行' },
      { 2094: '0076 虎尾分行' },
      { 2095: '0087 斗六分行' },
      { 2096: '0098 民雄分行' },
      { 2097: '0102 北港分行' },
      { 2098: '0113 麻豆分行' },
      { 2099: '0124 朴子分行' },
      { 2100: '0135 佳里分行' },
      { 2101: '0146 梅山分行' },
      { 2102: '0157 斗南分行' },
      { 2103: '0180 東台南分行' },
      { 2104: '0205 北台南分行' },
      { 2105: '0227 西螺分行' },
      { 2106: '0249 善化分行' },
      { 2107: '0250 學甲分行' },
      { 2108: '0272 開元分行' },
      { 2109: '0283 永康分行' },
      { 2110: '0294 新市分行' },
      { 2111: '0308 仁德分行' },
      { 2112: '0319 六甲分行' },
      { 2113: '0320 安南分行' },
      { 2114: '0331 中埔分行' },
      { 2115: '0342 崙背分行' },
      { 2116: '0353 西港分行' },
      { 2117: '0364 新化分行' },
      { 2118: '0375 竹崎分行' },
      { 2119: '0397 安和分行' },
      { 2120: '0401 新興分行' },
      { 2121: '0412 中華分行' },
      { 2122: '0434 白河分行' },
      { 2123: '0445 興業分行' },
      { 2124: '0467 水上分行' },
      { 2125: '0489 府城分行' },
      { 2126: '0504 歸仁分行' },
      { 2127: '0515 鹽行分行' },
      { 2128: '0537 台北分行' },
      { 2129: '0548 大林分行' },
      { 2130: '0607 關廟分行' },
      { 2131: '0641 太保分行' },
      { 2132: '0652 蘆洲分行' },
      { 2133: '0663 玉井分行' },
      { 2134: '0696 高雄分行' },
      { 2135: '0700 台中分行' },
      { 2136: '0711 內湖分行' },
      { 2137: '0744 桃園分行' },
      { 2138: '0755 雙和分行' },
      { 2139: '0766 岡山分行' },
      { 2140: '0777 中正分行' },
      { 2141: '0788 忠孝分行' },
      { 2142: '0799 北高雄分行' },
      { 2143: '0814 板橋分行' },
      { 2144: '0825 文心分行' },
      { 2145: '0836 松山分行' },
      { 2146: '0847 新莊分行' },
      { 2147: '0858 新竹分行' },
      { 2148: '0869 中壢分行' },
      { 2149: '0870 新店分行' },
      { 2150: '0881 南崁分行' },
      { 2151: '0892 大里分行' },
      { 2152: '0906 楠梓分行' },
      { 2153: '0917 彰化分行' },
      { 6389: '0928 東新竹分行' },
      { 6390: '0939 裕農分行' }
    ],
    27: [{ 2155: '0019 總公司' }],
    28: [
      { 2157: '0010 台北總公司' },
      { 2158: '0021 高雄分公司' },
      { 2159: '0032 台中分公司' },
      { 2160: '0043 台南分公司' },
      { 2161: '0054 桃園分公司' },
      { 2162: '0065 板橋分公司' }
    ],
    29: [
      { 2164: '0011 台北總公司' },
      { 2165: '0022 台中分公司' },
      { 2166: '0033 台南分公司' },
      { 2167: '0044 高雄分公司' },
      { 2168: '0055 嘉義分公司' },
      { 2169: '0066 桃園分公司' },
      { 2170: '0077 板橋分公司' },
      { 2171: '0088 中山分公司' },
      { 2172: '0099 新竹分公司' }
    ],
    30: [{ 2174: '0015 總公司' }],
    31: [{ 2176: '0014 台北分行' }],
    32: [{ 2178: '0006 台北分行' }, { 2179: '0028 高雄分行' }],
    33: [{ 2181: '0018 台北分行' }],
    34: [
      { 2183: '0016 台北分行' },
      { 2184: '0027 高雄分行' },
      { 2185: '0049 台中分行' },
      { 2186: '0050 台南分行' },
      { 2187: '0061 板橋分行' },
      { 2188: '0072 桃園分行' },
      { 2189: '0083 建國分行' },
      { 2190: '0094 天母分行' },
      { 2191: '0108 忠孝分行' },
      { 2192: '0120 光復分行' },
      { 2193: '0131 中山分行' },
      { 2194: '0153 南京東路分行' },
      { 2195: '0164 新板分行' },
      { 2196: '0186 古亭分行' },
      { 2197: '0197 民生分行' },
      { 2198: '0201 南港分行' },
      { 2199: '0212 林口分行' },
      { 2200: '0223 新竹分行' },
      { 2201: '0234 松江分行' },
      { 2202: '0245 永和分行' },
      { 2203: '0267 信義分行' },
      { 2204: '0278 新莊分行' },
      { 2205: '0290 新店分行' },
      { 2206: '0304 安和分行' },
      { 2207: '0315 中壢分行' },
      { 2208: '0326 仁愛分行' },
      { 2209: '0337 敦北分行' },
      { 2210: '0359 大安分行' },
      { 2211: '0360 內湖分行' },
      { 2212: '0371 民權分行' },
      { 2213: '0382 復興分行' },
      { 2214: '0393 中和分行' },
      { 2215: '0500 竹北分行' },
      { 2216: '0511 國美分行' },
      { 2217: '0522 敦南分行' },
      { 2218: '0533 大興分行' },
      { 2219: '0555 左營分行' },
      { 2220: '0566 大直分行' },
      { 2221: '0577 士林分行' },
      { 2222: '0588 蘆洲分行' },
      { 2223: '0599 崇德分行' }
    ],
    35: [
      { 2225: '0017 台北分行' },
      { 2226: '0039 高雄分行' },
      { 2227: '0051 台中分行' }
    ],
    36: [{ 2229: '0009 台北分行' }],
    37: [{ 2231: '0011 台北分行' }],
    38: [
      { 2233: '0010 台北分行' },
      { 2234: '0032 台中分行' },
      { 2235: '0043 高雄分行' }
    ],
    39: [{ 2237: '0011 台北分行' }],
    40: [{ 2239: '0016 台北分行' }],
    41: [
      { 2241: '0011 營業部' },
      { 2242: '0020 民生分行' },
      { 2243: '0039 建成分行' },
      { 2244: '0048 大橋分行' },
      { 2245: '0057 南京東路分行' },
      { 2246: '0066 昆明分行' },
      { 2247: '0075 長安分行' },
      { 2248: '0084 永吉分行' },
      { 2249: '0093 和平東路分行' },
      { 2250: '0109 石牌分行' },
      { 2251: '0118 內湖分行' },
      { 2252: '0127 成功簡易型分行' },
      { 2253: '0136 古亭分行' },
      { 2254: '0145 景美分行' },
      { 2255: '0154 南港分行' },
      { 2256: '0163 信義簡易型分行' },
      { 2257: '0172 萬華分行' },
      { 2258: '0181 士林分行' },
      { 2259: '0190 松山簡易型分行' },
      { 2260: '0206 城內分行' },
      { 2261: '0215 桃園分行' },
      { 2262: '0224 中和分行' }
    ],
    42: [
      { 2264: '0010 營業部' },
      { 2265: '0029 迪化街分行' },
      { 2266: '0038 建成分行' },
      { 2267: '0047 大同分行' },
      { 2268: '0056 中山分行' },
      { 2269: '0065 大安分行' },
      { 2270: '0074 松山分行' },
      { 2271: '0083 古亭分行' },
      { 2272: '0092 士林分行' },
      { 2273: '0108 內湖分行' },
      { 2274: '0117 信義分行' },
      { 2275: '0126 永吉分行' },
      { 2276: '0135 和平分行' },
      { 2277: '0144 光復分行' },
      { 2278: '0153 文山簡易型分行' },
      { 2279: '0171 石牌分行' },
      { 2280: '0180 萬華分行' },
      { 2281: '0199 桃園分行' },
      { 2282: '0205 松德分行' },
      { 2283: '0214 新莊分行' },
      { 2284: '0223 中和分行' },
      { 2285: '0232 板橋分行' },
      { 2286: '0241 南京東路分行' },
      { 2287: '0250 敦化分行' },
      { 2288: '0269 新店分行' },
      { 2289: '0278 中壢分行' },
      { 2290: '0287 高雄分行' },
      { 2291: '0296 三重分行' },
      { 2292: '0302 大直分行' },
      { 2293: '0348 台南分行' },
      { 2294: '0357 北高雄分行' },
      { 2295: '0366 台中分行' },
      { 6391: '0375 彰化分行' },
      { 6392: '0384 北台中分行' }
    ],
    43: [
      { 2297: '0019 中正分行' },
      { 2298: '0028 東台北分行' },
      { 2299: '0037 龍山分行' },
      { 2300: '0046 西園分行' },
      { 2301: '0055 西門分行' },
      { 2302: '0064 大同分行' },
      { 2303: '0073 復興分行' },
      { 2304: '0082 忠孝簡易型分行' },
      { 2305: '0091 五常分行' },
      { 2306: '0107 城北分行' },
      { 2307: '0116 城內分行' },
      { 2308: '0125 新湖分行' },
      { 2309: '0134 松安分行' },
      { 2310: '0143 慶城分行' },
      { 2311: '0152 內湖分行' },
      { 2312: '0161 莊敬分行' },
      { 2313: '0170 松山分行' },
      { 2314: '0189 南港分行' },
      { 2315: '0198 林森北路分行' },
      { 2316: '0204 大安簡易型分行' },
      { 2317: '0213 新竹分行' },
      { 2318: '0222 中壢分行' },
      { 2319: '0231 桃園分行' },
      { 2320: '0240 東三重分行' },
      { 2321: '0259 竹北分行' },
      { 2322: '0268 連城路分行' },
      { 2323: '0277 竹科分行' },
      { 2324: '0286 土城分行' },
      { 2325: '0295 蘆洲分行' },
      { 2326: '0301 建成分行' },
      { 2327: '0310 北三重分行' },
      { 2328: '0329 永和分行' },
      { 2329: '0338 新埔分行' },
      { 2330: '0347 台中分行' },
      { 2331: '0356 中港分行' },
      { 2332: '0365 左營華夏路分行' },
      { 2333: '0374 大甲分行' },
      { 2334: '0383 大墩分行' },
      { 2335: '0392 員林分行' },
      { 2336: '0408 南屯分行' },
      { 2337: '0417 東台南分行' },
      { 2338: '0426 大里分行' },
      { 2339: '0435 松竹分行' },
      { 2340: '0444 彰化分行' },
      { 2341: '0453 樹林分行' },
      { 2342: '0462 後埔分行' },
      { 2343: '0471 古亭分行' },
      { 2344: '0480 士林分行' },
      { 2345: '0499 營業部' },
      { 2346: '0505 丹鳳分行' },
      { 2347: '0514 北投復興崗分行' },
      { 2348: '0523 新生南路分行' },
      { 2349: '0541 新營分行' },
      { 2350: '0550 天母分行' },
      { 2351: '0569 新店分行' },
      { 2352: '0578 大直分行' },
      { 2353: '0587 興隆簡易型分行' },
      { 2354: '0596 八德分行' },
      { 2355: '0611 長安分行' },
      { 2356: '0620 嘉義分行' },
      { 2357: '0648 鳳山分行' },
      { 2358: '0657 台南分行' },
      { 2359: '0666 北嘉義簡易型分行' },
      { 2360: '0675 斗六分行' },
      { 2361: '0684 竹南分行' },
      { 2362: '0693 花蓮分行' },
      { 2363: '0709 基隆分行' },
      { 2364: '0718 宜蘭分行' },
      { 2365: '0727 三峽分行' },
      { 2366: '0736 路竹簡易型分行' },
      { 2367: '0745 高雄分行' },
      { 2368: '0754 新莊分行' },
      { 2369: '0763 江子翠分行' },
      { 2370: '0772 林口分行' },
      { 2371: '0781 彌陀簡易型分行' },
      { 2372: '0790 岡山分行' },
      { 2373: '0806 北高雄分行' },
      { 2374: '0824 小港分行' },
      { 2375: '0833 中華分行' },
      { 2376: '0842 承德分行' },
      { 2377: '0851 敦南分行' },
      { 2378: '0860 中和分行' },
      { 2379: '0879 南台中分行' },
      { 2380: '0888 水湳分行' },
      { 2381: '0897 北屯分行' },
      { 2382: '0903 西屯分行' },
      { 2383: '0912 向上分行' },
      { 2384: '0921 十甲分行' },
      { 2385: '0930 豐原分行' },
      { 2386: '0949 永安分行' },
      { 2387: '0958 屏東分行' },
      { 2388: '0967 東園分行' },
      { 2389: '0976 萬丹分行' },
      { 2390: '0985 七賢分行' },
      { 2391: '0994 南崁分行' },
      { 2392: '1003 汐止分行' },
      { 2393: '1012 桃北分行' },
      { 2394: '1021 東新竹分行' },
      { 2395: '1030 九如分行' },
      { 2396: '1049 永康分行' },
      { 2397: '1058 壢新分行' },
      { 2398: '1067 沙鹿分行' },
      { 2399: '1076 大雅分行' },
      { 2400: '1094 草屯分行' },
      { 2401: '1100 南東分行' }
    ],
    44: [
      { 2403: '0018 營業部' },
      { 2404: '0027 吉林分社' },
      { 2405: '0036 大同分社' },
      { 2406: '0045 中山分社' },
      { 2407: '0054 松山分社' },
      { 2408: '0063 大安分社' },
      { 2409: '0072 石牌分社' },
      { 2410: '0081 南港分社' },
      { 2411: '0090 文山分社' },
      { 2412: '0106 中正分社' },
      { 2413: '0115 北投分社' },
      { 2414: '0124 內湖分社' },
      { 2415: '0133 天母分社' }
    ],
    45: [
      { 2417: '0014 營業部' },
      { 2418: '0023 石牌分行' },
      { 2419: '0032 北投分行' },
      { 2420: '0041 社子分行' },
      { 2421: '0050 士林分行' },
      { 2422: '0069 大屯分行' },
      { 2423: '0078 劍潭分行' },
      { 2424: '0087 蘭雅分行' },
      { 2425: '0096 天母分行' },
      { 2426: '0102 社中分行' },
      { 2427: '0111 吉林分行' },
      { 2428: '0120 成功分行' },
      { 2429: '0139 民生分行' },
      { 2430: '0148 延吉分行' },
      { 2431: '0157 木柵分行' },
      { 2432: '0166 龍江分行' },
      { 2433: '0175 南京分行' },
      { 2434: '0184 景美分行' },
      { 2435: '0193 中興分行' },
      { 2436: '0209 信義分行' },
      { 2437: '0218 中和分行' },
      { 2438: '0227 永和分行' },
      { 2439: '0236 蘆洲分行' },
      { 2440: '0245 板橋分行' },
      { 2441: '0263 新和簡易型分行' },
      { 2442: '0272 溪洲分行' },
      { 2443: '0281 泰山分行' },
      { 2444: '0290 新莊分行' },
      { 2445: '0306 古亭分行' },
      { 2446: '0315 三重分行' },
      { 2447: '0324 大業簡易型分行' },
      { 2448: '0333 雙和分行' },
      { 2449: '0342 復興分行' },
      { 2450: '0351 大安分行' },
      { 2451: '0360 桃園分行' },
      { 2452: '0379 新店分行' },
      { 2453: '0397 新福簡易型分行' },
      { 2454: '0403 新竹分行' },
      { 2455: '0412 員林分行' },
      { 2456: '0421 花蓮分行' },
      { 2457: '0430 精武分行' },
      { 2458: '0449 社頭簡易型分行' },
      { 2459: '0458 屏東分行' },
      { 2460: '0467 中正簡易型分行' },
      { 2461: '0476 高雄分行' },
      { 2462: '0485 苓雅分行' },
      { 2463: '0494 北屯分行' },
      { 2464: '0500 嘉義分行' },
      { 2465: '0519 新埔分行' },
      { 2466: '0528 台南分行' },
      { 2467: '0555 東寧簡易型分行' },
      { 2468: '0564 中華分行' },
      { 2469: '0573 健康分行' },
      { 2470: '0582 台中分行' },
      { 2471: '0607 西華分行' },
      { 2472: '0616 安順分行' },
      { 2473: '0634 左營分行' },
      { 2474: '0643 向上分行' },
      { 2475: '0652 內湖分行' },
      { 2476: '0661 中壢分行' },
      { 2477: '0670 五股分行' },
      { 2478: '0689 林森分行' },
      { 2479: '0698 立文分行' },
      { 2480: '0704 新興分行' },
      { 2481: '0713 青年分行' },
      { 2482: '0722 三鳳分行' },
      { 2483: '0731 四維分行' },
      { 2484: '0740 大公分行' },
      { 2485: '0759 大順分行' },
      { 2486: '0768 海光分行' },
      { 2487: '0777 前鎮分行' },
      { 2488: '0786 平等分行' },
      { 2489: '0795 基隆分行' },
      { 2490: '0801 小港分行' },
      { 2491: '0810 右昌分行' },
      { 2492: '0829 建國分行' },
      { 2493: '0838 五甲分行' },
      { 2494: '0847 鼎力分行' },
      { 2495: '0856 楠梓分行' },
      { 2496: '0865 旗山分行' },
      { 2497: '0874 土城分行' },
      { 2498: '0883 美濃簡易型分行' },
      { 2499: '0917 林園簡易型分行' },
      { 2500: '0926 岡山分行' },
      { 2501: '0935 仁德分行' },
      { 2502: '0944 東桃園分行' },
      { 2503: '0953 永康簡易型分行' },
      { 2504: '0971 南港分行' },
      { 2505: '0980 里港簡易型分行' },
      { 2506: '0999 大里分行' },
      { 2507: '1017 萬華分行' },
      { 2508: '1026 臺北分行' },
      { 2509: '1035 長安分行' },
      { 2510: '1053 羅東分行' },
      { 2511: '1062 竹北分行' },
      { 2512: '1071 重新分行' },
      { 2513: '1080 彰化分行' },
      { 2514: '1336 苗栗分行' },
      { 2515: '1354 龍井分行' },
      { 2516: '1363 雲林分行' },
      { 2517: '1372 南投分行' },
      { 5256: '1503 臺東分行' },
      { 5257: '1512 宜蘭分行' },
      { 6393: '1567 林口分行' }
    ],
    46: [
      { 2519: '0015 營業部' },
      { 2520: '0024 仁一路分社' },
      { 2521: '0033 愛三路分社' },
      { 2522: '0042 忠二路分社' },
      { 2523: '0051 安一路分社' },
      { 2524: '0060 信二路分社' },
      { 2525: '0079 中華路分社' },
      { 2526: '0088 八斗子分社' },
      { 2527: '0097 南興路分社' },
      { 2528: '0103 大武崙分社' },
      { 2529: '0112 碇內分社' },
      { 2530: '0121 仁二路分社' },
      { 2531: '0130 百福分社' },
      { 2532: '0149 松山分社' },
      { 6426: '0158 永春分社' }
    ],
    47: [
      { 2534: '0014 營業部（公誠證券收付處）' },
      { 2535: '0023 廟口分社' },
      { 2536: '0032 中正區分社' },
      { 2537: '0041 新店分社（國際證券收付處）' },
      { 2538: '0050 七堵分社' },
      { 2539: '0069 安樂分社' },
      { 2540: '0078 信義分社' },
      { 2541: '0087 港東簡易型分社' },
      { 2542: '0096 暖暖分社' },
      { 2543: '0102 安和分社' },
      { 2544: '0111 臺北分社' },
      { 2545: '0120 百福分社' },
      { 2546: '0139 基金簡易型分社' },
      { 2547: '0148 新豐簡易型分社' },
      { 2548: '0157 復興簡易型分社' }
    ],
    48: [
      { 2550: '0011 板橋分行' },
      { 2551: '0020 後埔分行' },
      { 2552: '0039 永和分行' },
      { 2553: '0048 埔墘分行' },
      { 2554: '0057 華江分行' },
      { 2555: '0066 民族分行' },
      { 2556: '0075 中和分行' },
      { 2557: '0084 土城分行' },
      { 2558: '0093 文化分行' },
      { 2559: '0109 大觀分行' },
      { 2560: '0118 興南分行' },
      { 2561: '0145 小港分行' },
      { 2562: '0163 新興分行' },
      { 2563: '0181 前鎮分行' },
      { 2564: '0206 陽明分行' },
      { 2565: '0215 高新莊分行' },
      { 2566: '0260 松江分行' },
      { 2567: '0279 信義分行' },
      { 2568: '0288 新莊分行' },
      { 2569: '0297 秀朗分行' },
      { 2570: '0303 桃園分行' },
      { 2571: '0312 三重分行' },
      { 2572: '0330 員山分行' },
      { 2573: '0349 桃鶯分行' },
      { 2574: '0358 民生分行' },
      { 2575: '0367 龍岡分行' },
      { 2576: '0385 樹林分行' },
      { 2577: '0394 金城分行' },
      { 2578: '0400 新竹分行' },
      { 2579: '0419 八德分行' },
      { 2580: '0428 台中分行' },
      { 2581: '0437 台南分行' },
      { 2582: '0446 新店分行' },
      { 2583: '0455 內湖分行' },
      { 2584: '0482 中正分行' },
      { 2585: '0507 嘉義分行' },
      { 2586: '0561 軍輝分行' },
      { 2587: '0598 蘆洲分行' },
      { 2588: '0604 羅東分行' },
      { 2589: '0613 北台中分行' },
      { 2590: '0622 成功分行' },
      { 2591: '0631 福和分行' },
      { 2592: '0659 營業部' },
      { 2593: '0668 瑞光分行' },
      { 2594: '0677 丹鳳分行' },
      { 2595: '0686 北新分行' },
      { 2596: '0695 艋舺分行' },
      { 2597: '0701 興隆分行' },
      { 2598: '0710 雙園分行' },
      { 2599: '0729 西門分行' },
      { 2600: '0738 古亭分行' },
      { 2601: '0747 安東分行' },
      { 2602: '0756 松山分行' },
      { 2603: '0765 士林分行' },
      { 2604: '0774 東湖分行' },
      { 2605: '0783 安和分行' },
      { 2606: '0792 光復分行' },
      { 2607: '0808 雙連分行' },
      { 2608: '0817 重慶分行' },
      { 2609: '0826 木柵分行' },
      { 2610: '0835 環南分行' },
      { 2611: '0844 大直分行' },
      { 2612: '0853 萬大分行' },
      { 2613: '0862 永春分行' },
      { 6394: '0880 南京東路分行' },
      { 6395: '0899 北桃園分行' },
      { 6396: '0905 環東分行' },
      { 6397: '0914 汐止分行' },
      { 6398: '0923 燕巢分行' },
      { 6399: '0932 苗栗分行' }
    ],
    49: [
      { 2615: '0010 營業部' },
      { 2616: '0029 三重分社' },
      { 2617: '0038 三芝分社' },
      { 2618: '0047 八里分社' },
      { 2619: '0056 石門分社' },
      { 2620: '0065 竹圍分社' },
      { 2621: '0074 水碓分社' },
      { 2622: '0083 英專分社' },
      { 2623: '0092 義山分社' },
      { 2624: '0108 龍形分社' },
      { 2625: '0117 金山分社' },
      { 2626: '0126 萬里分社' },
      { 2627: '0135 板橋分社' },
      { 2628: '0153 林口分社' },
      { 6427: '0162 中和分社' },
      { 6428: '0180 北投分社' },
      { 6429: '0199 新莊分社' }
    ],
    50: [
      { 2630: '0016 營業部' },
      { 2631: '0025 水碓分社' },
      { 2632: '0034 竹圍分社' },
      { 2633: '0043 英專分社' },
      { 2634: '0052 義山分社' },
      { 2635: '0061 三芝分社' },
      { 2636: '0070 八里分社' },
      { 2637: '0089 成州分社' },
      { 2638: '0098 五股分社' },
      { 2639: '0104 萬金分社' },
      { 2640: '0113 新莊分社' },
      { 6444: '0122 泰山分社' },
      { 6445: '0131 林口分社' },
      { 6446: '0140 板橋分社' },
      { 6447: '0159 蘆洲分社' },
      { 6448: '0168 三重分社' }
    ],
    51: [
      { 2642: '0012 營業部' },
      { 2643: '0021 復興分社' },
      { 2644: '0030 東門分社' },
      { 2645: '0058 頭城分社' },
      { 2646: '0067 礁溪分社' },
      { 2647: '0076 冬山分社' },
      { 5261: '0085 三星分社' },
      { 6449: '0094 壯圍分社' },
      { 6450: '0100 羅東分社' },
      { 6451: '0119 蘇澳分社' }
    ],
    52: [
      { 2649: '0019 營業部' },
      { 2650: '0028 大林分社' },
      { 2651: '0037 永興分社' },
      { 2652: '0046 南華分社' },
      { 2653: '0055 介壽分社' },
      { 2654: '0064 中山分社' },
      { 2655: '0073 永安分社' },
      { 2656: '0082 會稽分社' },
      { 2657: '0091 莊敬分社' },
      { 2658: '0107 大湳分社' },
      { 2659: '0116 南崁分社' }
    ],
    53: [
      { 2661: '0013 營業部' },
      { 2662: '0022 城中分社' },
      { 2663: '0031 武昌分社' },
      { 2664: '0040 新社分社' },
      { 2665: '0059 竹北分社' },
      { 2666: '0068 三民分社' },
      { 2667: '0077 香山分社' },
      { 2668: '0086 東南分社' },
      { 2669: '0095 光復分社' },
      { 2670: '0101 南寮分社' },
      { 2671: '0110 南香山分社' },
      { 2672: '0129 武陵分社' },
      { 2673: '0138 延平分社' },
      { 2674: '0147 建功分社' },
      { 2675: '0156 芎林分社' },
      { 2676: '0165 寶山分社' },
      { 6430: '0174 平鎮分社' },
      { 6431: '0183 楊梅分社' },
      { 6432: '0192 八德分社' },
      { 6433: '0208 中壢分社' }
    ],
    54: [
      { 2678: '0011 市中分社' },
      { 2679: '0020 民生分社' },
      { 2680: '0039 營業部' },
      { 2681: '0048 中正分社' },
      { 2682: '0057 東南分社' },
      { 2683: '0066 西門分社' },
      { 2684: '0075 東光分社' },
      { 2685: '0084 香山分社' },
      { 2686: '0093 竹南分社' },
      { 2687: '0109 延平分社' },
      { 2688: '0118 光華分社' },
      { 2689: '0127 新豐分社' },
      { 2690: '0136 竹北分社' },
      { 2691: '0145 六家簡易型分社' },
      { 6434: '0154 頭份分社' }
    ],
    55: [
      { 2693: '0014 營業部' },
      { 2694: '0023 文昌分社' },
      { 2695: '0032 南屯分社' },
      { 2696: '0041 五權分社' },
      { 2697: '0050 東南分社' },
      { 2698: '0069 大智分社' },
      { 2699: '0078 港路分社' },
      { 2700: '0087 精進分社' },
      { 2701: '0096 中興分社' },
      { 2702: '0102 水湳分社' },
      { 2703: '0111 中和分社' },
      { 2704: '0120 松竹分社' },
      { 2705: '0139 向上分社' },
      { 2706: '0148 大雅分社' },
      { 2707: '0157 西屯分社' },
      { 2708: '0166 太平分社' },
      { 2709: '0175 大里分社' },
      { 2710: '0184 潭子分社' },
      { 6435: '0193 文山分社' },
      { 6436: '0209 軍功分社' },
      { 6437: '0218 高工分社' },
      { 6438: '0227 敦化分社' }
    ],
    56: [
      { 2712: '0013 台中分行' },
      { 2713: '0022 營業部' },
      { 2714: '0040 成功分行' },
      { 2715: '0059 西屯分行' },
      { 2716: '0068 國光分行' },
      { 2717: '0077 大智分行' },
      { 2718: '0086 林森分行' },
      { 2719: '0095 南門分行' },
      { 2720: '0101 進化分行' },
      { 2721: '0110 南屯分行' },
      { 2722: '0138 北屯分行' },
      { 2723: '0147 豐原分行' },
      { 2724: '0156 彰化分行' },
      { 2725: '0165 員林分行' },
      { 2726: '0183 台北分行' },
      { 2727: '0192 桃園分行' },
      { 2728: '0208 板橋分行' },
      { 2729: '0253 豐信分行' },
      { 2730: '0262 中山分行' },
      { 2731: '0271 豐東簡易型分行' },
      { 2732: '0299 台南分行' },
      { 2733: '0305 高雄分行' },
      { 2734: '0314 新莊分行' },
      { 2735: '0323 新竹分行' },
      { 2736: '0332 鳳山分行' },
      { 2737: '0341 大雅分行' },
      { 2738: '0350 橋頭分行' },
      { 5219: '0378 龍井分行' },
      { 5258: '0369 大肚分行' },
      { 6400: '0387 田中分行' },
      { 6401: '0396 太平分行' }
    ],
    57: [
      { 2740: '0019 營業部' },
      { 2741: '0028 中華分社' },
      { 2742: '0037 曉陽分社' },
      { 2743: '0046 彰美分社' },
      { 2744: '0055 花壇分社' },
      { 2745: '0064 大竹分社' },
      { 2746: '0073 和美分社' },
      { 2747: '0082 中正分社' },
      { 2748: '0091 員林分社' }
    ],
    58: [
      { 2750: '0013 營業部' },
      { 2751: '0022 華山分社' },
      { 2752: '0031 彰南路分社' },
      { 2753: '0040 東芳分社' },
      { 2754: '0059 旭光分社' },
      { 2755: '0068 大里分社' },
      { 2756: '0077 埔心分社' },
      { 5262: '0086 田中分社' },
      { 6452: '0095 芬園分社' }
    ],
    59: [
      { 2758: '0012 車路口分社' },
      { 2759: '0021 營業部' },
      { 2760: '0030 觀音亭口分社' },
      { 2761: '0049 華陽分社' },
      { 2762: '0058 和美分社' },
      { 2763: '0067 花壇分社' },
      { 2764: '0076 曉陽分社' },
      { 2765: '0085 伸港分社' },
      { 2766: '0094 秀水分社' },
      { 2767: '0100 福興分社' },
      { 2768: '0119 永靖分社' },
      { 2769: '0128 埔心分社' },
      { 6439: '0137 員林分社' },
      { 6440: '0146 東興分社' }
    ],
    60: [
      { 2771: '0011 營業部' },
      { 2772: '0020 華山分社' },
      { 2773: '0039 三民分社' },
      { 2774: '0048 中華分社' },
      { 2775: '0057 大竹分社' },
      { 2776: '0066 和美分社' },
      { 2777: '0075 大埔分社' },
      { 2778: '0084 埔鹽分社' },
      { 2779: '0093 溪湖分社' },
      { 2780: '0109 伸港分社' }
    ],
    61: [
      { 2782: '0019 營業部' },
      { 2783: '0028 彰鹿分社' },
      { 2784: '0037 頂番分社' },
      { 2785: '0046 埔鹽分社' },
      { 2786: '0055 彰濱分社' },
      { 2787: '0064 管嶼分社' },
      { 2788: '0073 王功分社' },
      { 2789: '0082 草港分社' },
      { 2790: '0091 溪湖分社' },
      { 6453: '0107 員林分社' }
    ],
    62: [
      { 2792: '0013 營業部' },
      { 2793: '0022 中埔分社' },
      { 2794: '0031 新榮分社' },
      { 2795: '0040 北興分社' },
      { 2796: '0059 忠孝分社' },
      { 2797: '0068 興嘉分社' },
      { 2798: '0077 新南分社' },
      { 2799: '0086 新興分社' },
      { 2800: '0095 美源分社' },
      { 2801: '0101 新生分社' },
      { 2802: '0110 民生分社' }
    ],
    63: [
      { 2804: '0010 營業部' },
      { 2805: '0029 東門分社' },
      { 2806: '0038 西門分社' },
      { 2807: '0047 安平分社' },
      { 2808: '0056 成功分社' },
      { 2809: '0065 大同分社' },
      { 2810: '0074 文賢分社' },
      { 2811: '0083 金華分社' },
      { 2812: '0092 小東分社' },
      { 2813: '0108 開元分社' },
      { 2814: '0117 中華分社' },
      { 2815: '0126 大灣分社' },
      { 2816: '0135 安中分社' }
    ],
    64: [
      { 2818: '0011 營業部' },
      { 2819: '0020 八德分社' },
      { 2820: '0039 苓雅分社' },
      { 2821: '0048 新興分社' },
      { 2822: '0057 三民分社' },
      { 2823: '0066 鼓山分社' },
      { 2824: '0075 臨海分社' },
      { 2825: '0084 前鎮分社' },
      { 2826: '0093 前金分社' },
      { 2827: '0109 左營分社' },
      { 2828: '0118 青年分社' },
      { 2829: '0127 楠梓分社' },
      { 2830: '0136 三多分社' },
      { 2831: '0145 灣子分社' },
      { 2832: '0154 天祥分社' },
      { 2833: '0163 陽明分社' },
      { 2834: '0172 武廟分社' },
      { 2835: '0181 瑞祥分社' },
      { 2836: '0190 右昌分社' },
      { 2837: '0206 一心分社' },
      { 6441: '0215 文川分社' }
    ],
    65: [
      { 2839: '0017 復興分社（光隆，日盛證券收付處）' },
      { 2840: '0026 自由分社' },
      { 2841: '0035 中華分社' },
      { 2842: '0044 總社〈營業部〉' },
      { 2843: '0053 美崙分社' },
      { 2844: '0062 信義簡易型分社' },
      { 2845: '0071 自強分社' },
      { 2846: '0080 國光簡易型分社' },
      { 2847: '0099 鳳山分社' },
      { 2848: '0105 建國簡易型分社' },
      { 2849: '0114 永康分社' },
      { 2850: '0123 慶豐簡易型分社' },
      { 2851: '0132 台東分社' },
      { 2852: '0141 仁武分社' },
      { 2853: '0150 橋頭分社' }
    ],
    66: [
      { 2855: '0016 營業部' },
      { 2856: '0025 中山分社' },
      { 2857: '0034 主里分社' },
      { 2858: '0043 美崙分社' },
      { 2859: '0052 中正分社' },
      { 2860: '0061 富國分社' },
      { 2861: '0070 田蒲分社' },
      { 2862: '0089 建國分社' },
      { 2863: '0098 壽豐分社' },
      { 2864: '0104 玉里分社' },
      { 2865: '0122 台東分社' },
      { 2866: '0131 大里分社' },
      { 2867: '0140 太平分社' },
      { 2868: '0159 大雅分社' },
      { 2869: '0168 烏日分社' },
      { 2870: '0177 公益分社' },
      { 5260: '0186 西屯分社' },
      { 6442: '0195 羅東分社' },
      { 6443: '0201 左營分社' }
    ],
    67: [
      { 2872: '0017 營業部' },
      { 2873: '0026 民權分社' },
      { 2874: '0035 光復分社' },
      { 2875: '0044 澎南分社' },
      { 2876: '0053 赤崁分社' },
      { 2877: '0062 西嶼分社' },
      { 2878: '0080 湖西分社' },
      { 2879: '0099 港都分社' },
      { 2880: '0105 朝陽分社' }
    ],
    68: [
      { 2882: '0016 營業部' },
      { 2883: '0025 民生分社' },
      { 2884: '0034 陽明分社' },
      { 2885: '0043 澎南分社' },
      { 2886: '0052 白沙分社' },
      { 2887: '0061 西嶼分社' },
      { 2888: '0070 湖西分社' },
      { 2889: '0089 漁港分社' },
      { 2890: '0098 中興分社' }
    ],
    69: [
      { 2892: '0015 營業部' },
      { 2893: '0024 金沙分社' },
      { 2894: '0033 金湖分社' },
      { 2895: '0042 烈嶼分社' }
    ],
    70: [{ 2897: '0019 台北分行' }],
    71: [{ 2899: '0015 總公司' }],
    72: [{ 2901: '0016 臺北分行' }],
    73: [{ 2903: '0888 臺北分行' }],
    74: [{ 2905: '0018 臺北分行' }],
    0: [
      { 2907: '0019 基隆區漁會' },
      { 2909: '0010 新北市瑞芳區漁會' },
      { 2910: '0021 深澳分部' },
      { 2911: '0032 新北市萬里區漁會' },
      { 2912: '0054 萬里分部' },
      { 2914: '0011 宜蘭縣頭城區漁會' },
      { 2915: '0033 大溪分部' },
      { 2916: '0066 壯圍分部' },
      { 2917: '0022 宜蘭縣蘇澳區漁會' },
      { 2919: '0012 桃園區漁會' },
      { 2921: '0013 新竹區漁會' },
      { 2923: '0011 雲林區漁會' },
      { 2924: '0022 台西分部' },
      { 2925: '0033 口湖分部' },
      { 2926: '0044 麥寮分部' },
      { 2927: '0055 三條崙分部' },
      { 2929: '0014 嘉義區漁會' },
      { 2930: '0025 新塭分部' },
      { 2931: '0036 義竹分部' },
      { 2932: '0047 過溝分部' },
      { 2933: '0058 東石分部' },
      { 2934: '0069 過路分部' },
      { 2935: '0070 副瀨分部' },
      { 2936: '0081 景山分部' },
      { 2938: '0016 南市區漁會' },
      { 2939: '0027 四草分部' },
      { 2940: '0038 安南分部' },
      { 2942: '0017 南縣區漁會' },
      { 2943: '0028 七股分部' },
      { 2944: '0039 北門分部' },
      { 2945: '0040 將軍港分部' },
      { 2946: '0051 青山港分部' },
      { 2947: '0073 永吉分部' },
      { 2949: '0012 高雄區漁會信用部' },
      { 2950: '0034 旗津分部' },
      { 2951: '0056 援中港分部' },
      { 2952: '0023 小港區漁會信用部' },
      { 2954: '0024 興達港區漁會' },
      { 2955: '0035 下茄萣分部' },
      { 2956: '0068 林園區漁會' },
      { 2957: '0079 彌陀區漁會' },
      { 2958: '0105 舊港分部' },
      { 2959: '0149 南寮分部' },
      { 2960: '0091 永安區漁會' },
      { 2962: '0015 琉球區漁會' },
      { 2963: '0037 東港區漁會' },
      { 2964: '0118 光復分部' },
      { 2965: '0129 新園分部' },
      { 2966: '0048 林邊區漁會' },
      { 2967: '0059 塭豐分部' },
      { 2968: '0082 崎峰分部' },
      { 2969: '0093 水利分部' },
      { 2971: '0016 新港區漁會' },
      { 2973: '0017 澎湖區漁會' },
      { 2974: '0028 西嶼分部' },
      { 2975: '0039 白沙分部' },
      { 2977: '0016 基隆市農會' },
      { 2978: '0027 安樂分會' },
      { 2979: '0038 百福分會' },
      { 2980: '0049 愛六路分部' },
      { 2996: '0019 三重區農會' },
      { 2997: '0606 溪美分部' },
      { 2998: '0710 力行分部' },
      { 2999: '0721 三民分部' },
      { 3000: '0916 光復分部' },
      { 3001: '0938 成功分部' },
      { 3002: '1119 慈福分部' },
      { 3003: '0031 淡水區農會' },
      { 3004: '0798 中興分部' },
      { 3005: '0802 竹圍分部' },
      { 3006: '0813 水碓分部' },
      { 3007: '1061 福德分部' },
      { 3008: '1348 義山分部' },
      { 3009: '1360 新興分部' },
      { 3010: '0042 樹林區農會' },
      { 3011: '0237 三多分部' },
      { 3012: '0248 山佳分部' },
      { 3013: '0259 柑園分部' },
      { 3014: '0754 保安分部' },
      { 3015: '1027 東山分部' },
      { 3016: '1038 金寮分部' },
      { 3017: '1120 圳安分部' },
      { 3018: '1186 大學城分部' },
      { 3019: '1315 大同分部' },
      { 3020: '1337 復興分部' },
      { 3021: '0053 鶯歌區農會' },
      { 3022: '0503 二橋分部' },
      { 3023: '0514 鳳鳴分部' },
      { 3024: '1197 永昌分部' },
      { 3025: '0064 三峽區農會' },
      { 3026: '0260 成福分部' },
      { 3027: '0558 橫溪分部' },
      { 3028: '0673 大埔分部' },
      { 3029: '1131 北大分部' },
      { 3030: '1359 民生分部' },
      { 3031: '0097 土城區農會' },
      { 3032: '0318 清水分部' },
      { 3033: '0684 頂埔分部' },
      { 3034: '0695 貨饒分部' },
      { 3035: '0891 峰廷分部' },
      { 3036: '0905 廣福分部' },
      { 3037: '0101 蘆洲區農會' },
      { 3038: '0569 長安分部' },
      { 3039: '0835 成功分部' },
      { 3040: '1072 長榮分部' },
      { 3041: '0112 五股區農會信用部' },
      { 3042: '0329 興珍分部' },
      { 3043: '0330 成州分部' },
      { 3044: '0709 德泰分部' },
      { 3045: '0950 五福分部' },
      { 3046: '1083 德音分部' },
      { 3047: '1094 成德分部' },
      { 3048: '1175 成功分部' },
      { 3049: '0123 林口區農會' },
      { 3050: '0341 南勢分部' },
      { 3051: '0134 泰山區農會' },
      { 3052: '0651 貴子分部' },
      { 3053: '0662 明志分部' },
      { 3054: '0927 貴和分部' },
      { 3055: '1005 泰林分部' },
      { 3056: '1016 泰友分部' },
      { 3057: '0145 坪林區農會' },
      { 3058: '0156 八里區農會' },
      { 3059: '0765 埤頭分部' },
      { 3060: '0824 龍形分部' },
      { 3061: '0167 金山地區農會' },
      { 3062: '0352 萬里分部' },
      { 3063: '0857 野柳分部' },
      { 3064: '0178 瑞芳地區農會' },
      { 3065: '0374 九份分部' },
      { 3066: '0385 四腳亭分部' },
      { 3067: '0396 雙溪辦事處' },
      { 3068: '0400 貢寮辦事處' },
      { 3069: '0411 澳底分部' },
      { 3070: '0868 龍興分部' },
      { 3071: '0961 福隆辦事處' },
      { 3072: '0189 新店地區農會' },
      { 3073: '0363 德安分部' },
      { 3074: '0422 中正分部' },
      { 3075: '0433 烏來分部' },
      { 3076: '1108 新和分部' },
      { 3077: '1245 青潭辦事處' },
      { 3078: '1326 安康分部' },
      { 3079: '0190 中和地區農會' },
      { 3080: '0444 中正分部' },
      { 3081: '0455 員山分部' },
      { 3082: '0570 智光分部' },
      { 3083: '0581 連城分部' },
      { 3084: '0592 民享分部' },
      { 3085: '0732 錦和分部' },
      { 3086: '0743 仁愛分部' },
      { 3087: '1223 竹林分部' },
      { 3088: '1289 興南分部' },
      { 3089: '0204 深坑區農會' },
      { 3090: '1201 萬順辦事處' },
      { 3091: '0466 石碇區農會' },
      { 3092: '0477 平溪區農會' },
      { 3093: '0499 石門區農會' },
      { 3094: '0547 老梅分部' },
      { 3095: '1290 三芝區農會' },
      { 3096: '0846 北新庄分部' },
      { 3098: '0010 宜蘭市農會' },
      { 3099: '0308 神農分部' },
      { 3100: '0386 南門分部' },
      { 3101: '0412 新店分部' },
      { 3102: '0515 延平分部' },
      { 3103: '0021 頭城鎮農會' },
      { 3104: '0113 大溪分部' },
      { 3105: '0342 金面分部' },
      { 3106: '0478 竹安分部' },
      { 3107: '0032 羅東鎮農會' },
      { 3108: '0283 民權辦事處' },
      { 3109: '0319 南門辦事處' },
      { 3110: '0364 北門辦事處' },
      { 3111: '0375 中正路辦事處' },
      { 3112: '0467 東門辦事處' },
      { 3113: '0054 壯圍鄉農會' },
      { 3114: '0135 古亭辦事處' },
      { 3115: '0526 美城分部' },
      { 3116: '0065 員山鄉農會' },
      { 3117: '0146 同樂分部' },
      { 3118: '0331 深溝分部' },
      { 3119: '0445 大湖分部' },
      { 3120: '0076 五結鄉農會' },
      { 3121: '0157 利澤分部' },
      { 3122: '0168 二結分部' },
      { 3123: '0179 四結分部' },
      { 3124: '0098 蘇澳地區農會' },
      { 3125: '0205 南方澳分部' },
      { 3126: '0216 南澳分部' },
      { 3127: '0227 新馬分部' },
      { 3128: '0238 新城分部' },
      { 3129: '0423 龍德分部' },
      { 3130: '0102 三星地區農會' },
      { 3131: '0249 大洲辦事處' },
      { 3132: '0250 福山辦事處' },
      { 3133: '0353 大隱辦事處' },
      { 3134: '0434 大同辦事處' },
      { 3136: '0011 桃園區農會' },
      { 3137: '0468 大林分部' },
      { 3138: '0479 會稽分部' },
      { 3139: '0527 中路分部' },
      { 3140: '0538 慈文分部' },
      { 3141: '0631 埔子分部' },
      { 3142: '0642 龍山分部' },
      { 3143: '0033 大溪區農會' },
      { 3144: '0158 一心分部' },
      { 3145: '0169 中新分部' },
      { 3146: '0170 新峰分部' },
      { 3147: '0181 埔頂分部' },
      { 3148: '0387 員林分部' },
      { 3149: '0435 僑愛分部' },
      { 3150: '0712 崎頂分部' },
      { 3151: '0826 南興分部' },
      { 3152: '0044 楊梅區農會' },
      { 3153: '0192 老街分部' },
      { 3154: '0206 埔心分部' },
      { 3155: '0217 富岡分部' },
      { 3156: '0697 大平分部' },
      { 3157: '0055 大園區農會' },
      { 3158: '0228 埔心分部' },
      { 3159: '0239 竹圍分部' },
      { 3160: '0240 三和分部' },
      { 3161: '0561 和平分部' },
      { 3162: '0745 五權分部' },
      { 3163: '0756 潮音分部' },
      { 3164: '0066 蘆竹區農會' },
      { 3165: '0251 新興分部' },
      { 3166: '0262 山腳分部' },
      { 3167: '0273 宏竹分部' },
      { 3168: '0284 大竹分部' },
      { 3169: '0398 外社分部' },
      { 3170: '0402 蘆竹分部' },
      { 3171: '0505 中福分部' },
      { 3172: '0516 五福分部' },
      { 3173: '0859 海湖分部' },
      { 3174: '0077 龜山區農會' },
      { 3175: '0147 樂善分部' },
      { 3176: '0295 大崗分部' },
      { 3177: '0376 山頂分部' },
      { 3178: '0572 龍壽分部' },
      { 3179: '0583 南上分部' },
      { 3180: '0620 公西分部' },
      { 3181: '0723 嶺頂分部' },
      { 3182: '0804 山福分部' },
      { 3183: '0848 舊路分部' },
      { 3184: '0088 八德區農會' },
      { 3185: '0309 大湳分部' },
      { 3186: '0446 瑞豐分部' },
      { 3187: '0457 大福分部' },
      { 3188: '0549 茄苳分部' },
      { 3189: '0550 桃鶯分部' },
      { 3190: '0837 霄裡分部' },
      { 3191: '0103 新屋區農會' },
      { 3192: '0310 永安分部' },
      { 3193: '0321 大坡分部' },
      { 3194: '0594 高榮分部' },
      { 3195: '0114 龍潭區農會' },
      { 3196: '0480 中興分部' },
      { 3197: '0491 烏林分部' },
      { 3198: '0778 高平分部' },
      { 3199: '0790 八德分部' },
      { 3200: '0136 復興區農會' },
      { 3201: '0619 三民分部' },
      { 3202: '0653 觀音區農會' },
      { 3203: '0686 平鎮區農會' },
      { 3204: '0099 北勢分部' },
      { 3205: '0365 山峰分部' },
      { 3206: '0413 龍岡分部' },
      { 3207: '0424 宋屋分部' },
      { 3208: '0767 金陵分部' },
      { 3210: '0089 中華民國農會中壢辦事處信用部' },
      { 3211: '0090 大崙分部' },
      { 3213: '0016 關西鎮農會' },
      { 3214: '0131 石光分部' },
      { 3215: '0142 錦山分部' },
      { 3216: '0027 新埔鎮農會' },
      { 3217: '0393 照門分部' },
      { 3218: '0038 竹北市農會' },
      { 3219: '0153 六家分部' },
      { 3220: '0290 豐田分部' },
      { 3221: '0304 鳳岡分部' },
      { 3222: '0430 高鐵分部' },
      { 3223: '0050 湖口鄉農會' },
      { 3224: '0348 中興辦事處' },
      { 3225: '0474 客家樓分部' },
      { 3226: '0061 新竹市農會信用部' },
      { 3227: '0186 三姓橋分部' },
      { 3228: '0315 樹林頭分部' },
      { 3229: '0337 光復分部' },
      { 3230: '0359 內湖分部' },
      { 3231: '0360 民權分部' },
      { 3232: '0429 新興分部' },
      { 3233: '0441 虎林分部' },
      { 3234: '0452 光華分部' },
      { 3235: '0072 芎林鄉農會' },
      { 3236: '0197 秀湖分部' },
      { 3237: '0326 石潭分部' },
      { 3238: '0083 寶山鄉農會' },
      { 3239: '0201 新城分部' },
      { 3240: '0212 寶山分部' },
      { 3241: '0094 峨眉鄉農會' },
      { 3242: '0371 富興分部' },
      { 3243: '0108 北埔鄉農會' },
      { 3244: '0119 竹東地區農會' },
      { 3245: '0223 竹中分部' },
      { 3246: '0234 二重辦事處' },
      { 3247: '0256 五峰辦事處' },
      { 3248: '0382 東寧辦事處' },
      { 3249: '0120 橫山地區農會' },
      { 3250: '0267 橫山分部' },
      { 3251: '0289 尖石分部' },
      { 3252: '0463 新豐鄉農會' },
      { 3254: '0062 後龍鎮農會' },
      { 3255: '0501 新港分部' },
      { 3257: '1107 台中市豐原區農會' },
      { 3258: '1118 神岡區農會' },
      { 3260: '0075 名間鄉農會' },
      { 3261: '0260 新街分部' },
      { 3262: '0271 大庄分部' },
      { 3263: '0282 赤水分部' },
      { 3264: '0293 松柏嶺分部' },
      { 3265: '0466 新光分部' },
      { 3267: '0043 員林鎮農會' },
      { 3268: '0526 東山分部' },
      { 3269: '0700 百果山分部' },
      { 3270: '0087 二林鎮農會' },
      { 3271: '0711 原斗分部' },
      { 3272: '0799 挖仔分部' },
      { 3273: '0858 中山分部' },
      { 3274: '1291 萬興分部' },
      { 3275: '0124 秀水鄉農會' },
      { 3276: '0320 福安分部' },
      { 3277: '0478 馬鳴分部' },
      { 3278: '0515 金興分部' },
      { 3279: '0179 埔心鄉農會' },
      { 3280: '0559 舊館分部' },
      { 3281: '0593 明聖分部' },
      { 3282: '0180 永靖鄉農會' },
      { 3283: '0696 永安分部' },
      { 3284: '0733 五福分部' },
      { 3285: '0870 同安分部' },
      { 3286: '1028 永興分部' },
      { 3287: '0227 埤頭鄉農會' },
      { 3288: '0401 路口厝分部' },
      { 3289: '0412 埤頭分部' },
      { 3290: '0423 芙朝分部' },
      { 3291: '0261 竹塘鄉農會' },
      { 3292: '1017 長安分部' },
      { 3293: '1327 芬園鄉農會' },
      { 3294: '1338 芳苑鄉農會' },
      { 3296: '0012 斗六市農會' },
      { 3297: '0492 石榴分部' },
      { 3298: '0595 鎮南分部' },
      { 3299: '0609 光興分部' },
      { 3300: '0746 成功分部' },
      { 3301: '0757 虎溪分部' },
      { 3302: '0023 虎尾鎮農會' },
      { 3303: '0414 德興分部' },
      { 3304: '0506 大屯分部' },
      { 3305: '0517 惠來分部' },
      { 3306: '0610 頂溪分部' },
      { 3307: '0779 立仁分部' },
      { 3308: '0034 西螺鎮農會' },
      { 3309: '0403 埤源分部' },
      { 3310: '0528 九隆分部' },
      { 3311: '0539 和心分部' },
      { 3312: '0540 建興分部' },
      { 3313: '0621 中山分部' },
      { 3314: '0056 斗南鎮農會' },
      { 3315: '0218 石龜溪分部' },
      { 3316: '0458 西埤分部' },
      { 3317: '0469 新光分部' },
      { 3318: '0702 明昌分部' },
      { 3319: '0735 西岐分部' },
      { 3320: '0078 古坑鄉農會' },
      { 3321: '0230 永光分部' },
      { 3322: '0241 東和分部' },
      { 3323: '0089 大埤鄉農會' },
      { 3324: '0252 怡然分部' },
      { 3325: '0562 嘉興分部' },
      { 3326: '0090 莿桐鄉農會' },
      { 3327: '0263 饒平分部' },
      { 3328: '0436 六合分部' },
      { 3329: '0115 二崙鄉農會' },
      { 3330: '0274 油車分部' },
      { 3331: '0285 永定分部' },
      { 3332: '0126 崙背鄉農會' },
      { 3333: '0713 豐榮分部' },
      { 3334: '0137 台西鄉農會' },
      { 3335: '0296 崙豐分部' },
      { 3336: '0643 牛厝分部' },
      { 3337: '0665 蚊港分部' },
      { 3338: '0148 褒忠鄉農會' },
      { 3339: '0160 四湖鄉農會' },
      { 3340: '0300 飛沙分部' },
      { 3341: '0447 溪底分部' },
      { 3342: '0573 林厝分部' },
      { 3343: '0698 箔子分部' },
      { 3344: '0171 口湖鄉農會' },
      { 3345: '0311 宜梧分部' },
      { 3346: '0322 金湖分部' },
      { 3347: '0333 下崙分部' },
      { 3349: '0024 朴子市農會' },
      { 3350: '0219 開元分部' },
      { 3351: '0220 文化分部' },
      { 3352: '0231 松梅分部' },
      { 3353: '1353 大慷榔分部' },
      { 3354: '1364 雙溪口分部' },
      { 3355: '0035 布袋鎮農會' },
      { 3356: '0242 過溝分部' },
      { 3357: '0253 永安分部' },
      { 3358: '0769 新厝分部' },
      { 3359: '1375 新塭分部' },
      { 3360: '0046 大林鎮農會' },
      { 3361: '0264 吉林分部' },
      { 3362: '0275 溝背分部' },
      { 3363: '0286 大美分部' },
      { 3364: '0297 排路分部' },
      { 3365: '0781 中林分部' },
      { 3366: '1467 中坑分部' },
      { 3367: '0057 民雄鄉農會' },
      { 3368: '0301 北斗分部' },
      { 3369: '0312 山中分部' },
      { 3370: '0323 菁埔分部' },
      { 3371: '0714 秀林分部' },
      { 3372: '1478 三興分部' },
      { 3373: '1489 興南分部' },
      { 3374: '0068 溪口鄉農會' },
      { 3375: '0334 柴林分部' },
      { 3376: '0345 崙尾分部' },
      { 3377: '1386 柳溝分部' },
      { 3378: '0079 東石鄉農會' },
      { 3379: '0356 東石分部' },
      { 3380: '0367 副瀨分部' },
      { 3381: '0378 下楫分部' },
      { 3382: '0389 西崙分部' },
      { 3383: '0080 義竹鄉農會' },
      { 3384: '0390 過路分部' },
      { 3385: '0404 光榮分部' },
      { 3386: '1397 新店分部' },
      { 3387: '0091 鹿草鄉農會' },
      { 3388: '0415 下潭分部' },
      { 3389: '0426 後堀分部' },
      { 3390: '1331 重寮分部' },
      { 3391: '1401 松山分部' },
      { 3392: '0105 太保市農會' },
      { 3393: '0437 後潭分部' },
      { 3394: '0448 新埤分部' },
      { 3395: '0459 南新分部' },
      { 3396: '0758 嘉太分部' },
      { 3397: '1412 安東分部' },
      { 3398: '0116 水上鄉農會' },
      { 3399: '0460 成功分部' },
      { 3400: '0471 南靖分部' },
      { 3401: '0482 大崙分部' },
      { 3402: '0493 忠和分部' },
      { 3403: '0507 北回分部' },
      { 3404: '0518 柳林分部' },
      { 3405: '0725 民生分部' },
      { 3406: '0138 番路鄉農會' },
      { 3407: '0563 觸口分部' },
      { 3408: '1320 隙頂分部' },
      { 3409: '0149 竹崎地區農會' },
      { 3410: '0574 內埔分部' },
      { 3411: '0585 灣橋分部' },
      { 3412: '0596 東義分部' },
      { 3413: '0699 大埔分部' },
      { 3414: '1423 石棹分部' },
      { 3415: '1490 鹿滿分部' },
      { 3416: '0150 梅山鄉農會' },
      { 3417: '0600 瑞里分部' },
      { 3418: '0611 太平分部' },
      { 3419: '0622 太和分部' },
      { 3420: '0806 六腳鄉農會' },
      { 3421: '0172 蒜頭分部' },
      { 3422: '0666 港尾寮分部' },
      { 3423: '0677 六腳分部' },
      { 3424: '0688 灣內分部' },
      { 3425: '0703 更寮分部' },
      { 3426: '1434 蘇崙分部' },
      { 3427: '0817 新港鄉農會' },
      { 3428: '0633 月眉分部' },
      { 3429: '0644 大潭分部' },
      { 3430: '0655 崙子分部' },
      { 3431: '1261 安和分部' },
      { 3432: '0862 嘉義市農會' },
      { 3433: '0013 吳鳳分部' },
      { 3434: '0194 福全分部' },
      { 3435: '0208 北興分部' },
      { 3436: '1272 宣信分部' },
      { 3437: '1283 民生分部' },
      { 3439: '0025 新營區農會' },
      { 3440: '0335 太子宮分部' },
      { 3441: '0346 延平分部' },
      { 3442: '1066 三民分部' },
      { 3443: '1217 中正分部' },
      { 3444: '0036 鹽水區農會' },
      { 3445: '0357 歡雅分部' },
      { 3446: '0368 圣頭港分部' },
      { 3447: '0069 佳里區農會' },
      { 3448: '0416 佳興分部' },
      { 3449: '0427 子龍分部' },
      { 3450: '0438 塭內分部' },
      { 3451: '0449 延平分部' },
      { 3452: '1158 新生分部' },
      { 3453: '0081 善化區農會' },
      { 3454: '0450 東關里分部' },
      { 3455: '0461 六分寮分部' },
      { 3456: '1365 茄拔分部' },
      { 3457: '0139 六甲區農會' },
      { 3458: '0612 林鳳營分部' },
      { 3459: '0162 西港區農會' },
      { 3460: '0689 後營分部' },
      { 3461: '0715 竹林分部' },
      { 3462: '0184 將軍區農會' },
      { 3463: '0771 苓仔寮分部' },
      { 3464: '0782 將軍分部' },
      { 3465: '0793 馬沙溝分部' },
      { 3466: '0807 青鯤鯓分部' },
      { 3467: '0195 北門區農會' },
      { 3468: '0818 溪底寮分部' },
      { 3469: '0829 蚵寮分部' },
      { 3470: '1206 三慈分部' },
      { 3471: '0243 玉井區農會' },
      { 3472: '0298 歸仁區農會' },
      { 3473: '0966 大潭武東分部' },
      { 3474: '0977 大廟分部' },
      { 3475: '0988 新市場分部' },
      { 3476: '1239 歸南分部' },
      { 3477: '0324 永康區農會' },
      { 3478: '1011 大灣分部' },
      { 3479: '1181 鹽行分部' },
      { 3480: '1240 中華分部' },
      { 3481: '1262 崑山分部' },
      { 3482: '1343 龍潭分部' },
      { 3483: '1402 楠西區農會' },
      { 3485: '0015 鳳山區農會' },
      { 3486: '0266 五甲分部' },
      { 3487: '0738 老爺分部' },
      { 3488: '0749 市場分部' },
      { 3489: '0864 文山分部' },
      { 3490: '0886 東區分部' },
      { 3491: '0897 鎮北分部' },
      { 3492: '0990 過埤分部' },
      { 3493: '0026 岡山區農會' },
      { 3494: '0277 市場分部' },
      { 3495: '0288 嘉興分部' },
      { 3496: '0299 仁壽分部' },
      { 3497: '0772 協榮分部' },
      { 3498: '1067 後紅分部' },
      { 3499: '0037 旗山區農會' },
      { 3500: '0314 旗尾分部' },
      { 3501: '0325 溪洲分部' },
      { 3502: '0336 南溪洲分部' },
      { 3503: '0347 嶺口分部' },
      { 3504: '0358 中正分部' },
      { 3505: '0048 美濃區農會' },
      { 3506: '0369 中壇分部' },
      { 3507: '0370 龍肚分部' },
      { 3508: '0381 廣興分部' },
      { 3509: '0392 南隆分部' },
      { 3510: '0059 橋頭區農會' },
      { 3511: '0716 橋頭分部' },
      { 3512: '0901 甲圍辦事處' },
      { 3513: '0923 隆豐和分部' },
      { 3514: '0934 五林分部' },
      { 3515: '0060 燕巢區農會' },
      { 3516: '0406 鳳雄分部' },
      { 3517: '0417 深水分部' },
      { 3518: '0680 安招分部' },
      { 3519: '0071 田寮區農會' },
      { 3520: '0794 新興分部' },
      { 3521: '0808 崇德分部' },
      { 3522: '0082 阿蓮區農會' },
      { 3523: '0624 中路分部' },
      { 3524: '1078 復安分部' },
      { 3525: '0093 路竹區農會' },
      { 3526: '0428 竹滬分部' },
      { 3527: '0439 一甲分部' },
      { 3528: '0440 下坑分部' },
      { 3529: '0451 三爺分部' },
      { 3530: '0107 湖內區農會' },
      { 3531: '0462 大湖分部' },
      { 3532: '0657 海埔分部' },
      { 3533: '0853 太爺分部' },
      { 3534: '0118 茄萣區農會' },
      { 3535: '0750 白砂崙分部' },
      { 3536: '0129 彌陀區農會' },
      { 3537: '0646 南安分部' },
      { 3538: '0783 壽齡分部' },
      { 3539: '0130 永安區農會' },
      { 3540: '0679 保寧分部' },
      { 3541: '0761 維新分部' },
      { 3542: '0141 梓官區農會' },
      { 3543: '0473 赤崁分部' },
      { 3544: '0484 大舍分部' },
      { 3545: '0495 梓官分部' },
      { 3546: '0185 林園區農會' },
      { 3547: '0831 中厝分部' },
      { 3548: '0196 大寮區農會' },
      { 3549: '0565 大寮分部' },
      { 3550: '0576 中庄分部' },
      { 3551: '0587 後庄分部' },
      { 3552: '0598 昭明分部' },
      { 3553: '0602 潮寮分部' },
      { 3554: '1012 翁園分部' },
      { 3555: '0211 仁武區農會' },
      { 3556: '0727 灣內分部' },
      { 3557: '1045 八卦分部' },
      { 3558: '0222 大社區農會' },
      { 3559: '0244 杉林區農會' },
      { 3560: '0255 甲仙地區農會' },
      { 3561: '0819 桃源分部' },
      { 3562: '0989 那瑪夏分部' },
      { 3563: '0303 六龜區農會' },
      { 3564: '1089 鳥松區農會' },
      { 3565: '0967 仁美分部' },
      { 3566: '1090 澄清湖分部' },
      { 3567: '1104 大樹區農會' },
      { 3568: '1115 內門區農會' },
      { 3569: '0978 溝坪分部' },
      { 3570: '1001 觀亭分部' },
      { 3572: '0031 東港鎮農會' },
      { 3573: '0721 下部分部' },
      { 3574: '0042 恆春鎮農會' },
      { 3575: '0547 墾丁分部' },
      { 3576: '0075 麟洛鄉農會' },
      { 3577: '0086 九如鄉農會' },
      { 3578: '0787 洽興分部' },
      { 3579: '0112 里港鄉農會' },
      { 3580: '0330 土庫辦事處' },
      { 3581: '0341 載興辦事處' },
      { 3582: '0628 三廓分部' },
      { 3583: '0950 塔樓分部' },
      { 3584: '0167 崁頂鄉農會' },
      { 3585: '0709 力社分部' },
      { 3586: '0710 港東分部' },
      { 3587: '0189 南州地區農會' },
      { 3588: '0813 新埤分部' },
      { 3589: '0204 琉球鄉農會' },
      { 3590: '0215 滿州鄉農會' },
      { 3591: '0259 枋山地區農會' },
      { 3592: '0743 枋山分部' },
      { 3593: '0754 加祿分部' },
      { 3594: '0868 屏東市農會' },
      { 3595: '0879 車城地區農會' },
      { 3596: '0880 屏東縣農會' },
      { 3597: '0891 枋寮地區農會' },
      { 3598: '0905 竹田鄉農會' },
      { 3599: '0916 萬丹鄉農會' },
      { 3600: '0927 長治鄉農會' },
      { 3601: '0938 林邊鄉農會' },
      { 3602: '0949 佳冬鄉農會' },
      { 3603: '0961 高樹鄉農會' },
      { 3604: '0972 萬巒地區農會' },
      { 3606: '0021 吉安鄉農會' },
      { 3607: '0102 仁里分部' },
      { 3608: '0227 北昌分部' },
      { 3609: '0238 宜昌分部' },
      { 3610: '0032 壽豐鄉農會' },
      { 3611: '0124 志學分部' },
      { 3612: '0331 豐裡分部' },
      { 3613: '0043 富里鄉農會' },
      { 3614: '0135 東竹分部' },
      { 3615: '0146 東里分部' },
      { 3616: '0098 新秀地區農會' },
      { 3617: '0191 新城分部' },
      { 3618: '0205 嘉里分部' },
      { 3619: '0216 秀林分部' },
      { 3621: '0011 關山鎮農會' },
      { 3622: '0239 海端分部' },
      { 3623: '0022 成功鎮農會' },
      { 3624: '0206 忠孝分部' },
      { 3625: '0251 信義分部' },
      { 3626: '0033 池上鄉農會' },
      { 3627: '0055 長濱鄉農會' },
      { 3628: '0066 台東地區農會' },
      { 3629: '0099 豐里分部' },
      { 3630: '0125 台東分部' },
      { 3631: '0136 知本分部' },
      { 3632: '0147 綠島分部' },
      { 3633: '0158 初鹿分部' },
      { 3634: '0217 太平分部' },
      { 3635: '0228 蘭嶼分部' },
      { 3636: '0284 新園分部' },
      { 3637: '0077 鹿野地區農會' },
      { 3638: '0169 瑞源分部' },
      { 3639: '0240 瑞豐分部' },
      { 3640: '0088 太麻里地區農會' },
      { 3641: '0170 美和分部' },
      { 3642: '0181 金崙分部' },
      { 3643: '0192 大武分部' },
      { 3644: '0103 東河鄉農會' },
      { 3645: '0044 東河分部' },
      { 3646: '0114 泰源分部' },
      { 3648: '0012 北投區農會' },
      { 3649: '0078 石牌分部' },
      { 3650: '0089 關渡分部' },
      { 3651: '0126 立農分部' },
      { 3652: '0137 大屯分部' },
      { 3653: '0241 福興分部' },
      { 3654: '0023 士林區農會' },
      { 3655: '0090 社子分會' },
      { 3656: '0148 文林分會' },
      { 3657: '0159 天母分會' },
      { 3658: '0207 陽明山分部' },
      { 3659: '0229 延平分部' },
      { 3660: '0034 內湖區農會' },
      { 3661: '0104 潭美分部' },
      { 3662: '0171 東湖分部' },
      { 3663: '0182 西湖分部' },
      { 3664: '0274 民權分部' },
      { 3665: '0045 南港區農會' },
      { 3666: '0115 中研分部' },
      { 3667: '0218 後山埤分部' },
      { 3668: '0056 木柵區農會' },
      { 3669: '0252 木新分部' },
      { 3670: '0067 景美區農會' },
      { 3672: '0013 澎湖縣農會信用部' },
      { 3673: '0024 湖西分部' },
      { 3674: '0035 西嶼分部' },
      { 3675: '0046 望安分部' },
      { 3676: '0057 白沙分部' },
      { 3677: '0068 七美分部' },
      { 3691: '0016 連江縣農會' },
      { 4710: '0015 大里區農會' },
      { 4711: '0026 內新辦事處' },
      { 4712: '0037 草湖辦事處' },
      { 4713: '0048 健民辦事處' },
      { 4714: '0059 東榮辦事處' },
      { 4715: '0060 金城辦事處' },
      { 4716: '0071 十九甲辦事處' },
      { 4717: '0093 仁化辦事處' },
      { 4718: '0107 國光辦事處' },
      { 4719: '0118 成功辦事處' },
      { 4743: '0086 冬山鄉農會' },
      { 4744: '0189 羅東辦事處' },
      { 4745: '0190 順安辦事處' },
      { 4746: '0271 廣興辦事處' },
      { 4747: '0488 群英分部' },
      { 4749: '0024 草屯鎮農會' },
      { 4750: '0183 雙冬辦事處' },
      { 4751: '0493 南埔分部' },
      { 4752: '0600 芬草分部' },
      { 4753: '0611 虎山分部' },
      { 4754: '0677 北勢分部' },
      { 4755: '0688 富寮分部' },
      { 4756: '0758 新庄分部' },
      { 4766: '0018 板橋區農會' },
      { 4767: '0029 後埔辦事處' },
      { 4768: '0030 埔墘辦事處' },
      { 4769: '0041 社後辦事處' },
      { 4770: '0052 江翠辦事處' },
      { 4771: '0063 溪崑辦事處' },
      { 4772: '0074 浮洲辦事處' },
      { 4773: '0111 新埔辦事處' },
      { 4774: '0122 文化辦事處' },
      { 4779: '0103 小港區農會' },
      { 4780: '0024 彰化市農會' },
      { 4781: '0105 礁溪鄉農會' },
      { 4782: '0116 四結分部' },
      { 4783: '0127 龍潭分部' },
      { 4784: '0138 德陽分部' },
      { 4785: '0208 二水鄉農會' },
      { 4786: '0301 公館鄉農會' },
      { 4787: '0312 福基分部' },
      { 4788: '0334 五谷分部' },
      { 4789: '0345 鶴岡分部' },
      { 4790: '0404 阿里山鄉農會' },
      { 4791: '0415 中山分部' },
      { 4792: '0426 達邦分部' },
      { 4793: '0507 獅潭鄉農會' },
      { 4794: '0518 汶水分部' },
      { 4795: '0600 大安區農會' },
      { 4796: '0611 海墘辦事處' },
      { 4797: '0622 松雅分部' },
      { 4798: '0806 大肚區農會' },
      { 4799: '0817 王田分部' },
      { 4800: '0828 福山辦事處' },
      { 4801: '0839 蔗廓辦事處' },
      { 4802: '0840 成功分部' },
      { 4803: '0909 龍井區農會' },
      { 4804: '0910 龍泉辦事處' },
      { 4805: '0921 龍津辦事處' },
      { 4806: '0932 新庄辦事處' },
      { 4807: '0943 國際辦事處' },
      { 4808: '1009 卓蘭鎮農會' },
      { 4809: '1102 竹南鎮農會' },
      { 4810: '1113 中港分部' },
      { 4811: '1124 大埔分部' },
      { 4812: '1135 龍鳳分部' },
      { 4813: '1205 頭份鎮農會' },
      { 4814: '1216 珊湖分部' },
      { 4815: '1227 上街分部' },
      { 4816: '1238 尖山分部' },
      { 4817: '1249 中山分部' },
      { 4818: '1308 三灣鄉農會' },
      { 4819: '1401 造橋鄉農會' },
      { 4820: '1412 大西分部' },
      { 4821: '1423 朝陽分部' },
      { 4822: '1434 龍昇分部' },
      { 4823: '1456 豐湖分部' },
      { 4824: '1504 頭屋鄉農會' },
      { 4825: '1515 明德分部' },
      { 4826: '1607 苗栗市農會' },
      { 4827: '1618 北苗辦事處' },
      { 4828: '1629 南苗辦事處' },
      { 4829: '1630 嘉盛辦事處' },
      { 4830: '1700 西湖鄉農會' },
      { 4831: '1711 五湖分部' },
      { 4832: '1803 通霄鎮農會' },
      { 4833: '1814 白沙屯分部' },
      { 4834: '1906 銅鑼鄉農會' },
      { 4835: '1917 中平分部' },
      { 4836: '1928 新隆分部' },
      { 4837: '2006 南投市農會' },
      { 4838: '2017 內興分部' },
      { 4839: '2028 永豐分部' },
      { 4840: '2039 福山分部' },
      { 4841: '2040 營南分部' },
      { 4842: '2051 營北分部' },
      { 4843: '2062 鳳山分部' },
      { 4844: '2073 漳和分部' },
      { 4845: '2109 水里鄉農會' },
      { 4846: '2110 上安分部' },
      { 4847: '2121 玉峰分部' },
      { 4848: '2132 民和分部' },
      { 4849: '2202 信義鄉農會' },
      { 4850: '2213 和社分部' },
      { 4851: '2224 地利分部' },
      { 4852: '2305 集集鎮農會' },
      { 4853: '2316 田寮分部' },
      { 4854: '2408 埔里鎮農會' },
      { 4855: '2419 合成分部' },
      { 4856: '2420 愛蘭分部' },
      { 4857: '2431 東興分部' },
      { 4858: '2501 中寮鄉農會' },
      { 4859: '2512 爽文分部' },
      { 4860: '2545 義和分部' },
      { 4861: '2556 內城分部' },
      { 4862: '2604 魚池鄉農會' },
      { 4863: '2615 頭社分部' },
      { 4864: '2626 日月潭分部' },
      { 4865: '2637 東光分部' },
      { 4866: '2648 共和分部' },
      { 4867: '2659 五城分部' },
      { 4868: '2707 國姓鄉農會' },
      { 4869: '2718 國姓分部' },
      { 4870: '2729 長流分部' },
      { 4871: '2730 北山分部' },
      { 4872: '2741 北港分部' },
      { 4873: '2752 福龜分部' },
      { 4874: '2763 南港分部' },
      { 4875: '2800 鹿谷鄉農會' },
      { 4876: '2811 廣興分部' },
      { 4877: '2822 初鄉分部' },
      { 4878: '2833 竹林分部' },
      { 4879: '2844 坪頂分部' },
      { 4880: '2855 永隆分部' },
      { 4881: '2866 瑞田分部' },
      { 4882: '2877 鳳凰分部' },
      { 4883: '2903 仁愛鄉農會' },
      { 4884: '3003 苑裡鎮農會' },
      { 4885: '3014 山腳分部' },
      { 4886: '3025 社苓分部' },
      { 4887: '3106 三義鄉農會' },
      { 4888: '3117 鯉魚潭分部' },
      { 4889: '3209 大湖地區農會' },
      { 4890: '3210 新開分部' },
      { 4891: '3221 南湖分部' },
      { 4892: '3232 清安分部' },
      { 4893: '3243 大湖分部' },
      { 4894: '3302 南庄鄉農會' },
      { 4895: '3313 田美分部' },
      { 4896: '3324 南富分部' },
      { 4897: '3405 線西鄉農會' },
      { 4898: '3508 太平區農會' },
      { 4899: '3519 新光分部' },
      { 4900: '3520 頭汴分部' },
      { 4901: '3531 光隆分部' },
      { 4902: '3542 中山分部' },
      { 4903: '3553 東平分部' },
      { 4904: '3564 坪林分部' },
      { 4905: '3575 新興分部' },
      { 4906: '3601 花蓮市農會' },
      { 4907: '3612 花商分部' },
      { 4908: '3634 自強分部' },
      { 4909: '3645 美崙分部' },
      { 4910: '3704 林內鄉農會' },
      { 4911: '3807 田尾鄉農會' },
      { 4912: '3818 溪畔分部' },
      { 4913: '3829 福田分部' },
      { 4914: '3830 海豐分部' },
      { 4915: '3900 北斗鎮農會' },
      { 4916: '4000 大甲區農會' },
      { 4917: '4011 順天分部' },
      { 4918: '4022 日南分部' },
      { 4919: '4033 西岐分部' },
      { 4920: '4044 幼獅社區分部' },
      { 4921: '4055 庄美分部' },
      { 4922: '4103 台中市石岡區農會' },
      { 4923: '4114 和盛分部' },
      { 4924: '4206 新社區農會' },
      { 4925: '4217 福興分部' },
      { 4926: '4228 中興嶺分部' },
      { 4927: '4309 梧棲區農會' },
      { 4928: '4310 興農分部' },
      { 4929: '4321 安寧分部' },
      { 4930: '4332 大莊分部' },
      { 4931: '4343 南簡分部' },
      { 4932: '4402 后里區農會' },
      { 4933: '4413 義里分部' },
      { 4934: '4424 公館分部' },
      { 4935: '4435 眉山分部' },
      { 4936: '4505 清水區農會' },
      { 4937: '4516 臨江辦事處' },
      { 4938: '4527 南社分部' },
      { 4939: '4608 霧峰區農會' },
      { 4940: '4619 北柳分部' },
      { 4941: '4620 萬豐分部' },
      { 4942: '4631 吉峰分部' },
      { 4943: '4642 舊正分部' },
      { 4944: '4653 吉峰路分部' },
      { 4945: '4701 外埔區農會' },
      { 4946: '4712 土城辦事處' },
      { 4947: '4723 大東分部' },
      { 4948: '4804 烏日區農會' },
      { 4949: '4815 溪埧分部' },
      { 4950: '4826 九德分部' },
      { 4951: '4837 成功分部' },
      { 4952: '4907 潭子區農會' },
      { 4953: '4918 頭家分部' },
      { 4954: '4929 東寶分部' },
      { 4955: '4930 栗林辦事處' },
      { 4956: '4941 甘蔗辦事處' },
      { 4957: '5007 伸港鄉農會' },
      { 4958: '5100 和美鎮農會' },
      { 4959: '5122 塗厝分部' },
      { 4960: '5133 新庄分部' },
      { 4961: '5144 鎮平分部' },
      { 4962: '5203 花壇鄉農會' },
      { 4963: '5214 白沙分部' },
      { 4964: '5225 灣雅分部' },
      { 4965: '5236 口庄分部' },
      { 4966: '5258 長沙分部' },
      { 4967: '5306 福興鄉農會' },
      { 4968: '5317 管嶼分部' },
      { 4969: '5328 外中分部' },
      { 4970: '5409 大村鄉農會' },
      { 4971: '5410 大村分部' },
      { 4972: '5421 美港分部' },
      { 4973: '5432 大西辦事處' },
      { 4974: '5443 村東辦事處' },
      { 4975: '5502 溪湖鎮農會' },
      { 4976: '5605 社頭鄉農會' },
      { 4977: '5616 湳雅分部' },
      { 4978: '5627 橋頭分部' },
      { 4979: '5638 清水分部' },
      { 4980: '5708 大城鄉農會' },
      { 4981: '5719 西港分部' },
      { 4982: '5801 溪州鄉農會' },
      { 4983: '5812 西畔分部' },
      { 4984: '5823 大庄分部' },
      { 4985: '5904 田中鎮農會' },
      { 4986: '6004 南投縣竹山鎮農會' },
      { 4987: '6015 社寮分部' },
      { 4988: '6026 瑞竹分部' },
      { 4989: '6037 延平分部' },
      { 4990: '6048 中央分部' },
      { 4991: '6107 麥寮鄉農會' },
      { 4992: '6118 橋頭分部' },
      { 4993: '6200 雲林縣東勢鄉農會' },
      { 4994: '6211 新坤辦事處' },
      { 4995: '6303 土庫鎮農會' },
      { 4996: '6314 馬光分部' },
      { 4997: '6325 新庄辦事處' },
      { 4998: '6336 忠正辦事處' },
      { 4999: '6358 後埔分部' },
      { 5000: '6406 元長鄉農會' },
      { 5001: '6417 鹿寮分部' },
      { 5002: '6428 客庴分部' },
      { 5003: '6509 北港鎮農會' },
      { 5004: '6510 好收分部' },
      { 5005: '6602 水林鄉農會' },
      { 5006: '6613 蔦松辦事處' },
      { 5007: '6624 西井辦事處' },
      { 5008: '6635 溪墘辦事處' },
      { 5009: '6646 宏仁分部' },
      { 5010: '6705 中埔鄉農會' },
      { 5011: '6716 三層分部' },
      { 5012: '6727 同仁分部' },
      { 5013: '6738 金蘭分部' },
      { 5014: '6749 隆興分部' },
      { 5015: '6750 和睦分部' },
      { 5016: '6761 沄水分部' },
      { 5017: '6772 和興分部' },
      { 5018: '6783 和美分部' },
      { 5019: '6808 鹿港鎮農會' },
      { 5020: '6819 草港分部' },
      { 5021: '6820 頂番分部' },
      { 5022: '6831 海埔分部' },
      { 5023: '6842 東崎分部' },
      { 5024: '6901 瑞穗鄉本會' },
      { 5025: '6912 富源分部' },
      { 5026: '7001 官田區農會' },
      { 5027: '7012 西庄辦事處' },
      { 5028: '7023 官田辦事處' },
      { 5029: '7034 二鎮辦事處' },
      { 5030: '7045 渡頭辦事處' },
      { 5031: '7056 湖山辦事處' },
      { 5032: '7104 大內區農會' },
      { 5033: '7115 二溪辦事處' },
      { 5034: '7126 頭社分部' },
      { 5035: '7137 環湖分部' },
      { 5036: '7207 新市區農會' },
      { 5037: '7218 大社分部' },
      { 5038: '7300 左鎮區農會' },
      { 5039: '7403 山上區農會' },
      { 5040: '7506 柳營區農會' },
      { 5041: '7517 太康辦事處' },
      { 5042: '7528 重溪辦事處' },
      { 5043: '7539 旭山辦事處' },
      { 5044: '7540 果毅辦事處' },
      { 5045: '7609 七股區農會' },
      { 5046: '7702 玉溪地區農會' },
      { 5047: '7724 松浦分部' },
      { 5048: '7805 學甲區農會' },
      { 5049: '7816 中洲分部' },
      { 5050: '7827 宅港分部' },
      { 5051: '7838 頂洲分部' },
      { 5052: '7849 草圣分部' },
      { 5053: '7850 大灣分部' },
      { 5054: '7908 下營區農會' },
      { 5055: '7919 中興分部' },
      { 5056: '7920 中營分部' },
      { 5057: '7931 甲中分部' },
      { 5058: '8053 埔鹽鄉農會' },
      { 5059: '8064 新水分部' },
      { 5060: '8101 麻豆區農會' },
      { 5061: '8112 光復分部' },
      { 5062: '8123 新生分部' },
      { 5063: '8134 復光分部' },
      { 5064: '8145 安業分部' },
      { 5065: '8204 仁德區農會' },
      { 5066: '8215 太子分部' },
      { 5067: '8226 後壁分部' },
      { 5068: '8237 保安分部' },
      { 5069: '8248 中洲分部' },
      { 5070: '8259 二行分部' },
      { 5071: '8307 關廟區農會' },
      { 5072: '8318 龜洞辦事處' },
      { 5073: '8329 埤頭辦事處' },
      { 5074: '8400 龍崎區農會' },
      { 5075: '8411 龍船分部' },
      { 5076: '8503 安定區農會' },
      { 5077: '8514 蘇厝分部' },
      { 5078: '8525 港口辦事處' },
      { 5079: '8536 中沙分部' },
      { 5080: '8547 海寮分部' },
      { 5081: '8606 白河區農會' },
      { 5082: '8617 市場分部' },
      { 5083: '8628 關嶺分部' },
      { 5084: '8639 竹門分部' },
      { 5085: '8640 玉豐分部' },
      { 5086: '8651 內角分部' },
      { 5087: '8709 東山區農會' },
      { 5088: '8710 東原辦事處' },
      { 5089: '8721 青山辦事處' },
      { 5090: '8732 南溪辦事處' },
      { 5091: '8743 聖賢辦事處' },
      { 5092: '8802 後壁區農會' },
      { 5093: '8813 菁寮辦事處' },
      { 5094: '8824 安溪辦事處' },
      { 5095: '8835 上茄苳分部' },
      { 5096: '8846 新東分部' },
      { 5097: '8857 竹新分部' },
      { 5098: '9005 內埔地區農會' },
      { 5099: '9016 豐田分部' },
      { 5100: '9027 東勢分部' },
      { 5101: '9038 龍泉分部' },
      { 5102: '9049 水門分部' },
      { 5103: '9050 老埤分部' },
      { 5104: '9108 鳳榮地區農會' },
      { 5105: '9119 林榮分部' },
      { 5106: '9120 萬榮分部' },
      { 5107: '9201 光豐地區農會' },
      { 5108: '9212 大富分部' },
      { 5109: '9223 豐濱分部' },
      { 5110: '9304 和平區農會' },
      { 5111: '9315 梨山辦事處' },
      { 5112: '9326 雙崎辦事處' },
      { 5113: '9407 大雅區農會' },
      { 5114: '9418 上楓分部' },
      { 5115: '9429 馬岡分部' },
      { 5116: '9430 忠義分部' },
      { 5117: '9452 橫山分部' },
      { 5118: '9603 台中市東勢區農會' },
      { 5119: '9706 台中市沙鹿區農會' },
      { 5120: '9717 北勢分部' },
      { 5121: '9728 鹿峰分部' },
      { 5122: '9739 西明辦事處' },
      { 5123: '9740 公館辦事處' },
      { 5124: '9809 新化區農會' },
      { 5125: '9810 那拔林分部' },
      { 5126: '9821 中山分部' },
      { 5127: '9902 南化區農會' },
      { 5128: '0703 南龍區漁會' },
      { 5129: '0714 竹南分部' },
      { 5130: '8905 彰化區漁會' },
      { 5131: '8916 王功分部' },
      { 5132: '8927 芳苑分部' },
      { 5133: '8938 大城分部' },
      { 5134: '8949 草港分部' },
      { 5135: '8950 福興分部' },
      { 5136: '8961 伸港分部' },
      { 5137: '8972 埔心分部' },
      { 5138: '9500 通苑區漁會' },
      { 5139: '9511 苑裡分部' }
    ],
    90: [
      { 2982: '0018 信用部' },
      { 2983: '0029 三民分部' },
      { 2984: '0030 鼓山分部' },
      { 2985: '0041 新興分部' },
      { 2986: '0052 鼎力分部' },
      { 2987: '0063 苓雅分部' },
      { 2988: '0074 前鎮分部' },
      { 2989: '0085 楠梓分部' },
      { 2990: '0096 右昌分部' },
      { 2991: '0100 後勁分部' },
      { 2992: '0111 左營分部' },
      { 2993: '0122 營北分部' },
      { 2994: '0133 新庄分部' }
    ],
    109: [
      { 3679: '0014 臺中地區農會信用部' },
      { 3680: '0025 西屯分部' },
      { 3681: '0036 北屯分部' },
      { 3682: '0047 南屯分部' },
      { 3683: '0058 四民分部' },
      { 3684: '0069 西區分部' },
      { 3685: '0070 東區分部' },
      { 3686: '0092 南區分部' },
      { 3687: '0117 北區分部' },
      { 3688: '0139 軍功分部' },
      { 3689: '0140 大坑分部' }
    ],
    111: [{ 3693: '0010 郵政劃撥儲金' }, { 3694: '0021 郵政存簿儲金' }],
    112: [
      { 3696: '0010 台北分行' },
      { 3697: '0021 營業部' },
      { 3698: '0032 桃園分行' },
      { 3699: '0043 台中分行' },
      { 3700: '0054 南京東路分行' },
      { 3701: '0065 中壢分行' },
      { 3702: '0076 三重分行' },
      { 3703: '0098 東台北分行' },
      { 3704: '0102 高雄分行' },
      { 3705: '0113 板橋分行' },
      { 3706: '0124 台南分行' },
      { 3707: '0135 員林分行' },
      { 3708: '0157 仁愛分行' },
      { 3709: '0168 南崁分行' },
      { 3710: '0179 新竹分行' },
      { 3711: '0180 蘆洲分行' },
      { 3712: '0205 九如分行' },
      { 3713: '0216 雙和分行' },
      { 3714: '0227 迴龍分行' },
      { 3715: '0238 嘉義分行' },
      { 3716: '0249 公館分行' },
      { 3717: '0250 內壢分行' },
      { 3718: '0261 忠孝分行' },
      { 3719: '0272 新莊分行' },
      { 3720: '0283 鳳山分行' },
      { 3721: '0294 桃鶯分行' },
      { 3722: '0308 龍潭分行' },
      { 3723: '0319 新店分行' },
      { 3724: '0320 大直分行' },
      { 3725: '0331 中山簡易型分行' },
      { 3726: '0342 文心分行' },
      { 3727: '0353 健行分行' },
      { 3728: '0364 中和分行' },
      { 3729: '0375 內湖分行' },
      { 3730: '0386 大園分行' },
      { 3731: '0397 永和分行' },
      { 3732: '0401 中港簡易型分行' },
      { 3733: '0412 通化簡易型分行' },
      { 3734: '0423 士東分行' },
      { 3735: '0434 微風簡易型分行' },
      { 3736: '0445 長春分行' },
      { 3737: '0456 松江分行' },
      { 3738: '0467 大安分行' },
      { 3739: '0478 永吉分行' },
      { 3740: '0489 文林分行' },
      { 3741: '0490 東門分行' },
      { 3742: '0504 敦化分行' },
      { 3743: '0515 西湖分行' },
      { 3744: '0526 北投簡易型分行' },
      { 3745: '0537 北三重分行' },
      { 3746: '0548 後埔分行' },
      { 3747: '0559 北中和簡易型分行' },
      { 3748: '0560 富國分行' },
      { 3749: '0571 樹林分行' },
      { 3750: '0582 汐止分行' },
      { 3751: '0593 北桃園分行' },
      { 3752: '0607 北中壢分行' },
      { 3753: '0618 北台中分行' },
      { 3754: '0629 民權分行' },
      { 3755: '0630 西屯分行' },
      { 3756: '0641 興中分行' },
      { 3757: '0652 北屯分行' },
      { 3758: '0663 南員林分行' },
      { 3759: '0674 東嘉義分行' },
      { 3760: '0685 府城分行' },
      { 3761: '0696 豐原分行' },
      { 3762: '0700 富強分行' },
      { 3763: '0711 開元分行' },
      { 3764: '0722 南台南分行' },
      { 3765: '0733 苓雅分行' },
      { 3766: '0744 北高雄分行' },
      { 3767: '0755 三民分行' },
      { 3768: '0766 五甲分行' },
      { 3769: '0777 屏東分行' },
      { 3770: '0788 和平分行' },
      { 3771: '0799 永春分行' },
      { 3772: '0803 田心分行' },
      { 3773: '0814 安康分行' },
      { 3774: '0825 大竹分行' },
      { 3775: '0836 龜山分行' },
      { 3776: '0847 林口分行' },
      { 3777: '0858 五股分行' },
      { 3778: '0869 高榮分行' },
      { 3779: '0870 大業分行' },
      { 3780: '0881 蘆竹分行' },
      { 3781: '0892 土城分行' },
      { 3782: '0906 南桃園分行' },
      { 3783: '0917 集賢分行' },
      { 3784: '0928 三峽分行' },
      { 3785: '0939 東湖分行' },
      { 6402: '0940 淡水簡易型分行' },
      { 6403: '0951 金城分行' }
    ],
    113: [
      { 3787: '0012 營業部' },
      { 3788: '0023 台北襄陽分行' },
      { 3789: '0034 台北逸仙分行' },
      { 3790: '0045 桃園分行' },
      { 3791: '0056 台中公益分行' },
      { 3792: '0067 台南分行' },
      { 3793: '0078 高雄五福分行' },
      { 3794: '0090 板橋南雅分行' },
      { 3795: '0104 新竹巨城分行' },
      { 3796: '0115 高雄中正分行' },
      { 3797: '0148 三重分行' },
      { 3798: '0159 台北松江分行' },
      { 3799: '0160 永康分行' },
      { 3800: '0171 中壢分行' },
      { 3801: '0193 永和分行' },
      { 3802: '0207 台北東門分行' },
      { 3803: '0218 高雄文化中心分行' },
      { 3804: '0229 台中文心分行' },
      { 3805: '0230 新竹科學園區分行' },
      { 3806: '0241 桃園大興分行' },
      { 3807: '0252 台北南門分行' },
      { 3808: '0263 台北新莊分行' },
      { 3809: '0274 台北松山分行' },
      { 3810: '0285 新店分行' },
      { 3811: '0296 台北重慶分行' },
      { 3812: '0300 竹北分行' },
      { 3813: '0311 台北忠孝分行' },
      { 3814: '0322 林口分行' },
      { 3815: '0333 板橋文化分行' },
      { 3816: '0344 台中自由分行' },
      { 3817: '0355 新莊富國分行' },
      { 3818: '0366 台北永吉分行' },
      { 3819: '0377 三重重陽簡易型分行' },
      { 3820: '0388 桃園大有分行' },
      { 3821: '0399 台北金湖簡易型分行' },
      { 3822: '0403 台北農安簡易型分行' },
      { 3823: '0414 台北中山分行' },
      { 3824: '0425 台北古亭分行' },
      { 3825: '0436 台北信義分行' },
      { 3826: '0458 蘆洲分行' },
      { 3827: '0469 中和分行' },
      { 3828: '0470 板橋中正分行' },
      { 3829: '0481 新竹經國分行' },
      { 3830: '0492 台中大雅分行' },
      { 3831: '0506 台中朝富分行' },
      { 3832: '0517 南投分行' },
      { 3833: '0528 嘉義分行' },
      { 3834: '0539 高雄苓雅分行' },
      { 3835: '0540 鳳山分行' },
      { 3836: '0562 大里分行' },
      { 3837: '0573 崇德分行' },
      { 3838: '0584 板橋中本分行' },
      { 3839: '0595 台北一０一分行' },
      { 3840: '0609 雙和分行' },
      { 3841: '0610 台北南京東路分行' },
      { 3842: '0621 台北大稻埕分行' },
      { 6404: '0632 石牌分行' },
      { 6405: '0654 台北復興簡易型分行' }
    ],
    114: [
      { 3844: '0013 台中分行' },
      { 3845: '0024 文心分行' },
      { 3846: '0035 台北分行' },
      { 3847: '0046 沙鹿分行' },
      { 3848: '0057 豐原分行' },
      { 3849: '0068 彰化分行' },
      { 3850: '0079 員林分行' },
      { 3851: '0080 三重分行' },
      { 3852: '0091 平鎮分行' },
      { 3853: '0105 草屯分行' },
      { 3854: '0116 永康分行' },
      { 3855: '0150 景美分行' },
      { 3856: '0161 新竹分行' },
      { 3857: '0172 鹿港分行' },
      { 3858: '0183 斗信分行' },
      { 3859: '0194 嘉義分行' },
      { 3860: '0219 南京東路分行' },
      { 3861: '0220 永和分行' },
      { 3862: '0231 復興分行' },
      { 3863: '0242 大里分行' },
      { 3864: '0253 博愛分行' },
      { 3865: '0264 苗栗分行' },
      { 3866: '0275 崇德分行' },
      { 3867: '0286 北斗分行' },
      { 3868: '0297 佳里分行' },
      { 3869: '0301 大甲分行' },
      { 3870: '0312 虎尾分行' },
      { 3871: '0334 士林分行' },
      { 3872: '0345 南崁分行' },
      { 3873: '0367 古亭分行' },
      { 3874: '0378 新莊分行' },
      { 3875: '0389 中壢分行' },
      { 3876: '0404 三民分行' },
      { 3877: '0415 城中分行' },
      { 3878: '0426 屏東分行' },
      { 3879: '0448 斗南分行' },
      { 3880: '0482 板橋分行' },
      { 3881: '0493 桃園分行' },
      { 3882: '0518 鳳山分行' },
      { 3883: '0529 東信分行' },
      { 3884: '0585 內湖分行' },
      { 3885: '0596 羅東分行' },
      { 3886: '0600 金門分行' },
      { 3887: '0611 台南分行' },
      { 3888: '0622 林口分行' },
      { 3889: '0633 竹北分行' },
      { 3890: '0688 府城分行' },
      { 3891: '0699 府東分行' },
      { 3892: '0806 開元分行' },
      { 3893: '0828 安和分行' },
      { 3894: '0839 中和分行' },
      { 3895: '0840 松江分行' },
      { 3896: '0851 竹科分行' },
      { 3897: '0862 天母分行' },
      { 3898: '0873 蘆洲分行' },
      { 3899: '0884 信義分行' },
      { 3900: '0895 中山北路分行' },
      { 3901: '0909 民生分行' },
      { 3902: '0910 新店分行' },
      { 3903: '0932 忠孝分行' },
      { 3904: '0943 桃興分行' },
      { 3905: '0954 中港分行' },
      { 3906: '0965 花蓮分行' },
      { 3907: '0976 承德分行' },
      { 3908: '0987 太平分行' },
      { 3909: '0998 營業部' },
      { 3910: '1009 土城分行' },
      { 3911: '1010 館前分行' },
      { 3912: '1021 大同分行' },
      { 3913: '1032 松山分行' },
      { 3914: '1043 大安分行' },
      { 3915: '1076 北三重分行' },
      { 3916: '1087 上新莊分行' },
      { 3917: '1102 高雄分行' },
      { 3918: '1191 大統分行' },
      { 3919: '1205 雙和分行' },
      { 3920: '1216 北投分行' },
      { 3921: '1227 延平分行' },
      { 3922: '1238 文德分行' },
      { 3923: '1249 敦南分行' },
      { 3924: '1250 左營分行' },
      { 3925: '1261 埔墘分行' },
      { 3926: '1272 公館分行' },
      { 3927: '1283 新店中正分行' },
      { 3928: '1294 和平分行' },
      { 3929: '1308 西門分行' },
      { 3930: '1319 樹林分行' },
      { 3931: '1320 光復分行' },
      { 5150: '1342 苓雅分行' },
      { 5151: '1836 旗津分行' },
      { 5152: '1526 成功分行' },
      { 5154: '1445 水湳分行' },
      { 5155: '1548 右昌分行' },
      { 5156: '1559 前金分行' },
      { 5157: '1560 五甲分行' },
      { 5158: '1571 連城分行' },
      { 5159: '1582 新營分行' },
      { 5160: '1593 大和平分行' },
      { 5161: '1641 江翠分行' },
      { 5162: '1652 長庚分行' },
      { 5163: '1674 世貿分行' },
      { 5164: '1685 東新竹分行' },
      { 5165: '1696 汐止分行' },
      { 5166: '1722 澄清分行' },
      { 5167: '1733 秀朗分行' },
      { 5168: '1744 小港分行' },
      { 5169: '1755 北桃園分行' },
      { 5170: '1766 永春分行' },
      { 5171: '1777 圓山分行' },
      { 5172: '1788 豐仁分行' },
      { 5173: '1799 彰興分行' },
      { 5174: '1825 北台中分行' },
      { 5175: '1858 鼓山分行' },
      { 5176: '1869 前鎮分行' },
      { 5177: '1870 明誠分行' },
      { 5178: '1881 中正分行' },
      { 5179: '1892 石牌分行' },
      { 5180: '1939 新板分行' },
      { 5181: '1940 北新分行' },
      { 5182: '1951 南屯分行' },
      { 5183: '1962 中山分行' },
      { 5184: '1973 東門分行' },
      { 5185: '1984 金城分行' },
      { 5186: '1995 民族分行' },
      { 5189: '1467 華山分行' },
      { 5192: '1375 前鎮中山分行' },
      { 5193: '1504 延吉分行' },
      { 5194: '2006 頭份分行' },
      { 5195: '1331 南港分行 ' },
      { 5196: '1353 敦化分行 ' },
      { 5197: '1364 屏榮分行 ' },
      { 5198: '1386 高鳳分行 ' },
      { 5199: '1397 北府分行 ' },
      { 5200: '1401 南嘉義分行 ' },
      { 5201: '1412 東台北分行 ' },
      { 5202: '1423 南新莊分行 ' },
      { 5203: '1434 新壢分行 ' },
      { 5204: '1456 大昌分行 ' },
      { 5205: '1478 東板橋分行 ' },
      { 5206: '1489 沙鹿中山分行 ' },
      { 5207: '1490 十全分行 ' },
      { 5208: '1607 金華分行 ' },
      { 5209: '1618 瑞光分行 ' },
      { 5210: '1629 灣裡分行 ' },
      { 5211: '1630 安南分行 ' },
      { 5212: '1700 忠誠分行 ' },
      { 5213: '1814 大里德芳分行 ' },
      { 5214: '1928 南門分行 ' },
      { 6406: '2017 萬華分行' },
      { 6407: '2028 敦安分行' }
    ],
    115: [
      { 3933: '0014 世貿分行' },
      { 3934: '0025 敦北分行' },
      { 3935: '0036 台中分行' },
      { 3936: '0047 高雄分行' },
      { 3937: '0058 忠孝分行' },
      { 3938: '0069 西松分行' },
      { 3939: '0070 板新分行' },
      { 3940: '0092 敦南分行' },
      { 3941: '0106 新泰分行' },
      { 3942: '0117 桃園分行' },
      { 3943: '0128 北台中分行' },
      { 3944: '0139 台南分行' },
      { 3945: '0151 松江分行' },
      { 3946: '0162 嘉義分行' },
      { 3947: '0173 三民分行' },
      { 3948: '0184 新竹分行' },
      { 3949: '0195 三重分行' },
      { 3950: '0210 台北分行' },
      { 3951: '0221 彰化分行' },
      { 3952: '0232 岡山分行' },
      { 3953: '0243 中壢分行' },
      { 3954: '0254 鳳山分行' },
      { 3955: '0265 中正簡易型分行' },
      { 3956: '0276 北新分行' },
      { 3957: '0287 屏東分行' },
      { 3958: '0298 北桃園分行' },
      { 3959: '0302 學府分行' },
      { 3960: '0313 南高雄分行' },
      { 3961: '0324 光華分行' },
      { 3962: '0335 東門分行' },
      { 3963: '0346 天母分行' },
      { 3964: '0357 東板橋分行' },
      { 3965: '0368 東台南分行' },
      { 3966: '0379 竹南分行' },
      { 3967: '0380 南台中分行' },
      { 3968: '0391 竹北自強簡易型分行' },
      { 3969: '0405 永康分行' },
      { 3970: '0416 中科分行' },
      { 3971: '0427 北高雄分行' },
      { 3972: '0438 金門分行' },
      { 3973: '0449 興大簡易型分行' },
      { 3974: '0450 中崙分行' },
      { 3975: '0461 西湖分行' },
      { 3976: '0472 豐原分行' },
      { 3977: '0483 大里分行' },
      { 3978: '1011 忠孝東路分行' },
      { 3979: '1033 建成分行' },
      { 3980: '1044 西門簡易型分行' },
      { 3981: '1055 萬華分行' },
      { 3982: '1066 興隆分行' },
      { 3983: '1077 南門分行' },
      { 3984: '1088 長安分行' },
      { 3985: '1099 延平分行' },
      { 3986: '1103 北投分行' },
      { 3987: '1114 松山分行' },
      { 3988: '1125 中山分行' },
      { 3989: '1136 士林分行' },
      { 3990: '1147 三和分行' },
      { 3991: '1158 雙園分行' },
      { 3992: '1169 永和分行' },
      { 3993: '1170 板橋分行' },
      { 3994: '1181 基隆分行' },
      { 3995: '1192 羅東分行' },
      { 3996: '1206 宜蘭分行' },
      { 3997: '1217 營業部' },
      { 3998: '1228 景美分行' },
      { 3999: '1239 新莊分行' },
      { 4000: '1251 汐止分行' },
      { 4001: '1262 城中分行' },
      { 4002: '1273 光復分行' },
      { 4003: '1284 正義分行' },
      { 4004: '1295 中和分行' },
      { 4005: '1310 士東分行' },
      { 4006: '1321 中港分行' },
      { 4007: '1332 蘆洲分行' },
      { 4008: '1343 內湖分行' },
      { 4009: '1354 社子分行' },
      { 4010: '1365 信義分行' },
      { 4011: '1376 永春分行' },
      { 4012: '1387 南三重分行' },
      { 4013: '1398 積穗分行' },
      { 4014: '1402 鶯歌分行' },
      { 4015: '1413 新店分行' },
      { 4016: '1435 西盛分行' },
      { 4017: '1446 板橋忠孝分行' },
      { 4018: '1457 思源分行' },
      { 4019: '1468 土城分行' },
      { 4020: '1479 三興分行' },
      { 4021: '1480 龍江分行' },
      { 4022: '1491 樹林分行' },
      { 4023: '1505 五股分行' },
      { 4024: '1538 松德分行' },
      { 4025: '1549 東湖分行' },
      { 4026: '1550 華江分行' },
      { 4027: '1561 北三重分行' },
      { 4028: '1572 蘭雅分行' },
      { 4029: '1583 德惠分行' },
      { 4030: '1594 迴龍分行' },
      { 4031: '1608 仁愛分行' },
      { 4032: '1620 和平分行' },
      { 4033: '1642 重新分行' },
      { 4034: '1653 中興分行' },
      { 4035: '1664 海山分行' },
      { 4036: '1697 南蘆洲分行' },
      { 4037: '1701 江子翠分行' },
      { 4038: '1712 民安分行' },
      { 4039: '1723 苓雅分行' },
      { 4040: '1734 鶯桃分行' },
      { 4041: '1745 板橋民族分行' },
      { 4042: '1756 泰山分行' },
      { 4043: '1767 花蓮分行' },
      { 4044: '1778 南港分行' },
      { 4045: '1789 深坑分行' },
      { 4046: '1790 南崁分行' },
      { 4047: '1804 北台南分行' },
      { 4048: '1815 西屯分行' },
      { 4049: '1826 成大分行' },
      { 4050: '1837 濟南路分行' },
      { 4051: '1848 淡水簡易型分行' },
      { 4052: '1859 南桃園分行' },
      { 4053: '1860 竹科分行' },
      { 4054: '1871 員林分行' },
      { 4055: '1882 林口分行' },
      { 4056: '1893 內壢分行' },
      { 4057: '1907 重慶北路分行' },
      { 4058: '1918 新湖分行' },
      { 4059: '1929 大園分行' },
      { 4060: '1930 竹北光明分行' },
      { 4061: '1963 竹圍分行' }
    ],
    116: [
      { 4063: '0015 營業部' },
      { 4064: '0026 南京東路分行' },
      { 4065: '0048 城東分行' },
      { 4066: '0059 新莊分行' },
      { 4067: '0060 新竹分行' },
      { 4068: '0071 嘉義分行' },
      { 4069: '0082 高雄分行' },
      { 4070: '0118 基隆路分行' },
      { 4071: '0129 雙和分行' },
      { 4072: '0130 中壢分行' },
      { 4073: '0141 文心分行' },
      { 4074: '0152 台南分行' },
      { 4075: '0163 天母分行' },
      { 4076: '0174 埔墘分行' },
      { 4077: '0185 桃園分行' },
      { 4078: '0196 前鎮分行' },
      { 4079: '0211 民生分行' },
      { 4080: '0222 大里分行' },
      { 4081: '0233 永康分行' },
      { 4082: '0244 鳳山分行' },
      { 4083: '0255 三重分行' },
      { 4084: '0266 土城分行' },
      { 4085: '0277 八德分行' },
      { 4086: '0288 大墩分行' },
      { 4087: '0299 七賢分行' },
      { 4088: '0303 復興分行' },
      { 4089: '0314 敦南分行' },
      { 4090: '0325 重新分行' },
      { 4091: '0336 彰化分行' },
      { 4092: '0347 北高雄分行' },
      { 4093: '0358 大雅分行' },
      { 4094: '0369 楊梅分行' },
      { 4095: '0370 樹林分行' },
      { 4096: '0381 豐原分行' },
      { 4097: '0392 斗六分行' },
      { 4098: '0406 長春分行' },
      { 4099: '0417 中山分行' },
      { 4100: '0428 佳里分行' },
      { 4101: '0439 中和分行' },
      { 4102: '0440 竹南分行' },
      { 4103: '0451 羅東分行' },
      { 4104: '0462 內湖分行' },
      { 4105: '0473 壢新分行' },
      { 4106: '0484 板新分行' },
      { 4107: '0495 光華分行' },
      { 4108: '0509 東嘉義分行' },
      { 4109: '0510 成功分行' },
      { 4110: '0521 永和分行' },
      { 4111: '0532 城中分行' },
      { 4112: '0543 五股分行' },
      { 4113: '0554 新店分行' },
      { 4114: '0565 松山分行' },
      { 4115: '0576 和平分行' },
      { 4116: '0587 新板特區分行' },
      { 4117: '0598 民權分行' },
      { 4118: '0602 泰山分行' },
      { 4119: '0613 南崁分行' },
      { 4120: '0624 台大分行' },
      { 4121: '0635 左營分行' },
      { 4122: '0646 楠梓分行' },
      { 4123: '0657 士林分行' },
      { 4124: '0668 苓雅分行' },
      { 4125: '0679 三民分行' },
      { 4126: '0680 南屯分行' },
      { 4127: '0691 木柵簡易型分行' },
      { 4128: '0705 大昌分行' },
      { 4129: '0716 台東簡易型分行' },
      { 4130: '0727 澄清分行' },
      { 4131: '0738 小港分行' },
      { 4132: '0749 北新莊分行' },
      { 4133: '0750 竹北分行' },
      { 4134: '0761 東台南分行' },
      { 4135: '0772 集賢簡易型分行' },
      { 4136: '0783 基隆分行' },
      { 4137: '0794 岡山分行' },
      { 4138: '0808 連城分行' },
      { 4139: '0819 林園分行' },
      { 4140: '0820 蘆洲分行' },
      { 4141: '0831 後庄分行' },
      { 4142: '0842 中路特區分行' },
      { 4143: '0853 東三重分行' },
      { 4144: '0864 北投分行' },
      { 4145: '0875 忠孝分行' },
      { 4146: '0886 林口分行' },
      { 4147: '0897 東湖分行' },
      { 4148: '0901 北新分行' },
      { 4149: '0912 中崙分行' },
      { 4150: '0923 建成分行' },
      { 4151: '0934 屏東分行' },
      { 4152: '0945 二重分行' },
      { 4153: '0956 台北分行' },
      { 4154: '0967 大安分行' },
      { 4155: '0978 東港分行' },
      { 4156: '0989 古亭分行' },
      { 4157: '0990 草屯分行' },
      { 4158: '1001 新營分行' },
      { 4159: '1012 迴龍分行' },
      { 4160: '1023 花蓮分行' },
      { 4161: '1034 澎湖分行' },
      { 4162: '1045 三峽分行' },
      { 4163: '1056 松江分行' },
      { 4164: '1067 金華分行' },
      { 4165: '1078 光復分行' },
      { 4166: '1089 新湖分行' },
      { 4167: '1090 桃鶯分行' },
      { 4168: '1104 員林分行' },
      { 4169: '1115 永安分行' },
      { 4170: '1126 新豐分行' },
      { 4171: '1137 仁德分行' },
      { 4172: '1148 泰和簡易型分行' },
      { 4173: '1159 西屯分行' },
      { 4174: '1160 南勢角分行' },
      { 4175: '1171 板橋分行' },
      { 4176: '1182 南港分行' },
      { 4177: '1193 東門分行' },
      { 4178: '1207 南土城分行' },
      { 4179: '1218 朴子分行' },
      { 4180: '1229 信義分行' },
      { 4181: '1230 汐止分行' },
      { 4182: '1241 新樹分行' },
      { 4183: '1252 中工分行' },
      { 4184: '1263 瑞光分行' },
      { 4185: '1274 鹽行分行' },
      { 4186: '1285 頭份分行' },
      { 4187: '1296 後龍分行' },
      { 4188: '1300 五權分行' },
      { 4189: '1311 南永康分行' },
      { 4190: '1322 藝文分行' },
      { 4191: '1333 中原分行' },
      { 4192: '1344 竹科分行' },
      { 4193: '1355 太平分行' },
      { 4194: '1366 台中分行' },
      { 4195: '1377 六家分行' },
      { 4196: '1388 北屯分行' },
      { 4197: '1399 烏日分行' },
      { 4198: '1403 安南分行' },
      { 6408: '1425 沙鹿分行' },
      { 6409: '1436 東林口分行' },
      { 6410: '1458 南山廣場分行' }
    ],
    117: [
      { 4200: '0016 中山分行' },
      { 4201: '0027 忠孝分行' },
      { 4202: '0038 三重分行' },
      { 4203: '0049 台中分行' },
      { 4204: '0050 台南分行' },
      { 4205: '0061 高雄分行' },
      { 4206: '0072 城東分行' },
      { 4207: '0094 營業部' },
      { 4208: '0108 板橋分行' },
      { 4209: '0119 桃園分行' },
      { 4210: '0120 中壢分行' },
      { 4211: '0131 北高雄分行' },
      { 4212: '0153 新莊分行' },
      { 4213: '0164 中正分行' },
      { 4214: '0175 員林分行' },
      { 4215: '0197 敦南分行' },
      { 4216: '0201 松江分行' },
      { 4217: '0212 蘆洲分行' },
      { 4218: '0223 鳳山分行' },
      { 4219: '0234 新竹分行' },
      { 4220: '0245 松山分行' },
      { 4221: '0256 土城分行' },
      { 4222: '0267 中和分行' },
      { 4223: '0278 赤崁分行' },
      { 4224: '0289 基隆分行' },
      { 4225: '0290 斗六簡易型分行' },
      { 4226: '0304 東門分行' },
      { 4227: '0315 北門分行' },
      { 4228: '0326 屏東分行' },
      { 4229: '0337 林森分行' },
      { 4230: '0348 歸仁分行' },
      { 4231: '0359 海東分行' },
      { 4232: '0371 永康分行' },
      { 4233: '0382 天母分行' },
      { 4234: '0393 新店分行' },
      { 4235: '0407 大安分行' },
      { 4236: '0429 苗栗分行' },
      { 4237: '0430 內湖分行' },
      { 4238: '0452 花蓮分行' },
      { 4239: '0463 繼光分行' },
      { 4240: '0474 彰化分行' },
      { 4241: '0485 豐原分行' },
      { 4242: '0511 大里分行' },
      { 4243: '0522 嘉義分行' },
      { 4244: '0577 風城分行' },
      { 4245: '0588 南大分行' },
      { 4246: '0599 竹科分行' },
      { 4247: '0625 台東簡易型分行' },
      { 4248: '0669 羅東分行' },
      { 4249: '0692 市政分行' },
      { 4250: '0706 藝文分行' },
      { 5259: '0681 高美館分行' },
      { 6411: '0670 大直分行' },
      { 6412: '0717 瑞光分行' },
      { 6413: '0728 民生分行' }
    ],
    118: [
      { 4252: '0010 台中分行' },
      { 4253: '0021 忠孝分行' },
      { 4254: '0032 敦北分行' },
      { 4255: '0043 高雄分行' },
      { 4256: '0054 桃園分行' },
      { 4257: '0065 豐原分行' },
      { 4258: '0076 林口分行' },
      { 4259: '0098 台南分行' },
      { 4260: '0102 大安分行' },
      { 4261: '0113 華山分行' },
      { 4262: '0124 太平分行' },
      { 4263: '0135 中清分行' },
      { 4264: '0157 三重分行' },
      { 4265: '0168 蘆洲分行' },
      { 4266: '0179 信義分行' },
      { 4267: '0180 中港分行' },
      { 4268: '0205 左營分行' },
      { 4269: '0216 汐止分行' },
      { 4270: '0227 天母分行' },
      { 4271: '0238 八德分行' },
      { 4272: '0249 中山分行' },
      { 4273: '0250 新莊分行' },
      { 4274: '0261 板橋分行' },
      { 4275: '0272 中和分行' },
      { 4276: '0283 楠梓分行' },
      { 4277: '0294 民權分行' },
      { 4278: '0308 苓雅分行' },
      { 4279: '0319 東台南分行' },
      { 4280: '0320 新店分行' },
      { 4281: '0331 前鎮分行' },
      { 4282: '0342 東新竹分行' },
      { 4283: '0353 鼎強分行' },
      { 4284: '0364 南京東路分行' },
      { 4285: '0375 莒光分行' },
      { 4286: '0386 中壢分行' },
      { 4287: '0397 新竹分行' },
      { 4288: '0401 大同分行' },
      { 4289: '0412 敦南分行' },
      { 4290: '0423 內湖分行' },
      { 6414: '0456 松山分行' },
      { 6415: '0467 松江分行' },
      { 6416: '0478 松仁分行' },
      { 6417: '0489 新站分行' },
      { 6418: '0490 中正分行' },
      { 6419: '0504 中興分行' },
      { 6420: '0559 四維分行' }
    ],
    119: [
      { 4292: '0012 營業部' },
      { 4293: '0023 敦南分行' },
      { 4294: '0045 新生分行' },
      { 4295: '0056 新莊分行' },
      { 4296: '0067 桃園分行' },
      { 4297: '0078 台中分行' },
      { 4298: '0089 板橋分行' },
      { 4299: '0090 高雄分行' },
      { 4300: '0104 台南分行' },
      { 4301: '0115 南京東路分行' },
      { 4302: '0126 中壢分行' },
      { 4303: '0137 信託部' },
      { 4304: '0159 苓雅分行' },
      { 4305: '0160 蘆洲分行' },
      { 4306: '0171 天母分行' },
      { 4307: '0182 大里分行' },
      { 4308: '0207 中和分行' },
      { 4309: '0218 嘉義分行' },
      { 4310: '0229 豐原分行' },
      { 4311: '0230 花蓮分行' },
      { 4312: '0241 新竹分行' },
      { 4313: '0252 七賢分行' },
      { 4314: '0263 信義分行' },
      { 4315: '0274 北台中分行' },
      { 4316: '0285 三重分行' },
      { 4317: '0296 永福分行' },
      { 4318: '0300 屏東分行' },
      { 4319: '0311 大安分行' },
      { 4320: '0322 龍潭分行' },
      { 4321: '0333 崇德分行' },
      { 4322: '0344 金華分行' },
      { 4323: '0355 後甲分行' },
      { 4324: '0366 海佃分行' },
      { 4325: '0377 彰化分行' },
      { 4326: '0388 五甲分行' },
      { 4327: '0399 鳳山分行' },
      { 4328: '0403 古亭分行' },
      { 4329: '0414 建橋分行' },
      { 4330: '0436 民生分行' },
      { 4331: '0447 太平分行' },
      { 4332: '0458 和平分行' },
      { 4333: '0469 北高雄分行' },
      { 4334: '0470 南新莊分行' },
      { 4335: '0481 內湖分行' },
      { 4336: '0492 永和分行' },
      { 4337: '0517 逢甲分行' },
      { 4338: '0528 竹科分行' },
      { 4339: '0540 南屯分行' },
      { 4340: '0551 大直分行' },
      { 4341: '0562 南門分行' },
      { 4342: '0610 西門分行' },
      { 4343: '0621 敦北分行' },
      { 4344: '0632 忠孝分行' },
      { 4345: '0643 復興分行' },
      { 4346: '0654 板南分行' },
      { 4347: '0665 民權分行' },
      { 4348: '0676 三民分行' },
      { 4349: '0687 建北分行' },
      { 4350: '0698 三和分行' },
      { 4351: '0702 北新店分行' },
      { 4352: '0713 府城分行' },
      { 4353: '0724 東高雄分行' },
      { 4354: '0735 基隆路分行' },
      { 4355: '0746 新店分行' },
      { 4356: '0757 北桃園分行' },
      { 4357: '0768 大墩分行' },
      { 4358: '0779 延平分行' },
      { 4359: '0780 南崁分行' },
      { 4360: '0791 景平分行' },
      { 4361: '0805 文心分行' },
      { 4362: '0816 岡山分行' },
      { 4363: '0827 松江分行' },
      { 4364: '0838 大雅分行' },
      { 4365: '0849 汐止分行' },
      { 4366: '0850 南屏分行' },
      { 4367: '0861 石牌分行' },
      { 4368: '0872 永康分行' },
      { 4369: '0883 南松山分行' },
      { 4370: '0894 淡水分行' },
      { 4371: '0908 東基隆分行' },
      { 4372: '0919 新板分行' },
      { 4373: '0920 北師分行' },
      { 4374: '0931 佳里分行' },
      { 4375: '0942 江翠分行' },
      { 4376: '0975 松德分行' },
      { 4377: '0986 北大分行' },
      { 4378: '0997 南港分行' },
      { 4379: '1008 關東橋分行' },
      { 4380: '1019 竹北分行' },
      { 4381: '1020 南寮分行' },
      { 4382: '1031 八德分行' },
      { 4383: '1042 員林分行' },
      { 4384: '1053 市府分行' },
      { 4385: '1064 右昌分行' },
      { 4386: '1075 沙鹿分行' },
      { 4387: '1086 竹南分行' },
      { 4388: '1097 羅東分行' },
      { 4389: '1101 東湖分行' },
      { 4390: '1112 文山分行' },
      { 4391: '1123 副都心分行' },
      { 6421: '1134 景美分行' },
      { 6422: '1145 雄科分行' }
    ],
    120: [
      { 4393: '0014 苓雅分行' },
      { 4394: '0025 敦化分行' },
      { 4395: '0036 屏東分行' },
      { 4396: '0047 高雄分行' },
      { 4397: '0058 鳳山分行' },
      { 4398: '0069 台南分行' },
      { 4399: '0070 嘉義分行' },
      { 4400: '0081 台北分行' },
      { 4401: '0092 新莊分行' },
      { 4402: '0106 中壢分行' },
      { 4403: '0117 台中分行' },
      { 4404: '0128 大昌分行' },
      { 4405: '0151 新生分行' },
      { 4406: '0162 板橋分行' },
      { 4407: '0173 沙鹿分行' },
      { 4408: '0184 博愛分行' },
      { 4409: '0209 長春分行' },
      { 4410: '0210 三重分行' },
      { 4411: '0221 桃園分行' },
      { 4412: '0232 永康分行' },
      { 4413: '0243 右昌分行' },
      { 4414: '0254 前金分行' },
      { 4415: '0265 五甲分行' },
      { 4416: '0276 文山簡易型分行' },
      { 4417: '0287 中和分行' },
      { 4418: '0298 新營分行' },
      { 4419: '0302 大和平分行' },
      { 4420: '0313 西台南分行' },
      { 4421: '0324 內湖分行' },
      { 4422: '0335 灣裡分行' },
      { 4423: '0346 安南分行' },
      { 4424: '0357 江翠分行' },
      { 4425: '0368 長庚分行' },
      { 4426: '0379 東台南分行' },
      { 4427: '0380 營業部' },
      { 4428: '0391 新竹分行' },
      { 4429: '0405 汐止簡易型分行' },
      { 4430: '0416 天母分行' },
      { 4431: '0427 仁愛分行' },
      { 4432: '0438 澄清分行' },
      { 4433: '0450 永和分行' },
      { 4434: '0461 小港分行' },
      { 4435: '0472 竹北簡易型分行' },
      { 4436: '0483 北桃園分行' },
      { 4437: '0494 永春分行' },
      { 4438: '0508 圓山分行' },
      { 4439: '0519 豐原簡易型分行' },
      { 4440: '0520 彰化分行' },
      { 4441: '0531 北高雄分行' },
      { 4442: '0542 大里簡易型分行' },
      { 4443: '0553 北台中分行' },
      { 4444: '0564 中屏簡易型分行' },
      { 4445: '0575 旗津分行' },
      { 4446: '0586 左營分行' },
      { 4447: '0597 鹽埕簡易型分行' },
      { 4448: '0601 前鎮分行' },
      { 4449: '0612 明誠分行' },
      { 4450: '0726 中正分行' },
      { 4451: '0737 東湖分行' },
      { 4452: '0759 安和分行' },
      { 4453: '0760 敦南分行' },
      { 4454: '0771 南門分行' },
      { 4455: '0782 新板分行' },
      { 4456: '0793 新店分行' },
      { 4457: '0807 南屯分行' },
      { 4458: '0818 中山分行' },
      { 4459: '0829 東門分行' }
    ],
    121: [
      { 4461: '0015 營業部' },
      { 4462: '0026 松江分行' },
      { 4463: '0037 延平分行' },
      { 4464: '0048 內湖分行' },
      { 4465: '0059 桃園分行' },
      { 4466: '0060 台中分行' },
      { 4467: '0071 台南分行' },
      { 4468: '0093 高雄分行' },
      { 4469: '0107 新莊分行' },
      { 4470: '0118 中壢分行' },
      { 4471: '0129 松山分行' },
      { 4472: '0130 松南分行' },
      { 4473: '0141 八德分行' },
      { 4474: '0163 板橋分行' },
      { 4475: '0174 信義分行' },
      { 4476: '0185 苓雅分行' },
      { 4477: '0196 前金分行' },
      { 4478: '0211 嘉義分行' },
      { 4479: '0222 三重分行' },
      { 4480: '0233 北台中分行' },
      { 4481: '0244 雙和分行' },
      { 4482: '0255 士林分行' },
      { 4483: '0266 樹林分行' },
      { 4484: '0277 北桃園分行' },
      { 4485: '0288 新竹分行' },
      { 4486: '0299 敦化分行' },
      { 4487: '0303 北高雄分行' },
      { 4488: '0314 新營分行' },
      { 4489: '0325 頭份分行' },
      { 4490: '0336 大里分行' },
      { 4491: '0347 光復分行' },
      { 4492: '0358 員林分行' },
      { 4493: '0369 虎尾分行' },
      { 4494: '0370 屏東分行' },
      { 4495: '0381 南門分行' },
      { 4496: '0392 大安分行' },
      { 4497: '0406 敦北分行' },
      { 4498: '0417 南京分行' },
      { 4499: '0428 竹北分行' },
      { 4500: '0439 安平分行' },
      { 4501: '0451 新店分行' },
      { 4502: '0462 土城分行' },
      { 4503: '0473 南港分行' },
      { 4504: '0484 蘆洲分行' }
    ],
    122: [
      { 4506: '0016 長安東路分行' },
      { 4507: '0027 信義分行' },
      { 4508: '0038 營業部' },
      { 4509: '0049 新生分行' },
      { 4510: '0050 桃園分行' },
      { 4511: '0061 新莊分行' },
      { 4512: '0072 台中分行' },
      { 4513: '0083 高雄分行' },
      { 4514: '0094 台南分行' },
      { 4515: '0108 興隆分行' },
      { 4516: '0119 延平分行' },
      { 4517: '0120 中壢分行' },
      { 4518: '0131 板橋分行' },
      { 4519: '0142 豐原分行' },
      { 4520: '0153 前金分行' },
      { 4521: '0164 中和分行' },
      { 4522: '0186 鳳山分行' },
      { 4523: '0197 松江簡易型分行' },
      { 4524: '0201 中崙分行' },
      { 4525: '0212 嘉義分行' },
      { 4526: '0223 三重簡易型分行' },
      { 4527: '0245 沙鹿分行' },
      { 4528: '0256 新竹分行' },
      { 4529: '0267 員林分行' },
      { 4530: '0278 建國分行' },
      { 4531: '0289 北高雄分行' },
      { 4532: '0290 新店分行' },
      { 4533: '0304 和平分行' },
      { 4534: '0315 成功簡易型分行' },
      { 4535: '0326 通化分行' },
      { 4536: '0337 南門分行' },
      { 4537: '0348 汀州分行' },
      { 4538: '0359 農安簡易型分行' },
      { 4539: '0360 景美分行' },
      { 4540: '0371 民權分行' },
      { 4541: '0382 松山分行' },
      { 4542: '0393 內湖簡易型分行' },
      { 4543: '0407 忠孝分行' },
      { 4544: '0418 蘆洲分行' },
      { 4545: '0429 永康分行' },
      { 4546: '0430 西壢分行' },
      { 4547: '0441 北桃園簡易型分行' },
      { 4548: '0452 幸福分行' },
      { 4549: '0463 天母分行' },
      { 4550: '0474 龍潭簡易型分行' },
      { 4551: '0485 復興分行' },
      { 4552: '0496 永吉簡易型分行' },
      { 4553: '0500 石牌簡易型分行' },
      { 4554: '0511 八德簡易型分行' },
      { 4555: '0522 竹北簡易型分行' },
      { 4556: '0533 永和分行' },
      { 4557: '0544 大直分行' },
      { 4558: '0555 土城簡易型分行' },
      { 4559: '0566 大里分行' }
    ],
    123: [
      { 4561: '0015 敦北分行' },
      { 4562: '0026 台中分行' },
      { 4563: '0037 高雄分行' },
      { 4564: '0059 台南分行' },
      { 4565: '0060 三重分行' },
      { 4566: '0071 城東分行' },
      { 4567: '0082 嘉義分行' },
      { 4568: '0093 中崙分行' },
      { 4569: '0107 城中分行' },
      { 4570: '0118 三民分行' },
      { 4571: '0129 中壢分行' },
      { 4572: '0130 雙和分行' },
      { 4573: '0141 中山分行' },
      { 4574: '0152 仁愛分行' },
      { 4575: '0163 敦南分行' },
      { 4576: '0174 板橋分行' },
      { 4577: '0185 忠孝分行' },
      { 4578: '0196 新興分行' },
      { 4579: '0200 南京東路分行' },
      { 4580: '0222 西台南分行' },
      { 4581: '0255 松山分行' },
      { 4582: '0266 新莊分行' },
      { 4583: '0277 桃園分行' },
      { 4584: '0288 中港分行' },
      { 4585: '0299 新竹分行' },
      { 4586: '0303 蘆洲分行' },
      { 4587: '0314 新店分行' },
      { 4588: '0325 員林分行' },
      { 4589: '0336 東新竹分行' },
      { 4590: '0347 公館分行' },
      { 4591: '0358 永康分行' },
      { 4592: '0369 土城分行' },
      { 4593: '0370 頭份分行' },
      { 4594: '0381 基隆分行' },
      { 4595: '0392 豐原分行' },
      { 4596: '0406 天母分行' },
      { 4597: '0417 板新分行' },
      { 4598: '0428 彰化分行' },
      { 4599: '0439 東高雄分行' },
      { 4600: '0440 中華分行' },
      { 4601: '0451 內湖分行' },
      { 4602: '0462 斗六分行' },
      { 4603: '0473 文心分行' },
      { 4604: '0484 重陽分行' },
      { 4605: '0495 永吉分行' },
      { 4606: '0509 前鎮分行' },
      { 4607: '0510 南崁分行' },
      { 4608: '0521 永和分行' },
      { 4609: '0532 公益分行' },
      { 4610: '0543 市府分行' },
      { 4611: '0554 六家庄分行' },
      { 4612: '0565 右昌簡易型分行' },
      { 4613: '0576 江翠分行' },
      { 4614: '0587 東湖簡易型分行' },
      { 4615: '0598 中原分行' },
      { 4616: '0602 大安分行' },
      { 4617: '0613 北高雄分行' },
      { 4618: '0624 承德分行' },
      { 4619: '0635 復興分行' },
      { 4620: '0646 石牌分行' },
      { 4621: '0657 城北分行' },
      { 4622: '0668 萬華分行' },
      { 4623: '0679 西松分行' },
      { 4624: '0680 北投分行' },
      { 4625: '0691 安和分行' },
      { 4626: '0705 西湖分行' },
      { 4627: '0716 瑞光分行' },
      { 4628: '0727 宜蘭分行' },
      { 4629: '0738 民生分行' },
      { 4630: '0749 中和分行' },
      { 4631: '0750 重新分行' },
      { 4632: '0761 丹鳳分行' },
      { 4633: '0772 北新店分行' },
      { 4634: '0783 汐止分行' },
      { 4635: '0794 金城分行' },
      { 4636: '0808 樹林分行' },
      { 4637: '0819 南勢角分行' },
      { 4638: '0820 北新莊分行' },
      { 4639: '0853 南桃園分行' },
      { 4640: '0864 北桃園分行' },
      { 4641: '0875 竹科分行' },
      { 4642: '0901 營業部' },
      { 4643: '1012 八德分行' },
      { 4644: '1023 林口分行' },
      { 4645: '1034 竹北分行' },
      { 4646: '1045 南屯分行' },
      { 4647: '1056 大里分行' },
      { 4648: '1067 北台中簡易型分行' },
      { 4649: '1078 南投簡易型分行' },
      { 4650: '1090 仁德簡易型分行' },
      { 4651: '1104 中台南分行' },
      { 4652: '1115 東台南分行' },
      { 4653: '1126 台東簡易型分行' },
      { 4654: '1137 南台南分行' },
      { 4655: '1148 新營分行' },
      { 4656: '1159 科博館分行' },
      { 4657: '1160 西屯分行' },
      { 4658: '1171 民族分行' },
      { 4659: '1182 南高雄分行' },
      { 4660: '1193 九如分行' },
      { 4661: '1207 屏東分行' },
      { 4662: '1218 復北分行' },
      { 4663: '1229 鳳山分行' },
      { 4664: '1230 五甲分行' },
      { 4665: '1241 延吉分行' },
      { 4666: '1252 信義分行' },
      { 4667: '1263 青年分行' },
      { 4668: '1274 岡山簡易型分行' },
      { 4669: '1285 士林分行' },
      { 4670: '1296 東民生分行' },
      { 4671: '1300 文山分行' },
      { 4672: '2101 淡水簡易型分行' },
      { 4673: '2112 民安簡易型分行' },
      { 4674: '2123 二重埔簡易型分行' },
      { 4675: '2134 富錦分行' },
      { 4676: '2145 站前分行' },
      { 4677: '2156 惠中簡易型分行' },
      { 4678: '2167 東蘆洲分行' },
      { 4679: '2178 木柵分行' },
      { 4680: '2189 東花蓮分行' },
      { 4681: '2190 寶山分行' },
      { 4682: '2204 藝文分行' },
      { 4683: '2215 東門分行' },
      { 4684: '2226 新板特區分行' },
      { 4685: '2237 三和分行' },
      { 4686: '2248 劍潭分行' },
      { 4687: '2259 北蘆洲分行' },
      { 4688: '2260 寶強分行' },
      { 4689: '2271 華山分行' },
      { 4690: '2282 龍江簡易型分行' },
      { 4691: '2293 成功分行' },
      { 4692: '3108 市政分行' },
      { 4693: '3119 大直分行' },
      { 4694: '3120 景美分行' },
      { 4695: '3131 內壢簡易型分行' },
      { 4696: '3142 重慶分行' },
      { 4697: '3153 長安分行' },
      { 4698: '3164 羅東分行' },
      { 4699: '3175 南中壢分行' },
      { 4700: '3186 板和簡易型分行' },
      { 4701: '3197 花蓮分行' },
      { 4702: '3201 北天母分行' },
      { 4703: '3212 民權西路分行' },
      { 4704: '3223 佳里分行' },
      { 4705: '3234 博愛分行' },
      { 4706: '3245 鹽行分行' },
      { 4707: '3267 光復分行' },
      { 4708: '3278 埔墘分行' },
      { 5217: '3289 黎明分行' },
      { 6423: '3290 新富分行' },
      { 6424: '3603 三峽分行' },
      { 6425: '3614 東林口分行' }
    ],
    125: [
      { 4721: '0109 信用部' },
      { 4722: '0110 社后分部' },
      { 4723: '0121 橫科分部' },
      { 4724: '0132 白雲分部' },
      { 4725: '0143 大新分部' },
      { 4726: '0154 中興分部' },
      { 4727: '0165 中正分部' },
      { 4728: '0176 樟樹分部' },
      { 4729: '0187 大同分部' },
      { 6980: '0017 汐止區農會' },
      { 6981: '0028 社后分部' },
      { 6982: '0039 橫科分部' },
      { 6983: '0040 白雲分部' },
      { 6984: '0051 大新分部' },
      { 6985: '0062 中興分部' },
      { 6986: '0073 中正分部' },
      { 6987: '0084 樟樹分部' },
      { 6988: '0095 大同分部' }
    ],
    126: [
      { 4731: '0018 信用部' },
      { 4732: '0029 丹鳳分部' },
      { 4733: '0030 頭前分部' },
      { 4734: '0063 西盛分部' },
      { 4735: '0074 中港分部' },
      { 4736: '0085 後港分部' },
      { 4737: '0096 營盤分部' },
      { 4738: '0100 瓊林分部' },
      { 4739: '0111 思源分部' },
      { 4740: '0122 新泰分部' }
    ],
    130: [
      { 4758: '0012 臺南地區農會信用部' },
      { 4759: '0023 成功分部' },
      { 4760: '0034 聯合分部' },
      { 4761: '0056 安順分部' },
      { 4762: '0067 和順分部' },
      { 4763: '0078 土城分部' },
      { 4764: '0089 灣裡分部' }
    ],
    137: [{ 5142: '0016 關貿網路股份有限公司' }],
    138: [{ 5144: '0017 財政部國庫署' }, { 5145: '0028 中部辦公室' }],
    141: [{ 5147: '0019 南龍區漁會' }, { 6461: '0020 竹南分部' }],
    103: [
      { 5218: '0015 鳳山區農會' },
      { 5221: '0060 燕巢區農會' },
      { 5222: '0026 岡山區農會' },
      { 5223: '0266 五甲分部' },
      { 5224: '0738 老爺分部' },
      { 5225: '0864 文山分部' },
      { 5226: '0886 東區分部' },
      { 5227: '0897 鎮北分部' },
      { 5228: '0990 過埤分部' },
      { 5229: '0277 市場分部' },
      { 5230: '0288 嘉興分部' },
      { 5231: '0299 仁壽分部' },
      { 5232: '0772 協榮分部' },
      { 5233: '0059 橋頭區農會' },
      { 5234: '0071 田寮區農會' },
      { 5235: '0082 阿蓮區農會' },
      { 5236: '0093 路竹區農會' },
      { 5237: '0107 湖內區農會' },
      { 5238: '0118 茄萣區農會' },
      { 5239: '0129 彌陀區農會' },
      { 5240: '0130 永安區農會' },
      { 5241: '0141 梓官區農會' },
      { 5242: '0185 林園區農會' },
      { 5243: '0196 大寮區農會' },
      { 5244: '0211 仁武區農會' },
      { 5245: '0222 大社區農會' },
      { 5246: '0244 杉林區農會' },
      { 5247: '0255 甲仙地區農會' },
      { 5248: '0303 六龜區農會' },
      { 5249: '1089 鳥松區農會' },
      { 5250: '1104 大樹區農會' },
      { 5251: '1115 內門區農會' },
      { 5872: '0749 市場分部' },
      { 5873: '1067 後紅分部' },
      { 5874: '0037 旗山區農會' },
      { 5875: '0314 旗尾分部' },
      { 5876: '0325 溪洲分部' },
      { 5877: '0336 南溪洲分部' },
      { 5878: '0347 嶺口分部' },
      { 5879: '0358 中正分部' },
      { 5880: '0048 美濃區農會' },
      { 5881: '0369 中壇分部' },
      { 5882: '0370 龍肚分部' },
      { 5883: '0381 廣興分部' },
      { 5884: '0392 南隆分部' },
      { 5885: '0716 橋頭分部' },
      { 5886: '0901 甲圍辦事處' },
      { 5887: '0923 隆豐和分部' },
      { 5888: '0934 五林分部' },
      { 5889: '0406 鳳雄分部' },
      { 5890: '0417 深水分部' },
      { 5891: '0680 安招分部' },
      { 5892: '0794 新興分部' },
      { 5893: '0808 崇德分部' },
      { 5894: '0624 中路分部' },
      { 5895: '1078 復安分部' },
      { 5896: '0428 竹滬分部' },
      { 5897: '0439 一甲分部' },
      { 5898: '0440 下坑分部' },
      { 5899: '0451 三爺分部' },
      { 5900: '0462 大湖分部' },
      { 5901: '0657 海埔分部' },
      { 5902: '0853 太爺分部' },
      { 5903: '0750 白砂崙分部' },
      { 5904: '0646 南安分部' },
      { 5905: '0783 壽齡分部' },
      { 5906: '0679 保寧分部' },
      { 5907: '0761 維新分部' },
      { 5908: '0473 赤崁分部' },
      { 5909: '0484 大舍分部' },
      { 5910: '0495 梓官分部' },
      { 5911: '0831 中厝分部' },
      { 5912: '0565 大寮分部' },
      { 5913: '0576 中庄分部' },
      { 5914: '0587 後庄分部' },
      { 5915: '0598 昭明分部' },
      { 5916: '0602 潮寮分部' },
      { 5917: '1012 翁園分部' },
      { 5918: '0727 灣內分部' },
      { 5919: '1045 八卦分部' },
      { 5920: '0819 桃源分部' },
      { 5921: '0989 那瑪夏分部' },
      { 5922: '0967 仁美分部' },
      { 5923: '1090 澄清湖分部' },
      { 5924: '0978 溝坪分部' },
      { 5925: '1001 觀亭分部' },
      { 7122: '1023 大灣分部' }
    ],
    97: [{ 5220: '1118 神岡區農會' }, { 5651: '1107 台中市豐原區農會' }],
    78: [{ 5263: '0012 桃園區漁會' }],
    79: [{ 5264: '0013 新竹區漁會' }],
    75: [{ 5265: '0019 基隆區漁會' }],
    76: [
      { 5266: '0010 新北市瑞芳區漁會' },
      { 5267: '0021 深澳分部' },
      { 5268: '0032 新北市萬里區漁會' },
      { 5269: '0054 萬里分部' }
    ],
    77: [
      { 5270: '0011 宜蘭縣頭城區漁會' },
      { 5271: '0033 大溪分部' },
      { 5272: '0066 壯圍分部' },
      { 5273: '0022 宜蘭縣蘇澳區漁會' }
    ],
    80: [
      { 5274: '0011 雲林區漁會' },
      { 5275: '0022 台西分部' },
      { 5276: '0033 口湖分部' },
      { 5277: '0044 麥寮分部' },
      { 5278: '0055 三條崙分部' }
    ],
    81: [
      { 5279: '0014 嘉義區漁會' },
      { 5280: '0025 新塭分部' },
      { 5281: '0036 義竹分部' },
      { 5282: '0047 過溝分部' },
      { 5283: '0058 東石分部' },
      { 5284: '0069 過路分部' },
      { 5285: '0070 副瀨分部' },
      { 5286: '0081 景山分部' }
    ],
    82: [
      { 5287: '0016 南市區漁會' },
      { 5288: '0027 四草分部' },
      { 5289: '0038 安南分部' }
    ],
    83: [
      { 5290: '0017 南縣區漁會' },
      { 5291: '0028 七股分部' },
      { 5292: '0039 北門分部' },
      { 5293: '0040 將軍港分部' },
      { 5294: '0051 青山港分部' },
      { 5295: '0073 永吉分部' }
    ],
    84: [
      { 5296: '0012 高雄區漁會信用部' },
      { 5297: '0034 旗津分部' },
      { 5298: '0056 援中港分部' },
      { 5299: '0023 小港區漁會信用部' }
    ],
    85: [
      { 5300: '0024 興達港區漁會' },
      { 5301: '0035 下茄萣分部' },
      { 5302: '0068 林園區漁會' },
      { 5303: '0079 彌陀區漁會' },
      { 5304: '0105 舊港分部' },
      { 5305: '0149 南寮分部' },
      { 5306: '0091 永安區漁會' },
      { 5307: '0116 梓官區漁會' }
    ],
    86: [
      { 5308: '0015 琉球區漁會' },
      { 5309: '0037 東港區漁會' },
      { 5310: '0118 光復分部' },
      { 5311: '0129 新園分部' },
      { 5312: '0048 林邊區漁會' },
      { 5313: '0059 塭豐分部' },
      { 5314: '0082 崎峰分部' },
      { 5315: '0093 水利分部' },
      { 5316: '0130 枋寮區漁會' }
    ],
    87: [{ 5317: '0016 新港區漁會' }],
    88: [
      { 5318: '0017 澎湖區漁會' },
      { 5319: '0028 西嶼分部' },
      { 5320: '0039 白沙分部' }
    ],
    145: [{ 5321: '0018 金門區漁會' }],
    136: [
      { 5322: '0703 南龍區漁會' },
      { 5323: '0714 竹南分部' },
      { 5324: '8905 彰化區漁會' },
      { 5325: '8916 王功分部' },
      { 5326: '8927 芳苑分部' },
      { 5327: '8938 大城分部' },
      { 5328: '8949 草港分部' },
      { 5329: '8950 福興分部' },
      { 5330: '8961 伸港分部' },
      { 5331: '8972 埔心分部' },
      { 5332: '9500 通苑區漁會' },
      { 5333: '9511 苑裡分部' },
      { 6008: '0024 彰化市農會' },
      { 6009: '0105 礁溪鄉農會' },
      { 6010: '0116 四結分部' },
      { 6011: '0127 龍潭分部' },
      { 6012: '0138 德陽分部' },
      { 6013: '0208 二水鄉農會' },
      { 6014: '0301 公館鄉農會' },
      { 6015: '0312 福基分部' },
      { 6016: '0334 五谷分部' },
      { 6017: '0345 鶴岡分部' },
      { 6018: '0404 阿里山鄉農會' },
      { 6019: '0415 中山分部' },
      { 6020: '0426 達邦分部' },
      { 6021: '0507 獅潭鄉農會' },
      { 6022: '0518 汶水分部' },
      { 6023: '0600 大安區農會' },
      { 6024: '0611 海墘辦事處' },
      { 6025: '0622 松雅分部' },
      { 6026: '0806 大肚區農會' },
      { 6027: '0817 王田分部' },
      { 6028: '0828 福山辦事處' },
      { 6029: '0839 蔗廓辦事處' },
      { 6030: '0840 成功分部' },
      { 6031: '0909 龍井區農會' },
      { 6032: '0910 龍泉辦事處' },
      { 6033: '0921 龍津辦事處' },
      { 6034: '0932 新庄辦事處' },
      { 6035: '0943 國際辦事處' },
      { 6036: '1009 卓蘭鎮農會' },
      { 6037: '1102 竹南鎮農會' },
      { 6038: '1113 中港分部' },
      { 6039: '1124 大埔分部' },
      { 6040: '1135 龍鳳分部' },
      { 6041: '1205 頭份市農會' },
      { 6042: '1216 珊湖分部' },
      { 6043: '1227 上街分部' },
      { 6044: '1238 尖山分部' },
      { 6045: '1249 中山分部' },
      { 6046: '1308 三灣鄉農會' },
      { 6047: '1401 造橋鄉農會' },
      { 6048: '1412 大西分部' },
      { 6049: '1423 朝陽分部' },
      { 6050: '1434 龍昇分部' },
      { 6051: '1456 豐湖分部' },
      { 6052: '1504 頭屋鄉農會' },
      { 6053: '1515 明德分部' },
      { 6054: '1607 苗栗市農會' },
      { 6055: '1618 北苗辦事處' },
      { 6056: '1629 南苗辦事處' },
      { 6057: '1630 嘉盛辦事處' },
      { 6058: '1700 西湖鄉農會' },
      { 6059: '1711 五湖分部' },
      { 6060: '1803 通霄鎮農會' },
      { 6061: '1814 白沙屯分部' },
      { 6062: '1906 銅鑼鄉農會' },
      { 6063: '1917 中平分部' },
      { 6064: '1928 新隆分部' },
      { 6065: '2006 南投市農會' },
      { 6066: '2017 內興分部' },
      { 6067: '2028 永豐分部' },
      { 6068: '2039 福山分部' },
      { 6069: '2040 營南分部' },
      { 6070: '2051 營北分部' },
      { 6071: '2062 鳳山分部' },
      { 6072: '2073 漳和分部' },
      { 6073: '2109 水里鄉農會' },
      { 6074: '2110 上安分部' },
      { 6075: '2121 玉峰分部' },
      { 6076: '2132 民和分部' },
      { 6077: '2202 信義鄉農會' },
      { 6078: '2213 和社分部' },
      { 6079: '2224 地利分部' },
      { 6080: '2305 集集鎮農會' },
      { 6081: '2316 田寮分部' },
      { 6082: '2408 埔里鎮農會' },
      { 6083: '2419 合成分部' },
      { 6084: '2420 愛蘭分部' },
      { 6085: '2431 東興分部' },
      { 6086: '2501 中寮鄉農會' },
      { 6087: '2512 爽文分部' },
      { 6088: '2545 義和分部' },
      { 6089: '2556 內城分部' },
      { 6090: '2604 魚池鄉農會' },
      { 6091: '2615 頭社分部' },
      { 6092: '2626 日月潭分部' },
      { 6093: '2637 東光分部' },
      { 6094: '2648 共和分部' },
      { 6095: '2659 五城分部' },
      { 6096: '2707 國姓鄉農會' },
      { 6097: '2718 國姓分部' },
      { 6098: '2729 長流分部' },
      { 6099: '2730 北山分部' },
      { 6100: '2741 北港分部' },
      { 6101: '2752 福龜分部' },
      { 6102: '2763 南港分部' },
      { 6103: '2800 鹿谷鄉農會' },
      { 6104: '2811 廣興分部' },
      { 6105: '2822 初鄉分部' },
      { 6106: '2833 竹林分部' },
      { 6107: '2844 坪頂分部' },
      { 6108: '2855 永隆分部' },
      { 6109: '2866 瑞田分部' },
      { 6110: '2877 鳳凰分部' },
      { 6111: '2903 仁愛鄉農會' },
      { 6112: '3003 苑裡鎮農會' },
      { 6113: '3014 山腳分部' },
      { 6114: '3025 社苓分部' },
      { 6115: '3106 三義鄉農會' },
      { 6116: '3117 鯉魚潭分部' },
      { 6117: '3209 大湖地區農會' },
      { 6118: '3210 新開分部' },
      { 6119: '3221 南湖分部' },
      { 6120: '3232 清安分部' },
      { 6121: '3243 大湖分部' },
      { 6122: '3302 南庄鄉農會' },
      { 6123: '3313 田美分部' },
      { 6124: '3324 南富分部' },
      { 6125: '3405 線西鄉農會' },
      { 6126: '3508 太平區農會' },
      { 6127: '3519 新光分部' },
      { 6128: '3520 頭汴分部' },
      { 6129: '3531 光隆分部' },
      { 6130: '3542 中山分部' },
      { 6131: '3553 東平分部' },
      { 6132: '3564 坪林分部' },
      { 6133: '3575 新興分部' },
      { 6134: '3601 花蓮市農會' },
      { 6135: '3612 花商分部' },
      { 6136: '3634 自強分部' },
      { 6137: '3645 美崙分部' },
      { 6138: '3704 林內鄉農會' },
      { 6139: '3807 田尾鄉農會' },
      { 6140: '3818 溪畔分部' },
      { 6141: '3829 福田分部' },
      { 6142: '3830 海豐分部' },
      { 6143: '3900 北斗鎮農會' },
      { 6144: '4000 大甲區農會' },
      { 6145: '4011 順天分部' },
      { 6146: '4022 日南分部' },
      { 6147: '4033 西岐分部' },
      { 6148: '4044 幼獅社區分部' },
      { 6149: '4055 庄美分部' },
      { 6150: '4103 台中市石岡區農會' },
      { 6151: '4114 和盛分部' },
      { 6152: '4206 新社區農會' },
      { 6153: '4217 福興分部' },
      { 6154: '4228 中興嶺分部' },
      { 6155: '4309 梧棲區農會' },
      { 6156: '4310 興農分部' },
      { 6157: '4321 安寧分部' },
      { 6158: '4332 大莊分部' },
      { 6159: '4343 南簡分部' },
      { 6160: '4402 后里區農會' },
      { 6161: '4413 義里分部' },
      { 6162: '4424 公館分部' },
      { 6163: '4435 眉山分部' },
      { 6164: '4505 清水區農會' },
      { 6165: '4516 臨江辦事處' },
      { 6166: '4527 南社分部' },
      { 6167: '4608 霧峰區農會' },
      { 6168: '4619 北柳分部' },
      { 6169: '4620 萬豐分部' },
      { 6170: '4631 吉峰分部' },
      { 6171: '4642 舊正分部' },
      { 6172: '4653 吉峰路分部' },
      { 6173: '4701 外埔區農會' },
      { 6174: '4712 土城辦事處' },
      { 6175: '4723 大東分部' },
      { 6176: '4804 烏日區農會' },
      { 6177: '4815 溪埧分部' },
      { 6178: '4826 九德分部' },
      { 6179: '4837 成功分部' },
      { 6180: '4907 潭子區農會' },
      { 6181: '4918 頭家分部' },
      { 6182: '4929 東寶分部' },
      { 6183: '4930 栗林辦事處' },
      { 6184: '4941 甘蔗辦事處' },
      { 6185: '5007 伸港鄉農會' },
      { 6186: '5100 和美鎮農會' },
      { 6187: '5122 塗厝分部' },
      { 6188: '5133 新庄分部' },
      { 6189: '5144 鎮平分部' },
      { 6190: '5203 花壇鄉農會' },
      { 6191: '5214 白沙分部' },
      { 6192: '5225 灣雅分部' },
      { 6193: '5236 口庄分部' },
      { 6194: '5258 長沙分部' },
      { 6195: '5306 福興鄉農會' },
      { 6196: '5317 管嶼分部' },
      { 6197: '5328 外中分部' },
      { 6198: '5409 大村鄉農會' },
      { 6199: '5410 大村分部' },
      { 6200: '5421 美港分部' },
      { 6201: '5432 大西辦事處' },
      { 6202: '5443 村東辦事處' },
      { 6203: '5502 溪湖鎮農會' },
      { 6204: '5605 社頭鄉農會' },
      { 6205: '5616 湳雅分部' },
      { 6206: '5627 橋頭分部' },
      { 6207: '5638 清水分部' },
      { 6208: '5708 大城鄉農會' },
      { 6209: '5719 西港分部' },
      { 6210: '5801 溪州鄉農會' },
      { 6211: '5812 西畔分部' },
      { 6212: '5823 大庄分部' },
      { 6213: '5904 田中鎮農會' },
      { 6214: '6004 南投縣竹山鎮農會' },
      { 6215: '6015 社寮分部' },
      { 6216: '6026 瑞竹分部' },
      { 6217: '6037 延平分部' },
      { 6218: '6048 中央分部' },
      { 6219: '6107 麥寮鄉農會' },
      { 6220: '6118 橋頭分部' },
      { 6221: '6200 雲林縣東勢鄉農會' },
      { 6222: '6211 新坤辦事處' },
      { 6223: '6303 土庫鎮農會' },
      { 6224: '6314 馬光分部' },
      { 6225: '6325 新庄辦事處' },
      { 6226: '6336 忠正辦事處' },
      { 6227: '6358 後埔分部' },
      { 6228: '6406 元長鄉農會' },
      { 6229: '6417 鹿寮分部' },
      { 6230: '6428 客庴分部' },
      { 6231: '6509 北港鎮農會' },
      { 6232: '6510 好收分部' },
      { 6233: '6602 水林鄉農會' },
      { 6234: '6613 蔦松辦事處' },
      { 6235: '6624 西井辦事處' },
      { 6236: '6635 溪墘辦事處' },
      { 6237: '6646 宏仁分部' },
      { 6238: '6705 中埔鄉農會' },
      { 6239: '6716 三層分部' },
      { 6240: '6727 同仁分部' },
      { 6241: '6738 金蘭分部' },
      { 6242: '6749 隆興分部' },
      { 6243: '6750 和睦分部' },
      { 6244: '6761 沄水分部' },
      { 6245: '6772 和興分部' },
      { 6246: '6783 和美分部' },
      { 6247: '6808 鹿港鎮農會' },
      { 6248: '6819 草港分部' },
      { 6249: '6820 頂番分部' },
      { 6250: '6831 海埔分部' },
      { 6251: '6842 東崎分部' },
      { 6252: '6901 瑞穗鄉本會' },
      { 6253: '6912 富源分部' },
      { 6254: '7001 官田區農會' },
      { 6255: '7012 西庄辦事處' },
      { 6256: '7023 官田辦事處' },
      { 6257: '7034 二鎮辦事處' },
      { 6258: '7045 渡頭辦事處' },
      { 6259: '7056 湖山辦事處' },
      { 6260: '7104 大內區農會' },
      { 6261: '7115 二溪辦事處' },
      { 6262: '7126 頭社分部' },
      { 6263: '7137 環湖分部' },
      { 6264: '7207 新市區農會' },
      { 6265: '7218 大社分部' },
      { 6266: '7300 左鎮區農會' },
      { 6267: '7403 山上區農會' },
      { 6268: '7506 柳營區農會' },
      { 6269: '7517 太康辦事處' },
      { 6270: '7528 重溪辦事處' },
      { 6271: '7539 旭山辦事處' },
      { 6272: '7540 果毅辦事處' },
      { 6273: '7609 七股區農會' },
      { 6274: '7702 玉溪地區農會' },
      { 6275: '7724 松浦分部' },
      { 6276: '7805 學甲區農會' },
      { 6277: '7816 中洲分部' },
      { 6278: '7827 宅港分部' },
      { 6279: '7838 頂洲分部' },
      { 6280: '7849 草圣分部' },
      { 6281: '7850 大灣分部' },
      { 6282: '7908 下營區農會' },
      { 6283: '7919 中興分部' },
      { 6284: '7920 中營分部' },
      { 6285: '7931 甲中分部' },
      { 6286: '8053 埔鹽鄉農會' },
      { 6287: '8064 新水分部' },
      { 6288: '8101 麻豆區農會' },
      { 6289: '8112 光復分部' },
      { 6290: '8123 新生分部' },
      { 6291: '8134 復光分部' },
      { 6292: '8145 安業分部' },
      { 6293: '8204 仁德區農會' },
      { 6294: '8215 太子分部' },
      { 6295: '8226 後壁分部' },
      { 6296: '8237 保安分部' },
      { 6297: '8248 中洲分部' },
      { 6298: '8259 二行分部' },
      { 6299: '8307 關廟區農會' },
      { 6300: '8318 龜洞辦事處' },
      { 6301: '8329 埤頭辦事處' },
      { 6302: '8400 龍崎區農會' },
      { 6303: '8411 龍船分部' },
      { 6304: '8503 安定區農會' },
      { 6305: '8514 蘇厝分部' },
      { 6306: '8525 港口辦事處' },
      { 6307: '8536 中沙分部' },
      { 6308: '8547 海寮分部' },
      { 6309: '8606 白河區農會' },
      { 6310: '8617 市場分部' },
      { 6311: '8628 關嶺分部' },
      { 6312: '8639 竹門分部' },
      { 6313: '8640 玉豐分部' },
      { 6314: '8651 內角分部' },
      { 6315: '8709 東山區農會' },
      { 6316: '8710 東原辦事處' },
      { 6317: '8721 青山辦事處' },
      { 6318: '8732 南溪辦事處' },
      { 6319: '8743 聖賢辦事處' },
      { 6320: '8802 後壁區農會' },
      { 6321: '8813 菁寮辦事處' },
      { 6322: '8824 安溪辦事處' },
      { 6323: '8835 上茄苳分部' },
      { 6324: '8846 新東分部' },
      { 6325: '8857 竹新分部' },
      { 6326: '9005 內埔地區農會' },
      { 6327: '9016 豐田分部' },
      { 6328: '9027 東勢分部' },
      { 6329: '9038 龍泉分部' },
      { 6330: '9049 水門分部' },
      { 6331: '9050 老埤分部' },
      { 6332: '9108 鳳榮地區農會' },
      { 6333: '9119 林榮分部' },
      { 6334: '9120 萬榮分部' },
      { 6335: '9201 光豐地區農會' },
      { 6336: '9212 大富分部' },
      { 6337: '9223 豐濱分部' },
      { 6338: '9304 和平區農會' },
      { 6339: '9315 梨山辦事處' },
      { 6340: '9326 雙崎辦事處' },
      { 6341: '9407 大雅區農會' },
      { 6342: '9418 上楓分部' },
      { 6343: '9429 馬岡分部' },
      { 6344: '9430 忠義分部' },
      { 6345: '9452 橫山分部' },
      { 6346: '9603 台中市東勢區農會' },
      { 6347: '9706 台中市沙鹿區農會' },
      { 6348: '9717 北勢分部' },
      { 6349: '9728 鹿峰分部' },
      { 6350: '9739 西明辦事處' },
      { 6351: '9740 公館辦事處' },
      { 6352: '9809 新化區農會' },
      { 6353: '9810 那拔林分部' },
      { 6354: '9821 中山分部' },
      { 6355: '9902 南化區農會' }
    ],
    93: [
      { 5334: '0011 桃園區農會' },
      { 5335: '0468 大林分部' },
      { 5336: '0479 會稽分部' },
      { 5337: '0527 中路分部' },
      { 5338: '0538 慈文分部' },
      { 5339: '0631 埔子分部' },
      { 5340: '0642 龍山分部' },
      { 5341: '0033 大溪區農會' },
      { 5342: '0158 一心分部' },
      { 5343: '0169 中新分部' },
      { 5344: '0170 新峰分部' },
      { 5345: '0181 埔頂分部' },
      { 5346: '0387 員林分部' },
      { 5347: '0435 僑愛分部' },
      { 5348: '0712 崎頂分部' },
      { 5349: '0826 南興分部' },
      { 5350: '0044 楊梅區農會' },
      { 5351: '0192 老街分部' },
      { 5352: '0206 埔心分部' },
      { 5353: '0217 富岡分部' },
      { 5354: '0697 大平分部' },
      { 5355: '0055 大園區農會' },
      { 5356: '0228 埔心分部' },
      { 5357: '0239 竹圍分部' },
      { 5358: '0240 三和分部' },
      { 5359: '0561 和平分部' },
      { 5360: '0745 五權分部' },
      { 5361: '0756 潮音分部' },
      { 5362: '0066 蘆竹區農會' },
      { 5363: '0251 新興分部' },
      { 5364: '0262 山腳分部' },
      { 5365: '0273 宏竹分部' },
      { 5366: '0284 大竹分部' },
      { 5367: '0398 外社分部' },
      { 5368: '0402 蘆竹分部' },
      { 5369: '0505 中福分部' },
      { 5370: '0516 五福分部' },
      { 5371: '0859 海湖分部' },
      { 5372: '0077 龜山區農會' },
      { 5373: '0147 樂善分部' },
      { 5374: '0295 大崗分部' },
      { 5375: '0376 山頂分部' },
      { 5376: '0572 龍壽分部' },
      { 5377: '0583 南上分部' },
      { 5378: '0620 公西分部' },
      { 5379: '0723 嶺頂分部' },
      { 5380: '0804 山福分部' },
      { 5381: '0848 舊路分部' },
      { 5382: '0088 八德區農會' },
      { 5383: '0309 大湳分部' },
      { 5384: '0446 瑞豐分部' },
      { 5385: '0457 大福分部' },
      { 5386: '0549 茄苳分部' },
      { 5387: '0550 桃鶯分部' },
      { 5388: '0837 霄裡分部' },
      { 5389: '0103 新屋區農會' },
      { 5390: '0310 永安分部' },
      { 5391: '0321 大坡分部' },
      { 5392: '0594 高榮分部' },
      { 5393: '0114 龍潭區農會' },
      { 5394: '0480 中興分部' },
      { 5395: '0491 烏林分部' },
      { 5396: '0778 高平分部' },
      { 5397: '0790 八德分部' },
      { 5398: '0136 復興區農會' },
      { 5399: '0619 三民分部' },
      { 5400: '0653 觀音區農會' },
      { 5401: '0686 平鎮區農會' },
      { 5402: '0099 北勢分部' },
      { 5403: '0365 山峰分部' },
      { 5404: '0413 龍岡分部' },
      { 5405: '0424 宋屋分部' },
      { 5406: '0767 金陵分部' }
    ],
    124: [
      { 5407: '0015 大里區農會' },
      { 5408: '0026 內新辦事處' },
      { 5409: '0037 草湖辦事處' },
      { 5410: '0048 健民辦事處' },
      { 5411: '0059 東榮辦事處' },
      { 5412: '0060 金城辦事處' },
      { 5413: '0071 十九甲辦事處' },
      { 5414: '0093 仁化辦事處' },
      { 5415: '0107 國光辦事處' },
      { 5416: '0118 成功辦事處' },
      { 5417: '0129 立新辦事處' }
    ],
    128: [
      { 5418: '0086 冬山鄉農會' },
      { 5419: '0189 羅東辦事處' },
      { 5420: '0190 順安辦事處' },
      { 5421: '0271 廣興辦事處' },
      { 5422: '0488 群英分部' },
      { 7003: '0019 冬山鄉農會信用部' },
      { 7004: '0020 羅東分部' },
      { 7005: '0031 順安分部' },
      { 7006: '0042 廣興分部' },
      { 7007: '0053 群英分部' }
    ],
    129: [
      { 5423: '0024 草屯鎮農會' },
      { 5424: '0183 雙冬辦事處' },
      { 5425: '0493 南埔分部' },
      { 5426: '0600 芬草分部' },
      { 5427: '0611 虎山分部' },
      { 5428: '0677 北勢分部' },
      { 5429: '0688 富寮分部' },
      { 5430: '0758 新庄分部' },
      { 7013: '0013 草屯鎮農會' },
      { 7014: '0035 南埔分部' },
      { 7015: '0057 芬草分部' },
      { 7016: '0068 虎山分部' },
      { 7017: '0079 北勢分部' },
      { 7018: '0080 新庄分部' },
      { 7019: '0091 富寮分部' }
    ],
    132: [
      { 5431: '0018 板橋區農會' },
      { 5432: '0029 後埔辦事處' },
      { 5433: '0030 埔墘辦事處' },
      { 5434: '0041 社後辦事處' },
      { 5435: '0052 江翠辦事處' },
      { 5436: '0063 溪崑辦事處' },
      { 5437: '0074 浮洲辦事處' },
      { 5438: '0111 新埔辦事處' },
      { 5439: '0122 文化辦事處' }
    ],
    89: [
      { 5440: '0016 基隆市農會' },
      { 5441: '0027 安樂分會' },
      { 5442: '0038 百福分會' },
      { 5443: '0049 愛六路分部' }
    ],
    91: [
      { 5444: '0019 三重區農會' },
      { 5445: '0606 溪美分部' },
      { 5446: '0710 力行分部' },
      { 5447: '0721 三民分部' },
      { 5448: '0916 光復分部' },
      { 5449: '0938 成功分部' },
      { 5450: '1119 慈福分部' },
      { 5451: '0031 淡水區農會' },
      { 5452: '0798 中興分部' },
      { 5453: '0802 竹圍分部' },
      { 5454: '0813 水碓分部' },
      { 5455: '1061 福德分部' },
      { 5456: '1348 義山分部' },
      { 5457: '1360 新興分部' },
      { 5458: '0042 樹林區農會' },
      { 5459: '0237 三多分部' },
      { 5460: '0248 山佳分部' },
      { 5461: '0259 柑園分部' },
      { 5462: '0754 保安分部' },
      { 5463: '1027 東山分部' },
      { 5464: '1038 金寮分部' },
      { 5465: '1120 圳安分部' },
      { 5466: '1186 大學城分部' },
      { 5467: '1315 大同分部' },
      { 5468: '1337 復興分部' },
      { 5469: '0053 鶯歌區農會' },
      { 5470: '0503 二橋分部' },
      { 5471: '0514 鳳鳴分部' },
      { 5472: '1197 永昌分部' },
      { 5473: '0064 三峽區農會' },
      { 5474: '0260 成福分部' },
      { 5475: '0558 橫溪分部' },
      { 5476: '0673 大埔分部' },
      { 5477: '1131 北大分部' },
      { 5478: '1359 民生分部' },
      { 5479: '0097 土城區農會' },
      { 5480: '0318 清水分部' },
      { 5481: '0684 頂埔分部' },
      { 5482: '0695 貨饒分部' },
      { 5483: '0891 峰廷分部' },
      { 5484: '0905 廣福分部' },
      { 5485: '1212 永豐分部' },
      { 5486: '0101 蘆洲區農會' },
      { 5487: '0569 長安分部' },
      { 5488: '0835 成功分部' },
      { 5489: '1072 長榮分部' },
      { 5490: '0112 五股區農會' },
      { 5491: '0329 興珍分部' },
      { 5492: '0330 成州分部' },
      { 5493: '0709 德泰分部' },
      { 5494: '0950 五福分部' },
      { 5495: '1083 德音分部' },
      { 5496: '1094 成德分部' },
      { 5497: '1175 成功分部' },
      { 5498: '0123 林口區農會' },
      { 5499: '0341 南勢分部' },
      { 5500: '0134 泰山區農會' },
      { 5501: '0651 貴子分部' },
      { 5502: '0662 明志分部' },
      { 5503: '0927 貴和分部' },
      { 5504: '1005 泰林分部' },
      { 5505: '1016 泰友分部' },
      { 5506: '0145 坪林區農會' },
      { 5507: '0156 八里區農會' },
      { 5508: '0765 埤頭分部' },
      { 5509: '0824 龍形分部' },
      { 5510: '0167 金山地區農會' },
      { 5511: '0352 萬里分部' },
      { 5512: '0857 野柳分部' },
      { 5513: '0178 瑞芳地區農會' },
      { 5514: '0374 九份分部' },
      { 5515: '0385 四腳亭分部' },
      { 5516: '0396 雙溪辦事處' },
      { 5517: '0400 貢寮辦事處' },
      { 5518: '0411 澳底分部' },
      { 5519: '0868 龍興分部' },
      { 5520: '0961 福隆辦事處' },
      { 5521: '0189 新店地區農會' },
      { 5522: '0363 德安分部' },
      { 5523: '0422 中正分部' },
      { 5524: '0433 烏來分部' },
      { 5525: '1108 新和分部' },
      { 5526: '1245 青潭辦事處' },
      { 5527: '1326 安康分部' },
      { 5528: '0190 中和地區農會' },
      { 5529: '0444 中正分部' },
      { 5530: '0455 員山分部' },
      { 5531: '0570 智光分部' },
      { 5532: '0581 連城分部' },
      { 5533: '0592 民享分部' },
      { 5534: '0732 錦和分部' },
      { 5535: '0743 仁愛分部' },
      { 5536: '1223 竹林分部' },
      { 5537: '1289 興南分部' },
      { 5538: '0204 深坑區農會' },
      { 5539: '1201 萬順辦事處' },
      { 5540: '0466 石碇區農會' },
      { 5541: '0477 平溪區農會' },
      { 5542: '0499 石門區農會' },
      { 5543: '0547 老梅分部' },
      { 5544: '1290 三芝區農會' },
      { 5545: '0846 北新庄分部' }
    ],
    92: [
      { 5546: '0010 宜蘭市農會' },
      { 5547: '0308 神農分部' },
      { 5548: '0386 南門分部' },
      { 5549: '0412 新店分部' },
      { 5550: '0515 延平分部' },
      { 5551: '0021 頭城鎮農會' },
      { 5552: '0113 大溪分部' },
      { 5553: '0342 金面分部' },
      { 5554: '0478 竹安分部' },
      { 5555: '0032 羅東鎮農會' },
      { 5556: '0283 民權辦事處' },
      { 5557: '0319 南門辦事處' },
      { 5558: '0364 北門辦事處' },
      { 5559: '0375 中正路辦事處' },
      { 5560: '0467 東門辦事處' },
      { 5561: '0054 壯圍鄉農會' },
      { 5562: '0135 古亭辦事處' },
      { 5563: '0526 美城分部' },
      { 5564: '0065 員山鄉農會' },
      { 5565: '0146 同樂分部' },
      { 5566: '0331 深溝分部' },
      { 5567: '0445 大湖分部' },
      { 5568: '0076 五結鄉農會' },
      { 5569: '0157 利澤分部' },
      { 5570: '0168 二結分部' },
      { 5571: '0179 四結分部' },
      { 5572: '0098 蘇澳地區農會' },
      { 5573: '0205 南方澳分部' },
      { 5574: '0216 南澳分部' },
      { 5575: '0227 新馬分部' },
      { 5576: '0238 新城分部' },
      { 5577: '0423 龍德分部' },
      { 5578: '0102 三星地區農會' },
      { 5579: '0249 大洲辦事處' },
      { 5580: '0250 福山辦事處' },
      { 5581: '0353 大隱辦事處' },
      { 5582: '0434 大同辦事處' }
    ],
    94: [
      { 5583: '0089 中華民國農會中壢辦事處信用部' },
      { 5584: '0090 大崙分部' }
    ],
    95: [
      { 5585: '0016 關西鎮農會' },
      { 5586: '0131 石光分部' },
      { 5587: '0142 錦山分部' },
      { 5588: '0027 新埔鎮農會' },
      { 5589: '0393 照門分部' },
      { 5590: '0038 竹北市農會' },
      { 5591: '0153 六家分部' },
      { 5592: '0290 豐田分部' },
      { 5593: '0304 鳳岡分部' },
      { 5594: '0430 高鐵分部' },
      { 5595: '0050 湖口鄉農會' },
      { 5596: '0348 中興辦事處' },
      { 5597: '0474 客家樓分部' },
      { 5598: '0061 新竹市農會信用部' },
      { 5599: '0186 三姓橋分部' },
      { 5600: '0315 樹林頭分部' },
      { 5601: '0337 光復分部' },
      { 5602: '0359 內湖分部' },
      { 5603: '0360 民權分部' },
      { 5604: '0429 新興分部' },
      { 5605: '0441 虎林分部' },
      { 5606: '0452 光華分部' },
      { 5607: '0072 芎林鄉農會' },
      { 5608: '0197 秀湖分部' },
      { 5609: '0326 石潭分部' },
      { 5610: '0485 上山分部' },
      { 5611: '0083 寶山鄉農會' },
      { 5612: '0201 新城分部' },
      { 5613: '0212 寶山分部' },
      { 5614: '0094 峨眉鄉農會' },
      { 5615: '0371 富興分部' },
      { 5616: '0108 北埔鄉農會' },
      { 5617: '0119 竹東地區農會' },
      { 5618: '0223 竹中分部' },
      { 5619: '0234 二重辦事處' },
      { 5620: '0256 五峰辦事處' },
      { 5621: '0382 東寧辦事處' },
      { 5622: '0120 橫山地區農會' },
      { 5623: '0267 橫山分部' },
      { 5624: '0289 尖石分部' },
      { 5625: '0463 新豐鄉農會' }
    ],
    96: [{ 5626: '0062 後龍鎮農會' }, { 5627: '0501 新港分部' }],
    107: [
      { 5628: '0012 北投區農會' },
      { 5629: '0078 石牌分部' },
      { 5630: '0089 關渡分部' },
      { 5631: '0126 立農分部' },
      { 5632: '0137 大屯分部' },
      { 5633: '0241 福興分部' },
      { 5634: '0023 士林區農會' },
      { 5635: '0090 社子分會' },
      { 5636: '0148 文林分會' },
      { 5637: '0159 天母分會' },
      { 5638: '0207 陽明山分部' },
      { 5639: '0229 延平分部' },
      { 5640: '0034 內湖區農會' },
      { 5641: '0104 潭美分部' },
      { 5642: '0171 東湖分部' },
      { 5643: '0182 西湖分部' },
      { 5644: '0274 民權分部' },
      { 5645: '0045 南港區農會' },
      { 5646: '0115 中研分部' },
      { 5647: '0218 後山埤分部' },
      { 5648: '0056 木柵區農會' },
      { 5649: '0252 木新分部' },
      { 5650: '0067 景美區農會' }
    ],
    98: [
      { 5652: '0075 名間鄉農會' },
      { 5653: '0260 新街分部' },
      { 5654: '0271 大庄分部' },
      { 5655: '0282 赤水分部' },
      { 5656: '0293 松柏嶺分部' },
      { 5657: '0466 新光分部' },
      { 7115: '0031 集集鎮農會' },
      { 7116: '0488 田寮分部' }
    ],
    99: [
      { 5658: '0043 員林鎮農會' },
      { 5659: '0526 東山分部' },
      { 5660: '0700 百果山分部' },
      { 5661: '0087 二林鎮農會' },
      { 5662: '0711 原斗分部' },
      { 5663: '0799 挖仔分部' },
      { 5664: '0858 中山分部' },
      { 5665: '1291 萬興分部' },
      { 5666: '0124 秀水鄉農會' },
      { 5667: '0320 福安分部' },
      { 5668: '0478 馬鳴分部' },
      { 5669: '0515 金興分部' },
      { 5670: '0179 埔心鄉農會' },
      { 5671: '0559 舊館分部' },
      { 5672: '0593 明聖分部' },
      { 5673: '0180 永靖鄉農會' },
      { 5674: '0696 永安分部' },
      { 5675: '0733 五福分部' },
      { 5676: '0870 同安分部' },
      { 5677: '1028 永興分部' },
      { 5678: '0227 埤頭鄉農會' },
      { 5679: '0401 路口厝分部' },
      { 5680: '0412 埤頭分部' },
      { 5681: '0423 芙朝分部' },
      { 5682: '0261 竹塘鄉農會' },
      { 5683: '1017 長安分部' },
      { 5684: '1327 芬園鄉農會' },
      { 5685: '1338 芳苑鄉農會' }
    ],
    100: [
      { 5686: '0012 斗六市農會' },
      { 5687: '0492 石榴分部' },
      { 5688: '0595 鎮南分部' },
      { 5689: '0609 光興分部' },
      { 5690: '0746 成功分部' },
      { 5691: '0757 虎溪分部' },
      { 5692: '0023 虎尾鎮農會' },
      { 5693: '0414 德興分部' },
      { 5694: '0506 大屯分部' },
      { 5695: '0517 惠來分部' },
      { 5696: '0610 頂溪分部' },
      { 5697: '0779 立仁分部' },
      { 5698: '0034 西螺鎮農會' },
      { 5699: '0403 埤源分部' },
      { 5700: '0528 九隆分部' },
      { 5701: '0539 和心分部' },
      { 5702: '0540 建興分部' },
      { 5703: '0621 中山分部' },
      { 5704: '0056 斗南鎮農會' },
      { 5705: '0218 石龜溪分部' },
      { 5706: '0458 西埤分部' },
      { 5707: '0469 新光分部' },
      { 5708: '0702 明昌分部' },
      { 5709: '0735 西岐分部' },
      { 5710: '0078 古坑鄉農會' },
      { 5711: '0230 永光分部' },
      { 5712: '0241 東和分部' },
      { 5713: '0089 大埤鄉農會' },
      { 5714: '0252 怡然分部' },
      { 5715: '0562 嘉興分部' },
      { 5716: '0090 莿桐鄉農會' },
      { 5717: '0263 饒平分部' },
      { 5718: '0436 六合分部' },
      { 5719: '0115 二崙鄉農會' },
      { 5720: '0274 油車分部' },
      { 5721: '0285 永定分部' },
      { 5722: '0126 崙背鄉農會' },
      { 5723: '0713 豐榮分部' },
      { 5724: '0137 台西鄉農會' },
      { 5725: '0296 崙豐分部' },
      { 5726: '0643 牛厝分部' },
      { 5727: '0665 蚊港分部' },
      { 5728: '0148 褒忠鄉農會' },
      { 5729: '0160 四湖鄉農會' },
      { 5730: '0300 飛沙分部' },
      { 5731: '0447 溪底分部' },
      { 5732: '0573 林厝分部' },
      { 5733: '0698 箔子分部' },
      { 5734: '0171 口湖鄉農會' },
      { 5735: '0311 宜梧分部' },
      { 5736: '0322 金湖分部' },
      { 5737: '0333 下崙分部' }
    ],
    101: [
      { 5738: '0024 朴子市農會' },
      { 5739: '0219 開元分部' },
      { 5740: '0220 文化分部' },
      { 5741: '0231 松梅分部' },
      { 5742: '1353 大慷榔分部' },
      { 5743: '1364 雙溪口分部' },
      { 5744: '0035 布袋鎮農會' },
      { 5745: '0242 過溝分部' },
      { 5746: '0253 永安分部' },
      { 5747: '0769 新厝分部' },
      { 5748: '1375 新塭分部' },
      { 5749: '0046 大林鎮農會' },
      { 5750: '0264 吉林分部' },
      { 5751: '0275 溝背分部' },
      { 5752: '0286 大美分部' },
      { 5753: '0297 排路分部' },
      { 5754: '0781 中林分部' },
      { 5755: '1467 中坑分部' },
      { 5756: '0057 民雄鄉農會' },
      { 5757: '0301 北斗分部' },
      { 5758: '0312 山中分部' },
      { 5759: '0323 菁埔分部' },
      { 5760: '0714 秀林分部' },
      { 5761: '1478 三興分部' },
      { 5762: '1489 興南分部' },
      { 5763: '0068 溪口鄉農會' },
      { 5764: '0334 柴林分部' },
      { 5765: '0345 崙尾分部' },
      { 5766: '1386 柳溝分部' },
      { 5767: '0079 東石鄉農會' },
      { 5768: '0356 東石分部' },
      { 5769: '0367 副瀨分部' },
      { 5770: '0378 下楫分部' },
      { 5771: '0389 西崙分部' },
      { 5772: '0080 義竹鄉農會' },
      { 5773: '0390 過路分部' },
      { 5774: '0404 光榮分部' },
      { 5775: '1397 新店分部' },
      { 5776: '0091 鹿草鄉農會' },
      { 5777: '0415 下潭分部' },
      { 5778: '0426 後堀分部' },
      { 5779: '1331 重寮分部' },
      { 5780: '1401 松山分部' },
      { 5781: '0105 太保市農會' },
      { 5782: '0437 後潭分部' },
      { 5783: '0448 新埤分部' },
      { 5784: '0459 南新分部' },
      { 5785: '0758 嘉太分部' },
      { 5786: '1412 安東分部' },
      { 5787: '0116 水上鄉農會' },
      { 5788: '0460 成功分部' },
      { 5789: '0471 南靖分部' },
      { 5790: '0482 大崙分部' },
      { 5791: '0493 忠和分部' },
      { 5792: '0507 北回分部' },
      { 5793: '0518 柳林分部' },
      { 5794: '0725 民生分部' },
      { 5795: '0138 番路鄉農會' },
      { 5796: '0563 觸口分部' },
      { 5797: '1320 隙頂分部' },
      { 5798: '0149 竹崎地區農會' },
      { 5799: '0574 內埔分部' },
      { 5800: '0585 灣橋分部' },
      { 5801: '0596 東義分部' },
      { 5802: '0699 大埔分部' },
      { 5803: '1423 石棹分部' },
      { 5804: '1490 鹿滿分部' },
      { 5805: '0150 梅山鄉農會' },
      { 5806: '0600 瑞里分部' },
      { 5807: '0611 太平分部' },
      { 5808: '0622 太和分部' },
      { 5809: '0806 六腳鄉農會' },
      { 5810: '0172 蒜頭分部' },
      { 5811: '0666 港尾寮分部' },
      { 5812: '0677 六腳分部' },
      { 5813: '0688 灣內分部' },
      { 5814: '0703 更寮分部' },
      { 5815: '1434 蘇崙分部' },
      { 5816: '0817 新港鄉農會' },
      { 5817: '0633 月眉分部' },
      { 5818: '0644 大潭分部' },
      { 5819: '0655 崙子分部' },
      { 5820: '1261 安和分部' },
      { 5821: '0862 嘉義市農會' },
      { 5822: '0013 吳鳳分部' },
      { 5823: '0194 福全分部' },
      { 5824: '0208 北興分部' },
      { 5825: '1272 宣信分部' },
      { 5826: '1283 民生分部' }
    ],
    102: [
      { 5827: '0025 新營區農會' },
      { 5828: '0335 太子宮分部' },
      { 5829: '0346 延平分部' },
      { 5830: '1066 三民分部' },
      { 5831: '1217 中正分部' },
      { 5832: '0036 鹽水區農會' },
      { 5833: '0357 歡雅分部' },
      { 5834: '0368 圣頭港分部' },
      { 5835: '0069 佳里區農會' },
      { 5836: '0416 佳興分部' },
      { 5837: '0427 子龍分部' },
      { 5838: '0438 塭內分部' },
      { 5839: '0449 延平分部' },
      { 5840: '1158 新生分部' },
      { 5841: '0081 善化區農會' },
      { 5842: '0450 東關里分部' },
      { 5843: '0461 六分寮分部' },
      { 5844: '1365 茄拔分部' },
      { 5845: '0139 六甲區農會' },
      { 5846: '0612 林鳳營分部' },
      { 5847: '0162 西港區農會' },
      { 5848: '0689 後營分部' },
      { 5849: '0715 竹林分部' },
      { 5850: '0184 將軍區農會' },
      { 5851: '0771 苓仔寮分部' },
      { 5852: '0782 將軍分部' },
      { 5853: '0793 馬沙溝分部' },
      { 5854: '0807 青鯤鯓分部' },
      { 5855: '0195 北門區農會' },
      { 5856: '0818 溪底寮分部' },
      { 5857: '0829 蚵寮分部' },
      { 5858: '1206 三慈分部' },
      { 5859: '0243 玉井區農會' },
      { 5860: '0298 歸仁區農會' },
      { 5861: '0966 大潭武東分部' },
      { 5862: '0977 大廟分部' },
      { 5863: '0988 新市場分部' },
      { 5864: '1239 歸南分部' },
      { 5865: '0324 永康區農會' },
      { 5866: '1011 大灣分部' },
      { 5867: '1181 鹽行分部' },
      { 5868: '1240 中華分部' },
      { 5869: '1262 崑山分部' },
      { 5870: '1343 龍潭分部' },
      { 5871: '1402 楠西區農會' },
      { 7117: '0092 柳營區農會' },
      { 7118: '0472 重溪辦事處' },
      { 7119: '0483 果毅辦事處' },
      { 7120: '0494 太康辦事處' },
      { 7121: '0508 旭山辦事處' }
    ],
    104: [
      { 5926: '0031 東港鎮農會' },
      { 5927: '0721 下部分部' },
      { 5928: '0042 恆春鎮農會' },
      { 5929: '0547 墾丁分部' },
      { 5930: '0075 麟洛鄉農會' },
      { 5931: '0086 九如鄉農會' },
      { 5932: '0787 洽興分部' },
      { 5933: '0112 里港鄉農會' },
      { 5934: '0330 土庫辦事處' },
      { 5935: '0341 載興辦事處' },
      { 5936: '0628 三廓分部' },
      { 5937: '0950 塔樓分部' },
      { 5938: '0167 崁頂鄉農會' },
      { 5939: '0709 力社分部' },
      { 5940: '0710 港東分部' },
      { 5941: '0189 南州地區農會' },
      { 5942: '0813 新埤分部' },
      { 5943: '0204 琉球鄉農會' },
      { 5944: '0215 滿州鄉農會' },
      { 5945: '0259 枋山地區農會' },
      { 5946: '0743 枋山分部' },
      { 5947: '0754 加祿分部' },
      { 5948: '0868 屏東市農會' },
      { 5949: '0879 車城地區農會' },
      { 5950: '0880 屏東縣農會' },
      { 5951: '0891 枋寮地區農會' },
      { 5952: '0905 竹田鄉農會' },
      { 5953: '0916 萬丹鄉農會' },
      { 5954: '0927 長治鄉農會' },
      { 5955: '0938 林邊鄉農會' },
      { 5956: '0949 佳冬鄉農會' },
      { 5957: '0961 高樹鄉農會' },
      { 5958: '0972 萬巒地區農會' },
      { 5959: '0994 新園鄉農會' },
      { 7123: '0983 潮州鎮農會' }
    ],
    105: [
      { 5960: '0021 吉安鄉農會' },
      { 5961: '0102 仁里分部' },
      { 5962: '0227 北昌分部' },
      { 5963: '0238 宜昌分部' },
      { 5964: '0032 壽豐鄉農會' },
      { 5965: '0124 志學分部' },
      { 5966: '0331 豐裡分部' },
      { 5967: '0043 富里鄉農會' },
      { 5968: '0135 東竹分部' },
      { 5969: '0146 東里分部' },
      { 5970: '0098 新秀地區農會' },
      { 5971: '0191 新城分部' },
      { 5972: '0205 嘉里分部' },
      { 5973: '0216 秀林分部' }
    ],
    106: [
      { 5974: '0011 關山鎮農會' },
      { 5975: '0239 海端分部' },
      { 5976: '0022 成功鎮農會' },
      { 5977: '0206 忠孝分部' },
      { 5978: '0251 信義分部' },
      { 5979: '0033 池上鄉農會' },
      { 5980: '0055 長濱鄉農會' },
      { 5981: '0066 台東地區農會' },
      { 5982: '0099 豐里分部' },
      { 5983: '0125 台東分部' },
      { 5984: '0136 知本分部' },
      { 5985: '0147 綠島分部' },
      { 5986: '0158 初鹿分部' },
      { 5987: '0217 太平分部' },
      { 5988: '0228 蘭嶼分部' },
      { 5989: '0284 新園分部' },
      { 5990: '0077 鹿野地區農會' },
      { 5991: '0169 瑞源分部' },
      { 5992: '0240 瑞豐分部' },
      { 5993: '0088 太麻里地區農會' },
      { 5994: '0170 美和分部' },
      { 5995: '0181 金崙分部' },
      { 5996: '0192 大武分部' },
      { 5997: '0103 東河鄉農會' },
      { 5998: '0044 東河分部' },
      { 5999: '0114 泰源分部' }
    ],
    108: [
      { 6000: '0013 澎湖縣農會信用部' },
      { 6001: '0024 湖西分部' },
      { 6002: '0035 西嶼分部' },
      { 6003: '0046 望安分部' },
      { 6004: '0057 白沙分部' },
      { 6005: '0068 七美分部' }
    ],
    110: [{ 6006: '0016 連江縣農會' }],
    134: [{ 6007: '0103 小港區農會' }],
    146: [{ 6454: '0017 宜蘭縣蘇澳區漁會' }, { 6455: '0028 新馬分部' }],
    147: [
      { 6456: '0018 宜蘭縣頭城區漁會' },
      { 6457: '0029 大溪分部' },
      { 6458: '0030 壯圍分部' }
    ],
    148: [{ 6459: '0014 通苑區漁會' }, { 6460: '0025 苑裡分部' }],
    149: [
      { 6462: '0010 彰化區漁會' },
      { 6463: '0021 大城分部' },
      { 6464: '0032 王功分部' },
      { 6465: '0043 芳苑分部' },
      { 6466: '0054 草港分部' },
      { 6467: '0065 福興分部' },
      { 6468: '0076 伸港分部' },
      { 6469: '0087 埔心分部' }
    ],
    150: [{ 6470: '0012 新北市瑞芳區漁會' }, { 6471: '0023 深澳分部' }],
    151: [{ 6472: '0013 萬里區漁會' }],
    152: [{ 6473: '0015 基隆區漁會' }],
    153: [
      { 6474: '0018 新化區農會' },
      { 6475: '0029 那拔林分部' },
      { 6476: '0030 中山分部' }
    ],
    154: [
      { 6477: '0013 宜蘭市農會' },
      { 6478: '0024 神農分部' },
      { 6479: '0035 新店分部' },
      { 6480: '0057 南門分部' },
      { 6481: '0068 延平分部' }
    ],
    155: [
      { 6482: '0019 白河區農會' },
      { 6483: '0020 市場分部' },
      { 6484: '0031 玉豐分部' },
      { 6485: '0042 關嶺分部' },
      { 6486: '0053 竹門分部' }
    ],
    156: [
      { 6487: '0010 麻豆區農會' },
      { 6488: '0021 新生分部' },
      { 6489: '0032 復光分部' },
      { 6490: '0043 安業分部' }
    ],
    157: [
      { 6491: '0015 後壁區農會' },
      { 6492: '0026 菁寮分部' },
      { 6493: '0037 安溪分部' },
      { 6494: '0048 新東分部' },
      { 6495: '0059 上茄苳分部' },
      { 6496: '0060 竹新分部' }
    ],
    158: [
      { 6497: '0017 下營區農會' },
      { 6498: '0028 中營分部' },
      { 6499: '0039 中興分部' },
      { 6500: '0040 甲中分部' }
    ],
    159: [
      { 6501: '0012 官田區農會' },
      { 6502: '0023 官田辦事處' },
      { 6503: '0034 渡頭辦事處' },
      { 6504: '0045 西庄辦事處' },
      { 6505: '0056 湖山辦事處' },
      { 6506: '0067 二鎮辦事處' }
    ],
    160: [
      { 6507: '0013 大內區農會' },
      { 6508: '0024 頭社分部' },
      { 6509: '0035 環湖分部' },
      { 6510: '0046 二溪辦事處' }
    ],
    161: [
      { 6511: '0017 學甲區農會' },
      { 6512: '0028 中洲分部' },
      { 6513: '0039 宅港分部' },
      { 6514: '0040 頂洲分部' },
      { 6515: '0051 大灣分部' },
      { 6516: '0062 草圣分部' }
    ],
    162: [{ 6517: '0018 新市區農會' }, { 6518: '0029 大社分部' }],
    163: [
      { 6519: '0019 安定區農會' },
      { 6520: '0020 港口分部' },
      { 6521: '0031 蘇厝分部' },
      { 6522: '0042 海寮分部' },
      { 6523: '0053 中沙分部' }
    ],
    164: [{ 6524: '0010 山上區農會' }],
    165: [{ 6525: '0015 左鎮區農會' }],
    166: [
      { 6526: '0016 仁德區農會' },
      { 6527: '0027 保安分部' },
      { 6528: '0038 二行分部' },
      { 6529: '0049 太子分部' },
      { 6530: '0050 中洲分部' },
      { 6531: '0061 後壁分部' }
    ],
    167: [
      { 6532: '0018 關廟區農會' },
      { 6533: '0029 龜洞分部' },
      { 6534: '0030 埤頭分部' }
    ],
    168: [{ 6535: '0019 龍崎區農會' }, { 6536: '0020 龍船分部' }],
    169: [{ 6537: '0011 南化區農會' }],
    170: [{ 6538: '0012 七股區農會' }],
    171: [
      { 6539: '0017 南投市農會' },
      { 6540: '0028 內興分部' },
      { 6541: '0039 永豐分部' },
      { 6542: '0040 福山分部' },
      { 6543: '0051 營南分部' },
      { 6544: '0062 營北分部' },
      { 6545: '0073 漳和分部' },
      { 6546: '0084 鳳山分部' }
    ],
    172: [
      { 6547: '0010 埔里鎮農會' },
      { 6548: '0021 愛蘭分部' },
      { 6549: '0032 合成分部' },
      { 6550: '0043 東興分部' }
    ],
    173: [
      { 6551: '0011 竹山鎮農會' },
      { 6552: '0022 社寮分部' },
      { 6553: '0033 瑞竹分部' },
      { 6554: '0044 延平分部' },
      { 6555: '0055 中央分部' }
    ],
    174: [
      { 6556: '0012 中寮鄉農會' },
      { 6557: '0023 爽文分部' },
      { 6558: '0034 義和分部' },
      { 6559: '0045 內城分部' }
    ],
    175: [
      { 6560: '0014 魚池鄉農會' },
      { 6561: '0025 頭社分部' },
      { 6562: '0036 日月潭分部' },
      { 6563: '0047 東光分部' },
      { 6564: '0058 共和分部' },
      { 6565: '0069 五城分部' }
    ],
    176: [
      { 6566: '0015 水里鄉農會' },
      { 6567: '0026 玉峰分部' },
      { 6568: '0037 民和分部' },
      { 6569: '0048 上安分部' }
    ],
    177: [
      { 6570: '0016 國姓鄉農會' },
      { 6571: '0027 國姓分部' },
      { 6572: '0038 長流分部' },
      { 6573: '0049 北山分部' },
      { 6574: '0050 福龜分部' },
      { 6575: '0072 北港分部' }
    ],
    178: [
      { 6576: '0010 鹿谷鄉農會' },
      { 6577: '0021 廣興分部' },
      { 6578: '0032 竹林分部' },
      { 6579: '0043 永隆分部' },
      { 6580: '0054 初鄉分部' },
      { 6581: '0065 坪頂分部' },
      { 6582: '0076 瑞田分部' },
      { 6583: '0087 鳳凰分部' }
    ],
    179: [
      { 6584: '0011 信義鄉農會' },
      { 6585: '0022 地利分部' },
      { 6586: '0033 和社分部' }
    ],
    180: [{ 6587: '0012 仁愛鄉農會' }],
    181: [
      { 6588: '0013 東山區農會' },
      { 6589: '0024 東原分部' },
      { 6590: '0035 青山分部' },
      { 6591: '0046 聖賢分部' },
      { 6592: '0057 南溪分部' }
    ],
    182: [
      { 6593: '0015 頭城鎮農會' },
      { 6594: '0026 金面分部' },
      { 6595: '0037 大溪分部' }
    ],
    183: [
      { 6596: '0016 羅東鎮農會' },
      { 6597: '0027 中正路分部' },
      { 6598: '0049 民權分部' },
      { 6599: '0050 南門分部' },
      { 6600: '0061 北門分部' },
      { 6601: '0119 東門分部' }
    ],
    184: [
      { 6602: '0017 礁溪鄉農會' },
      { 6603: '0028 四結分部' },
      { 6604: '0039 龍潭分部' },
      { 6605: '0040 德陽分部' }
    ],
    185: [
      { 6606: '0018 壯圍鄉農會' },
      { 6607: '0029 古亭分部' },
      { 6608: '0030 美城分部' }
    ],
    186: [
      { 6609: '0019 員山鄉農會' },
      { 6610: '0020 同樂分部' },
      { 6611: '0031 深溝分部' },
      { 6612: '0042 大湖分部' }
    ],
    187: [
      { 6613: '0019 五結鄉農會' },
      { 6614: '0020 二結分部' },
      { 6615: '0031 利澤分部' },
      { 6616: '0042 四結分部' }
    ],
    188: [
      { 6617: '0011 蘇澳地區農會' },
      { 6618: '0022 南方澳分部' },
      { 6619: '0033 新馬分部' },
      { 6620: '0044 新城分部' },
      { 6621: '0055 南澳分部' },
      { 6622: '0066 龍德分部' }
    ],
    189: [
      { 6623: '0012 三星地區農會' },
      { 6624: '0023 大洲分部' },
      { 6625: '0034 大隱分部' },
      { 6626: '0045 福山分部' },
      { 6627: '0056 大同分部' }
    ],
    190: [
      { 6628: '0015 中華民國農會中壢辦事處信用部' },
      { 6629: '0026 大崙分部' },
      { 6630: '0037 內壢分部' }
    ],
    191: [
      { 6631: '0011 基隆市農會' },
      { 6632: '0022 安樂分部' },
      { 6633: '0033 百福分部' },
      { 6634: '0044 愛六路分部' }
    ],
    192: [
      { 6635: '0017 鹿港鎮農會' },
      { 6636: '0028 頂番分部' },
      { 6637: '0039 草港分部' },
      { 6638: '0040 東崎分部' },
      { 6639: '0051 海埔分部' }
    ],
    193: [
      { 6640: '0018 和美鎮農會' },
      { 6641: '0029 塗厝分部' },
      { 6642: '0030 新庄分部' },
      { 6643: '0041 鎮平分部' }
    ],
    194: [{ 6644: '0013 溪湖鎮農會' }],
    195: [{ 6645: '0014 田中鎮農會' }],
    196: [{ 6646: '0015 北斗鎮農會' }],
    197: [{ 6647: '0017 線西鄉農會' }],
    198: [{ 6648: '0018 伸港鄉農會' }],
    199: [
      { 6649: '0010 花壇鄉農會' },
      { 6650: '0021 白沙分部' },
      { 6651: '0032 灣雅分部' },
      { 6652: '0043 口庄分部' },
      { 6653: '0054 長沙分部' }
    ],
    200: [
      { 6654: '0011 大村鄉農會' },
      { 6655: '0022 大村分部' },
      { 6656: '0033 美港分部' },
      { 6657: '0044 大西分部' },
      { 6658: '0055 村東分部' }
    ],
    201: [
      { 6659: '0017 社頭鄉農會' },
      { 6660: '0028 湳雅分部' },
      { 6661: '0039 橋頭分部' },
      { 6662: '0040 清水分部' }
    ],
    202: [{ 6663: '0018 二水鄉農會' }],
    203: [{ 6664: '0011 大城鄉農會' }, { 6665: '0022 西港分部' }],
    204: [
      { 6666: '0012 溪州鄉農會' },
      { 6667: '0023 西畔分部' },
      { 6668: '0034 大庄分部' }
    ],
    205: [{ 6669: '0014 埔鹽鄉農會' }, { 6670: '0025 新水分部' }],
    206: [
      { 6671: '0018 福興鄉農會' },
      { 6672: '0029 管嶼分部' },
      { 6673: '0030 外中分部' }
    ],
    207: [{ 6674: '0019 彰化市農會' }],
    208: [{ 6675: '0010 北港鎮農會' }, { 6676: '0021 好收分部' }],
    209: [
      { 6677: '0012 土庫鎮農會' },
      { 6678: '0023 馬光分部' },
      { 6679: '0034 新庄分部' },
      { 6680: '0045 忠正分部' },
      { 6681: '0056 後埔分部' }
    ],
    210: [{ 6682: '0013 東勢鄉農會' }, { 6683: '0024 新坤分部' }],
    211: [
      { 6684: '0016 水林鄉農會' },
      { 6685: '0027 蔦松分部' },
      { 6686: '0038 宏仁分部' },
      { 6687: '0049 西井分部' },
      { 6688: '0050 溪墘分部' }
    ],
    212: [
      { 6689: '0017 元長鄉農會' },
      { 6690: '0028 客庴分部' },
      { 6691: '0039 鹿寮分部' }
    ],
    213: [{ 6692: '0018 麥寮鄉農會' }, { 6693: '0029 橋頭分部' }],
    214: [{ 6694: '0019 林內鄉農會' }],
    215: [
      { 6695: '0011 內埔地區農會' },
      { 6696: '0022 龍泉分部' },
      { 6697: '0033 水門分部' },
      { 6698: '0044 東勢分部' },
      { 6699: '0055 豐田分部' },
      { 6700: '0066 老埤分部' }
    ],
    216: [
      { 6701: '0010 大溪區農會' },
      { 6702: '0021 一心分部' },
      { 6703: '0032 中新分部' },
      { 6704: '0043 新峰分部' },
      { 6705: '0054 埔頂分部' },
      { 6706: '0065 員林分部' },
      { 6707: '0076 僑愛分部' },
      { 6708: '0087 崎頂分部' },
      { 6709: '0098 南興分部' }
    ],
    217: [
      { 6710: '0011 桃園區農會' },
      { 6711: '0022 大林分部' },
      { 6712: '0033 會稽分部' },
      { 6713: '0055 中路分部' },
      { 6714: '0066 慈文分部' },
      { 6715: '0077 埔子分部' },
      { 6716: '0088 龍山分部' }
    ],
    218: [
      { 6717: '0012 平鎮區農會' },
      { 6718: '0023 北勢分部' },
      { 6719: '0034 山峰分部' },
      { 6720: '0045 龍岡分部' },
      { 6721: '0056 宋屋分部' },
      { 6722: '0067 金陵分部' }
    ],
    219: [
      { 6723: '0013 楊梅區農會' },
      { 6724: '0035 埔心分部' },
      { 6725: '0046 富岡分部' },
      { 6726: '0057 老街分部' }
    ],
    220: [
      { 6727: '0014 大園區農會' },
      { 6728: '0025 埔心分部' },
      { 6729: '0036 竹圍分部' },
      { 6730: '0047 三和分部' },
      { 6731: '0058 和平分部' },
      { 6732: '0069 五權分部' },
      { 6733: '0070 潮音分部' },
      { 6734: '0081 青埔分部' }
    ],
    221: [
      { 6735: '0015 蘆竹區農會' },
      { 6736: '0026 新興分部' },
      { 6737: '0037 山腳分部' },
      { 6738: '0048 宏竹分部' },
      { 6739: '0059 大竹分部' },
      { 6740: '0060 外社分部' },
      { 6741: '0071 蘆竹分部' },
      { 6742: '0082 中福分部' },
      { 6743: '0093 五福分部' },
      { 6744: '0107 海湖分部' }
    ],
    222: [
      { 6745: '0016 龜山區農會' },
      { 6746: '0027 大崗分部' },
      { 6747: '0038 山頂分部' },
      { 6748: '0049 龍壽分部' },
      { 6749: '0050 南上分部' },
      { 6750: '0061 公西分部' },
      { 6751: '0072 嶺頂分部' },
      { 6752: '0083 山福分部' },
      { 6753: '0094 舊路分部' },
      { 6754: '0108 樂善分部' }
    ],
    223: [
      { 6755: '0017 八德區農會' },
      { 6756: '0028 大湳分部' },
      { 6757: '0039 瑞豐分部' },
      { 6758: '0040 大福分部' },
      { 6759: '0051 茄苳分部' },
      { 6760: '0062 桃鶯分部' },
      { 6761: '0073 霄裡分部' }
    ],
    224: [
      { 6762: '0011 新屋區農會' },
      { 6763: '0022 永安分部' },
      { 6764: '0033 大坡分部' },
      { 6765: '0066 高榮分部' }
    ],
    225: [
      { 6766: '0012 龍潭區農會' },
      { 6767: '0023 中興分部' },
      { 6768: '0034 烏林分部' },
      { 6769: '0045 高平分部' },
      { 6770: '0056 八德分部' }
    ],
    226: [{ 6771: '0013 復興區農會' }, { 6772: '0024 三民分部' }],
    227: [{ 6773: '0014 觀音區農會' }],
    228: [
      { 6774: '0016 土城區農會' },
      { 6775: '0027 清水分部' },
      { 6776: '0038 貨饒分部' },
      { 6777: '0049 頂埔分部' },
      { 6778: '0050 峰廷分部' },
      { 6779: '0061 廣福分部' },
      { 6780: '0072 永豐分部' }
    ],
    229: [
      { 6781: '0017 三重區農會' },
      { 6782: '0028 溪美分部' },
      { 6783: '0040 三民分部' },
      { 6784: '0051 力行分部' },
      { 6785: '0062 光復分部' },
      { 6786: '0073 成功分部' },
      { 6787: '0084 慈福分部' }
    ],
    230: [
      { 6788: '0018 中和地區農會' },
      { 6789: '0029 中正分部' },
      { 6790: '0030 員山分部' },
      { 6791: '0063 興南分部' },
      { 6792: '0085 民享分部' },
      { 6793: '0096 連城分部' },
      { 6794: '0111 仁愛分部' },
      { 6795: '0122 錦和分部' },
      { 6796: '0133 智光分部' },
      { 6797: '0166 竹林分部' }
    ],
    231: [
      { 6798: '0019 淡水區農會' },
      { 6799: '0020 竹圍分部' },
      { 6800: '0031 中興分部' },
      { 6801: '0042 水碓分部' },
      { 6802: '0053 義山分部' },
      { 6803: '0064 新興分部' },
      { 6804: '0075 福德分部' }
    ],
    232: [
      { 6805: '0010 樹林區農會' },
      { 6806: '0021 柑園分部' },
      { 6807: '0032 山佳分部' },
      { 6808: '0043 三多分部' },
      { 6809: '0054 大同分部' },
      { 6810: '0065 保安分部' },
      { 6811: '0113 復興分部' },
      { 6812: '0124 東山分部' },
      { 6813: '0135 金寮分部' },
      { 6814: '0157 圳安分部' },
      { 6815: '0168 大學城分部' }
    ],
    233: [
      { 6816: '0014 鶯歌區農會' },
      { 6817: '0025 二橋分部' },
      { 6818: '0036 鳳鳴分部' },
      { 6819: '0047 永昌分部' }
    ],
    234: [
      { 6820: '0015 三峽區農會' },
      { 6821: '0026 大埔分部' },
      { 6822: '0037 成福分部' },
      { 6823: '0048 橫溪分部' },
      { 6824: '0059 民生分部' },
      { 6825: '0060 北大分部' }
    ],
    235: [
      { 6826: '0019 蘆洲區農會' },
      { 6827: '0020 成功分部' },
      { 6828: '0031 長安分部' },
      { 6829: '0053 長榮分部' }
    ],
    236: [
      { 6830: '0010 五股區農會' },
      { 6831: '0021 興珍分部' },
      { 6832: '0032 成州分部' },
      { 6833: '0043 德泰分部' },
      { 6834: '0054 五福分部' },
      { 6835: '0065 德音分部' },
      { 6836: '0076 成德分部' },
      { 6837: '0087 成功分部' }
    ],
    237: [{ 6838: '0011 林口區農會' }, { 6839: '0022 南勢分部' }],
    238: [
      { 6840: '0012 泰山區農會' },
      { 6841: '0023 貴子分部' },
      { 6842: '0034 明志分部' },
      { 6843: '0045 貴和分部' },
      { 6844: '0056 泰友分部' },
      { 6845: '0067 泰林分部' }
    ],
    239: [{ 6846: '0013 坪林區農會' }],
    240: [
      { 6847: '0017 八里區農會' },
      { 6848: '0028 龍形分部' },
      { 6849: '0039 埤頭分部' }
    ],
    241: [
      { 6850: '0018 金山地區農會' },
      { 6851: '0029 萬里分部' },
      { 6852: '0030 野柳分部' }
    ],
    242: [
      { 6853: '0019 瑞芳地區農會' },
      { 6854: '0020 雙溪分部' },
      { 6855: '0031 貢寮分部' },
      { 6856: '0042 澳底分部' },
      { 6857: '0053 龍興分部' },
      { 6858: '0064 四腳亭分部' },
      { 6859: '0075 福隆分部' }
    ],
    243: [
      { 6860: '0010 新店地區農會' },
      { 6861: '0021 中正分部' },
      { 6862: '0032 烏來分部' },
      { 6863: '0043 安康分部' },
      { 6864: '0054 青潭分部' },
      { 6865: '0065 德安分部' },
      { 6866: '0076 新和分部' }
    ],
    244: [{ 6867: '0012 深坑區農會' }, { 6868: '0023 萬順分部' }],
    245: [{ 6869: '0013 石碇區農會' }],
    246: [{ 6870: '0014 平溪區農會' }],
    247: [{ 6871: '0015 石門區農會' }, { 6872: '0026 老梅分部' }],
    248: [{ 6873: '0016 三芝區農會' }, { 6874: '0027 北新庄分部' }],
    249: [
      { 6875: '0015 中埔鄉農會' },
      { 6876: '0026 同仁分部' },
      { 6877: '0037 隆興分部' },
      { 6878: '0048 金蘭分部' },
      { 6879: '0059 和睦分部' },
      { 6880: '0060 和美分部' },
      { 6881: '0071 和興分部' },
      { 6882: '0093 沄水分部' }
    ],
    250: [
      { 6883: '0011 阿里山鄉農會' },
      { 6884: '0022 中山分部' },
      { 6885: '0033 達邦分部' }
    ],
    251: [{ 6886: '0013 東勢區農會' }],
    252: [
      { 6887: '0014 清水區農會' },
      { 6888: '0025 臨江分部' },
      { 6889: '0036 南社分部' }
    ],
    253: [
      { 6890: '0018 梧棲區農會' },
      { 6891: '0029 興農分部' },
      { 6892: '0030 安寧分部' },
      { 6893: '0041 大莊分部' },
      { 6894: '0052 南簡分部' }
    ],
    254: [
      { 6895: '0019 大甲區農會' },
      { 6896: '0020 日南分部' },
      { 6897: '0031 順天分部' },
      { 6898: '0042 西岐分部' },
      { 6899: '0053 幼獅社區分部' },
      { 6900: '0064 庄美分部' }
    ],
    255: [
      { 6901: '0010 沙鹿區農會' },
      { 6902: '0021 北勢分部' },
      { 6903: '0032 鹿峰分部' },
      { 6904: '0043 西明分部' },
      { 6905: '0054 公館分部' }
    ],
    256: [
      { 6906: '0012 霧峰區農會' },
      { 6907: '0023 萬豐分部' },
      { 6908: '0034 北柳分部' },
      { 6909: '0045 吉峰分部' },
      { 6910: '0056 舊正分部' },
      { 6911: '0067 吉峰路分部' }
    ],
    257: [
      { 6912: '0013 太平區農會' },
      { 6913: '0024 新光分部' },
      { 6914: '0035 頭汴分部' },
      { 6915: '0046 光隆分部' },
      { 6916: '0057 中山分部' },
      { 6917: '0068 東平分部' },
      { 6918: '0079 坪林分部' },
      { 6919: '0080 新興分部' }
    ],
    258: [
      { 6920: '0014 烏日區農會' },
      { 6921: '0025 溪埧分部' },
      { 6922: '0036 九德分部' },
      { 6923: '0047 成功分部' }
    ],
    259: [
      { 6924: '0015 后里區農會' },
      { 6925: '0026 公館分部' },
      { 6926: '0037 義里分部' },
      { 6927: '0048 眉山分部' }
    ],
    260: [
      { 6928: '0016 大雅區農會' },
      { 6929: '0027 橫山分部' },
      { 6930: '0038 上楓分部' },
      { 6931: '0049 馬岡分部' },
      { 6932: '0050 忠義分部' }
    ],
    261: [
      { 6933: '0017 潭子區農會' },
      { 6934: '0028 頭家分部' },
      { 6935: '0039 東寶分部' },
      { 6936: '0040 栗林分部' },
      { 6937: '0051 甘蔗分部' }
    ],
    262: [{ 6938: '0011 石岡區農會' }, { 6939: '0022 和盛分部' }],
    263: [
      { 6940: '0012 新社區農會' },
      { 6941: '0023 福興分部' },
      { 6942: '0034 中興嶺分部' }
    ],
    264: [
      { 6943: '0013 大肚區農會' },
      { 6944: '0024 福山分部' },
      { 6945: '0035 成功分部' },
      { 6946: '0046 蔗部分部' },
      { 6947: '0057 王田分部' }
    ],
    265: [
      { 6948: '0014 外埔區農會' },
      { 6949: '0025 土城分部' },
      { 6950: '0036 大東分部' }
    ],
    266: [
      { 6951: '0015 大安區農會' },
      { 6952: '0026 海墘分部' },
      { 6953: '0037 松雅分部' }
    ],
    267: [
      { 6954: '0016 龍井區農會' },
      { 6955: '0027 龍泉分部' },
      { 6956: '0038 龍津分部' },
      { 6957: '0049 新庄分部' },
      { 6958: '0050 國際分部' }
    ],
    268: [
      { 6959: '0017 和平區農會' },
      { 6960: '0028 梨山分部' },
      { 6961: '0039 雙崎分部' }
    ],
    269: [
      { 6962: '0015 花蓮市農會' },
      { 6963: '0026 花商分部' },
      { 6964: '0037 自強分部' },
      { 6965: '0048 美崙分部' }
    ],
    270: [{ 6966: '0019 瑞穗鄉農會' }, { 6967: '0020 富源分部' }],
    271: [{ 6968: '0010 玉溪地區農會' }, { 6969: '0021 松浦分部' }],
    272: [
      { 6970: '0011 鳳榮地區農會' },
      { 6971: '0022 萬榮分部' },
      { 6972: '0033 林榮分部' }
    ],
    273: [
      { 6973: '0012 光豐地區農會' },
      { 6974: '0023 豐濱分部' },
      { 6975: '0034 大富分部' }
    ],
    274: [
      { 6976: '0016 苗栗市農會' },
      { 6977: '0027 南苗分部' },
      { 6978: '0038 北苗分部' },
      { 6979: '0049 嘉盛分部' }
    ],
    275: [
      { 6989: '0010 頭份市農會' },
      { 6990: '0021 上街分部' },
      { 6991: '0032 珊湖分部' },
      { 6992: '0043 尖山分部' },
      { 6993: '0054 中山分部' }
    ],
    276: [
      { 6994: '0011 竹南鎮農會' },
      { 6995: '0022 中港分部' },
      { 6996: '0033 大埔分部' },
      { 6997: '0044 龍鳳分部' }
    ],
    277: [{ 6998: '0012 通霄鎮農會' }, { 6999: '0023 白沙屯分部' }],
    278: [
      { 7000: '0013 苑裡鎮農會' },
      { 7001: '0024 山腳分部' },
      { 7002: '0035 社苓分部' }
    ],
    279: [{ 7008: '0010 後龍鎮農會' }, { 7009: '0021 新港分部' }],
    280: [{ 7010: '0011 卓蘭鎮農會' }],
    281: [{ 7011: '0012 西湖鄉農會' }, { 7012: '0023 五湖分部' }],
    282: [
      { 7020: '0014 公館鄉農會' },
      { 7021: '0025 鶴岡分部' },
      { 7022: '0036 福基分部' },
      { 7023: '0047 五谷分部' }
    ],
    283: [
      { 7024: '0015 銅鑼鄉農會' },
      { 7025: '0026 中平分部' },
      { 7026: '0037 新盛隆分部' }
    ],
    284: [{ 7027: '0016 三義鄉農會' }, { 7028: '0027 鯉魚分部' }],
    285: [
      { 7029: '0010 造橋鄉農會' },
      { 7030: '0021 朝陽分部' },
      { 7031: '0032 大西分部' },
      { 7032: '0043 龍昇分部' },
      { 7033: '0054 豐湖分部' }
    ],
    286: [
      { 7034: '0011 南庄鄉農會' },
      { 7035: '0022 田美分部' },
      { 7036: '0033 南富分部' }
    ],
    287: [{ 7037: '0013 獅潭鄉農會' }, { 7038: '0024 汶水分部' }],
    288: [{ 7039: '0014 頭屋鄉農會' }, { 7040: '0025 明德分部' }],
    289: [{ 7041: '0015 三灣鄉農會' }],
    290: [
      { 7042: '0016 大湖地區農會' },
      { 7043: '0027 清安分部' },
      { 7044: '0038 新開分部' },
      { 7045: '0049 南湖分部' },
      { 7046: '0050 大湖分部' }
    ],
    291: [
      { 7047: '0019 關西鎮農會' },
      { 7048: '0020 石光分部' },
      { 7049: '0031 錦山分部' }
    ],
    292: [
      { 7050: '0013 新埔鎮農會' },
      { 7051: '0024 照門分部' },
      { 7052: '0035 田新分部' }
    ],
    293: [
      { 7053: '0014 竹北市農會' },
      { 7054: '0025 六家分部' },
      { 7055: '0036 豐田分部' },
      { 7056: '0047 鳳岡分部' },
      { 7057: '0058 高鐵分部' }
    ],
    294: [
      { 7058: '0015 湖口鄉農會' },
      { 7059: '0026 中興分部' },
      { 7060: '0037 客家樓分部' }
    ],
    295: [
      { 7061: '0016 芎林鄉農會' },
      { 7062: '0027 秀湖分部' },
      { 7063: '0038 石潭分部' },
      { 7064: '0049 上山分部' }
    ],
    296: [
      { 7065: '0017 寶山鄉農會' },
      { 7066: '0028 新城分部' },
      { 7067: '0039 寶山分部' }
    ],
    297: [{ 7068: '0018 峨眉鄉農會' }, { 7069: '0029 富興分部' }],
    298: [{ 7070: '0019 北埔鄉農會' }],
    299: [
      { 7071: '0010 竹東地區農會' },
      { 7072: '0021 竹中分部' },
      { 7073: '0032 二重分部' },
      { 7074: '0043 東寧分部' },
      { 7075: '0054 五峰分部' }
    ],
    300: [
      { 7076: '0011 橫山地區農會' },
      { 7077: '0022 橫山分部' },
      { 7078: '0033 尖石分部' }
    ],
    301: [{ 7079: '0012 新豐鄉農會' }],
    302: [
      { 7080: '0016 新竹市農會信用部' },
      { 7081: '0027 三姓橋分部' },
      { 7082: '0038 內湖分部' },
      { 7083: '0049 民權分部' },
      { 7084: '0050 樹林頭分部' },
      { 7085: '0061 光復分部' },
      { 7086: '0072 新興分部' },
      { 7087: '0083 虎林分部' },
      { 7088: '0094 光華分部' }
    ],
    303: [
      { 7089: '0012 田尾鄉農會' },
      { 7090: '0023 溪畔分部' },
      { 7091: '0034 福田分部' },
      { 7092: '0045 海豐分部' }
    ],
    304: [
      { 7093: '0012 北投區農會' },
      { 7094: '0023 石牌分部' },
      { 7095: '0034 關渡分部' },
      { 7096: '0056 立農分部' },
      { 7097: '0067 大屯分部' },
      { 7098: '0078 福興分部' }
    ],
    305: [
      { 7099: '0013 士林區農會' },
      { 7100: '0024 文林分部' },
      { 7101: '0035 社子分部' },
      { 7102: '0046 天母分部' },
      { 7103: '0068 陽明山分部' },
      { 7104: '0079 延平分部' }
    ],
    306: [
      { 7105: '0014 內湖區農會' },
      { 7106: '0025 潭美分部' },
      { 7107: '0036 東湖分部' },
      { 7108: '0047 西湖分部' },
      { 7109: '0058 民權分部' }
    ],
    307: [{ 7110: '0015 南港區農會' }, { 7111: '0026 中研分部' }],
    308: [{ 7112: '0016 木柵區農會' }, { 7113: '0027 木新分部' }],
    309: [{ 7114: '0017 景美區農會' }],
    316: [{ 7124: '0017 台北分行' }],
    317: [{ 7125: '8881 臺北分行' }]
  }

  return {
    data
  }
}
export default useBankExts
