<template>
  <div class="content-page">
    <div class="container">

      <div class="row">
        <div class="col-lg-12">
          <div class="card card-block card-stretch card-height blog blog-detail">
            <div class="card-header d-flex justify-content-center">
              <div class="header-title ">
                <h5 class="card-title text-success">{{$t('label.dedicatedApplicationPageForCreatorPaymentFlow')}}</h5>
              </div>
            </div>
            <div class="card-body">

              <div class="row">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center" style="flex-wrap: wrap;align-content: center;">
                  <img src="@/assets/images/special-store/paying-pay.png" class="rounded suer-l-img" alt="Responsive image">
                </div>
                <div class="col-sm-12 col-lg-6 justify-content-left">
                  <div>
                    <h1 class="title">{{$t('label.thirdPartyPaymentCollectionAndPayment')}}</h1>
                    <p>{{$t('label.toEnsureTransactionSecurityPleaseFillInCarefully')}}</p>
                  </div>
                  <h6 class="text-danger">{{$t('label.necessaryDocumentsForPersonalApplication')}}</h6>
                  <div class="row note">
                    <div class="col-md-12">
                      <ul>
                        <li>{{$t('label.photocopyOfFrontAndBackOfIDCard')}}</li>
                        <li>{{$t('label.secondIDDriversLicenseWithPhotoOrHealthInsuranceCardChooseOne')}}</li>
                        <li>{{$t('label.copyOfBankPassbookCover')}}</li>
                        <li>{{$t('label.4BusinessPhotos')}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 d-flex justify-content-center">
                  <img src="@/assets/images/line-qrcode.png" class="rounded ">
                </div>
                <div class="col-sm-12 d-flex justify-content-center">
                  <p class="mb-0 text-danger">{{$t('label.contactServicePersonnelToAssistYou')}}</p>
                </div>
                <hr>
                <div class="titlebar">
                  <h2>
                    <i class="fas fa-store"></i> {{$t('label.specialStore')}}
                  </h2>
                </div>
                <hr>
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="zh_tw_name">{{$t('label.chineseName')}}</label>
                        <input type="text" v-model="form.zh_tw_name" :class="{ 'is-invalid':  v$.form.zh_tw_name.$dirty && !v$.form.zh_tw_name.$pending && v$.form.zh_tw_name.$invalid }" class="form-control mb-0"
                          id="zh_tw_name" name="zh_tw_name" />
                        <div v-if="( v$.form.zh_tw_name.$dirty && !v$.form.zh_tw_name.$pending && v$.form.zh_tw_name.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="en_us_name">{{$t('label.englishName')}}</label>
                        <input type="text" v-model="form.en_us_name" :class="{ 'is-invalid':  v$.form.en_us_name.$dirty && !v$.form.en_us_name.$pending && v$.form.en_us_name.$invalid }" class="form-control mb-0"
                          id="en_us_name" name="en_us_name" />
                        <div v-if="( v$.form.en_us_name.$dirty && !v$.form.en_us_name.$pending && v$.form.en_us_name.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="applicant_name">{{$t('label.applicantName')}}</label>
                        <input type="text" v-model="form.applicant_name" :class="{ 'is-invalid':  v$.form.applicant_name.$dirty && !v$.form.applicant_name.$pending && v$.form.applicant_name.$invalid }"
                          class="form-control mb-0" id="applicant_name" name="applicant_name" />
                        <div v-if="( v$.form.applicant_name.$dirty && !v$.form.applicant_name.$pending && v$.form.applicant_name.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="applicant_id">{{$t('label.ApplicantsIdentityCardPassportNumber')}}</label>
                        <input type="text" v-model="form.applicant_id" :class="{ 'is-invalid':  v$.form.applicant_id.$dirty && !v$.form.applicant_id.$pending && v$.form.applicant_id.$invalid }" class="form-control mb-0"
                          id="applicant_id" name="applicant_id" />
                        <div v-if="( v$.form.applicant_id.$dirty && !v$.form.applicant_id.$pending && v$.form.applicant_id.$invalid)" class="invalid-feedback">
                          {{ $t('messages.required') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="applicant_phone">{{$t('label.applicantPhoneNumber')}}</label>
                        <input type="text" v-model="form.applicant_phone" :class="{ 'is-invalid':  v$.form.applicant_phone.$dirty && !v$.form.applicant_phone.$pending && v$.form.applicant_phone.$invalid }"
                          class="form-control mb-0" id="applicant_phone" name="applicant_phone" />
                        <div v-if="( v$.form.applicant_phone.$dirty && !v$.form.applicant_phone.$pending && v$.form.applicant_phone.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="applicant_cellphone">{{$t('label.applicantsMobilePhoneNumber')}}</label>
                        <input type="text" v-model="form.applicant_cellphone"
                          :class="{ 'is-invalid':  v$.form.applicant_cellphone.$dirty && !v$.form.applicant_cellphone.$pending && v$.form.applicant_cellphone.$invalid }" class="form-control mb-0" id="applicant_cellphone"
                          name="applicant_cellphone" />
                        <div v-if="( v$.form.applicant_cellphone.$dirty && !v$.form.applicant_cellphone.$pending && v$.form.applicant_cellphone.$invalid)" class="invalid-feedback">
                          {{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="applicant_email">{{$t('label.applicantEmail')}}</label>
                        <input type="text" v-model="form.applicant_email" :class="{ 'is-invalid':  v$.form.applicant_email.$dirty && !v$.form.applicant_email.$pending && v$.form.applicant_email.$invalid }"
                          class="form-control mb-0" id="applicant_email" name="applicant_email" />
                        <div v-if="( v$.form.applicant_email.$dirty && !v$.form.applicant_email.$pending && v$.form.applicant_email.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="applicant_birthday">{{$t('label.dateOfBirth')}}</label>
                        <input type="date" v-model="form.applicant_birthday" :class="{ 'is-invalid':  v$.form.applicant_birthday.$dirty && !v$.form.applicant_birthday.$pending && v$.form.applicant_birthday.$invalid }"
                          class="form-control mb-0" id="applicant_birthday" name="applicant_birthday" />
                        <div v-if="( v$.form.applicant_birthday.$dirty && !v$.form.applicant_birthday.$pending && v$.form.applicant_birthday.$invalid)" class="invalid-feedback">
                          {{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="applicant_zip_code">{{$t('label.applicantsHouseholdRegistrationZipCode')}}</label>
                        <input type="text" v-model="form.applicant_zip_code" :class="{ 'is-invalid':  v$.form.applicant_zip_code.$dirty && !v$.form.applicant_zip_code.$pending && v$.form.applicant_zip_code.$invalid }"
                          class="form-control mb-0" id="applicant_zip_code" name="applicant_zip_code" maxlength="3" />
                        <div v-if="( v$.form.applicant_zip_code.$dirty && !v$.form.applicant_zip_code.$pending && v$.form.applicant_zip_code.$invalid)" class="invalid-feedback">
                          {{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="applicant_address">{{$t('label.applicantsDomicileAddress')}}</label>
                        <input type="text" v-model="form.applicant_address" :class="{ 'is-invalid':  v$.form.applicant_address.$dirty && !v$.form.applicant_address.$pending && v$.form.applicant_address.$invalid }"
                          class="form-control mb-0" id="applicant_address" name="applicant_address" />
                        <div v-if="( v$.form.applicant_address.$dirty && !v$.form.applicant_address.$pending && v$.form.applicant_address.$invalid)" class="invalid-feedback">
                          {{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="real_zip_code">{{$t('label.businessZipCode')}}</label>
                        <input type="text" v-model="form.real_zip_code" :class="{ 'is-invalid':  v$.form.real_zip_code.$dirty && !v$.form.real_zip_code.$pending && v$.form.real_zip_code.$invalid }"
                          class="form-control mb-0" id="real_zip_code" name="real_zip_code" maxlength="3" />
                        <div v-if="( v$.form.real_zip_code.$dirty && !v$.form.real_zip_code.$pending && v$.form.real_zip_code.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="real_address">{{$t('label.businessAddress')}}</label>
                        <input type="text" v-model="form.real_address" :class="{ 'is-invalid':  v$.form.real_address.$dirty && !v$.form.real_address.$pending && v$.form.real_address.$invalid }" class="form-control mb-0"
                          id="real_address" name="real_address" />
                        <div v-if="( v$.form.real_address.$dirty && !v$.form.real_address.$pending && v$.form.real_address.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="service_mail">{{$t('label.serviceEmail')}}</label>
                        <input type="email" v-model="form.service_mail" :class="{ 'is-invalid':  v$.form.service_mail.$dirty && !v$.form.service_mail.$pending && v$.form.service_mail.$invalid }" class="form-control mb-0"
                          id="service_mail" name="service_mail" />
                        <div v-if="( v$.form.service_mail.$dirty && !v$.form.service_mail.$pending && v$.form.service_mail.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="service_phone">{{$t('label.serviceLine')}}</label>
                        <input type="text" v-model="form.service_phone" :class="{ 'is-invalid':  v$.form.service_phone.$dirty && !v$.form.service_phone.$pending && v$.form.service_phone.$invalid }"
                          class="form-control mb-0" id="service_phone" name="service_phone" />
                        <div v-if="( v$.form.service_phone.$dirty && !v$.form.service_phone.$pending && v$.form.service_phone.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="titlebar">
                      <h2>
                        <i class="fas fa-store"></i> {{$t('label.businessPhotosFour')}}
                      </h2>
                    </div>
                    <div class="col-md-6">
                      <h4 class="text-success pb-3 pt-3">{{$t('label.addressNumber')}}</h4>
                      <div class="">
                        <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                          <img v-if="form.business_photos_4_img[0].size === 0" :src="business_photos_4_imgImg[0]" class="rounded suer-l-img">
                          <img v-else :src="prefix + form.business_photos_4_img[0].data" class="rounded suer-l-img">
                        </div>
                        <div class="col-sm-12 px-lg-5  d-flex justify-content-center">
                          <vue-core-image-upload @click="cLoder" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{'authorization':token}"
                            @imagechanged="imagechanged({'type':'business_photos_4_img'})" :data=" { 'type': 'business_photos_4_img','index':0 }" :max-file-size="commomData.uploadOption.maxFileSize"
                            :url="commomData.uploadOption.MyPayUrl" :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                            <button type="button" class="shadow btn btn-primary rounded-pill mb-3 mt-3 me-1">
                              <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                              ＋</button>
                          </vue-core-image-upload>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h4 class="text-success pb-3 pt-3">{{$t('label.signboardOrOfficeSpace')}}</h4>
                      <div class="">
                        <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                          <img v-if="form.business_photos_4_img[1].size === 0" :src="business_photos_4_imgImg[1]" class="rounded suer-l-img">
                          <img v-else :src="prefix + form.business_photos_4_img[1].data" class="rounded suer-l-img">
                        </div>
                        <div class="col-sm-12 px-lg-5  d-flex justify-content-center">
                          <vue-core-image-upload @click="cLoder" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{'authorization':token}"
                            @imagechanged="imagechanged({'type':'business_photos_4_img'})" :data=" { 'type': 'business_photos_4_img','index':1 }" :max-file-size="commomData.uploadOption.maxFileSize"
                            :url="commomData.uploadOption.MyPayUrl" :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                            <button type="button" class="shadow btn btn-primary rounded-pill mb-3 mt-3 me-1">
                              <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                              ＋</button>
                          </vue-core-image-upload>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h4 class="text-success pb-3 pt-3">{{$t('label.businessItemOrPriceList')}}</h4>
                      <div class="">
                        <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                          <img v-if="form.business_photos_4_img[2].size === 0" :src="business_photos_4_imgImg[2]" class="rounded suer-l-img">
                          <img v-else :src="prefix + form.business_photos_4_img[2].data" class="rounded suer-l-img"> </div>
                        <div class="col-sm-12 px-lg-5  d-flex justify-content-center">
                          <vue-core-image-upload @click="cLoder" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{'authorization':token}"
                            @imagechanged="imagechanged({'type':'business_photos_4_img'})" :data=" { 'type': 'business_photos_4_img','index':2 }" :max-file-size="commomData.uploadOption.maxFileSize"
                            :url="commomData.uploadOption.MyPayUrl" :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                            <button type="button" class="shadow btn btn-primary rounded-pill mb-3 mt-3 me-1">
                              <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                              ＋</button>
                          </vue-core-image-upload>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h4 class="text-success pb-3 pt-3">{{$t('label.photosOfBusinessPremises')}}</h4>
                      <div class="">
                        <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                          <img v-if="form.business_photos_4_img[3].size === 0" :src="business_photos_4_imgImg[3]" class="rounded suer-l-img">
                          <img v-else :src="prefix + form.business_photos_4_img[3].data" class="rounded suer-l-img"> </div>
                        <div class="col-sm-12 px-lg-5  d-flex justify-content-center">
                          <vue-core-image-upload @click="cLoder" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{'authorization':token}"
                            @imagechanged="imagechanged({'type':'business_photos_4_img'})" :data=" { 'type': 'business_photos_4_img','index':3 }" :max-file-size="commomData.uploadOption.maxFileSize"
                            :url="commomData.uploadOption.MyPayUrl" :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                            <button type="button" class="shadow btn btn-primary rounded-pill mb-3 mt-3 me-1">
                              <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                              ＋</button>
                          </vue-core-image-upload>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="titlebar">
                      <h2>
                        <i class="fas fa-user"></i> {{$t('label.applicantFileUpload')}}
                      </h2>
                    </div>
                    <hr>
                    <h4 class="text-success pb-3">{{$t('label.iDCardInformation')}}</h4>
                    <div class="col-md-6">
                      <div class="">
                        <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                          <img v-if="form.principal_id_front_img.size === 0" :src="principal_id_front_imgImg" class="rounded suer-l-img">
                          <img v-else :src="prefix + form.principal_id_front_img.data" class="rounded suer-l-img">
                        </div>
                        <div class="col-sm-12 px-lg-5  d-flex justify-content-center">
                          <vue-core-image-upload @click="cLoder" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{'authorization':token}"
                            @imagechanged="imagechanged({'type':'principal_id_front_img'})" :data=" { 'type': 'principal_id_front_img' }" :max-file-size="commomData.uploadOption.maxFileSize"
                            :url="commomData.uploadOption.MyPayUrl" :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                            <button type="button" class="shadow btn btn-primary rounded-pill mb-3 mt-3 me-1">
                              <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                              ＋</button>
                          </vue-core-image-upload>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="">
                        <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                          <img v-if="form.principal_id_obverse_img.size === 0" :src="principal_id_obverse_imgImg" class="rounded suer-l-img">
                          <img v-else :src="prefix + form.principal_id_obverse_img.data" class="rounded suer-l-img">
                        </div>
                        <div class="col-sm-12 px-lg-5  d-flex justify-content-center">
                          <vue-core-image-upload @click="cLoder" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{'authorization':token}"
                            @imagechanged="imagechanged({'type':'principal_id_obverse_img'})" :data=" { 'type': 'principal_id_obverse_img' }" :max-file-size="commomData.uploadOption.maxFileSize"
                            :url="commomData.uploadOption.MyPayUrl" :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                            <button type="button" class="shadow btn btn-primary rounded-pill mb-3 mt-3 me-1">
                              <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                              ＋</button>
                          </vue-core-image-upload>
                        </div>
                      </div>
                    </div>
                    <h4 class="text-success pb-3">{{$t('label.secondDocumentInformation')}}</h4>
                    <div class="col-md-12">
                      <div class="">
                        <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                          <img v-if="form.principal_driverlicense_healthidcard_img.size === 0" :src="principal_driverlicense_healthidcard_imgImg" class="rounded suer-l-img">
                          <img v-else :src="prefix + form.principal_driverlicense_healthidcard_img.data" class="rounded suer-l-img"> </div>
                        <div class="col-sm-12 px-lg-5  d-flex justify-content-center">
                          <vue-core-image-upload @click="cLoder" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{'authorization':token}"
                            @imagechanged="imagechanged({'type':'principal_driverlicense_healthidcard_img'})" :data=" { 'type': 'principal_driverlicense_healthidcard_img' }"
                            :max-file-size="commomData.uploadOption.maxFileSize" :url="commomData.uploadOption.MyPayUrl" :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                            <button type="button" class="shadow btn btn-primary rounded-pill mb-3 mt-3 me-1">
                              <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                              ＋</button>
                          </vue-core-image-upload>
                        </div>
                      </div>
                    </div>
                    <div class="titlebar">
                      <h2>
                        <i class="far fa-file-image"></i> {{$t('label.appropriationAccount')}}
                      </h2>
                    </div>
                    <hr>
                    <div class="col-md-12">
                      <div class="">
                        <div class="col-sm-12 px-lg-5 d-flex justify-content-center">
                          <img v-if="form.bank_passbook_img.size === 0" :src="bank_passbook_imgImg" class="rounded suer-l-img">
                          <img v-else :src="prefix + form.bank_passbook_img.data" class="rounded suer-l-img">
                        </div>
                        <div class="col-sm-12 px-lg-5  d-flex justify-content-center">
                          <vue-core-image-upload @click="cLoder" :crop="false" @imageuploaded="imageuploaded" @imageuploading="imageuploading" :headers="{'authorization':token}"
                            @imagechanged="imagechanged({'type':'bank_passbook_img'})" :data=" { 'type': 'bank_passbook_img' }" :max-file-size="commomData.uploadOption.maxFileSize" :url="commomData.uploadOption.MyPayUrl"
                            :inputAccept="commomData.uploadOption.accept" :credentials="false" inputOfFile="file" text=".">
                            <button type="button" class="shadow btn btn-primary rounded-pill mb-3 mt-3 me-1">
                              <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                              ＋</button>
                          </vue-core-image-upload>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="allot_bank_idname">{{$t('label.bnkAccountName')}}</label>
                        <input type="email" v-model="form.allot_bank_idname" :class="{ 'is-invalid':  v$.form.allot_bank_idname.$dirty && !v$.form.allot_bank_idname.$pending && v$.form.allot_bank_idname.$invalid }"
                          class="form-control mb-0" id="allot_bank_idname" name="allot_bank_idname" />
                        <div v-if="( v$.form.allot_bank_idname.$dirty && !v$.form.allot_bank_idname.$pending && v$.form.allot_bank_idname.$invalid)" class="invalid-feedback">
                          {{ $t('messages.required') }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="allot_bank_id">{{$t('label.bankAccount')}}</label>
                        <input type="email" v-model="form.allot_bank_id" :class="{ 'is-invalid':  v$.form.allot_bank_id.$dirty && !v$.form.allot_bank_id.$pending && v$.form.allot_bank_id.$invalid }"
                          class="form-control mb-0" id="allot_bank_id" name="allot_bank_id" />
                        <div v-if="( v$.form.allot_bank_id.$dirty && !v$.form.allot_bank_id.$pending && v$.form.allot_bank_id.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}</div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="allot_bank_id">{{$t('label.bank')}}</label>
                        <select v-model="form.allot_bank_name" class="form-select" @change="selectBank"
                          :class="{ 'is-invalid':  v$.form.allot_bank_name.$dirty && !v$.form.allot_bank_name.$pending && v$.form.allot_bank_name.$invalid }">
                          <option value="">{{$t('label.pleaseSelectABank')}}</option>
                          <option v-for="(r, index) in bank.data" v-bind:key="index" :value="Object.keys(r)[0]">
                            {{Object.values(r)[0]}}
                          </option>
                        </select>
                        <div v-if="( v$.form.allot_bank_name.$dirty && !v$.form.allot_bank_name.$pending && v$.form.allot_bank_name.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="allot_bank_ext">{{$t('label.bankBranches')}}</label>
                        <select :disabled="showExts" v-model="form.allot_bank_ext" class="form-select">
                          <option value="">{{$t('label.pleaseSelectABranch')}}</option>
                          <option v-for="(r, index) in blankExtsFilter" v-bind:key="index" :value="Object.keys(r)[0]">
                            {{Object.values(r)[0]}}
                          </option>
                        </select>
                        <div v-if="( v$.form.allot_bank_ext.$dirty && !v$.form.allot_bank_ext.$pending && v$.form.allot_bank_ext.$invalid)" class="invalid-feedback">{{ $t('messages.required') }}</div>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="d-flex justify-content-center">
                        <button :disabled="commomData.btnLoader" @click="onSubmit()" type="button" style="width: 50%;" class="shadow btn btn-primary rounded-pill mb-3 me-1">
                          <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                          {{$t('button.send')}}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { createAgent } from '@/api/myPay'
import { useBank } from '@/utils/useBank'
import { useBankExts } from '@/utils/useBankExts'

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import VueCoreImageUpload from 'vue-core-image-upload'
import { useI18n } from 'vue-i18n'
export default {
  name: 'SpecialStore',
  components: {
    'vue-core-image-upload': VueCoreImageUpload
  },
  setup() {
    const { t } = useI18n()
    const v$ = useVuelidate()
    const bank = useBank()
    const bankExts = useBankExts()

    return { v$, bank, bankExts, t }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  data() {
    return {
      prefix: 'data:image/png;base64,',
      showExts: true,
      blankExtsFilter: [],
      commomData: commomData(),
      principal_id_front_imgImg: require('@/assets/images/id-card/IDcard-1.jpeg'),
      principal_id_obverse_imgImg: require('@/assets/images/id-card/IDcard-2.jpeg'),
      principal_driverlicense_healthidcard_imgImg: require('@/assets/images/id/ID2.jpeg'),
      business_photos_4_imgImg: [
        require('@/assets/images/business/1.png'),
        require('@/assets/images/business/2.png'),
        require('@/assets/images/business/3.png'),
        require('@/assets/images/business/4.png')
      ],
      bank_passbook_imgImg: require('@/assets/images/passbook/Passbook-sample.jpeg'),
      form: {
        allot_bank_name: '', //撥款預設行行庫名
        allot_bank_ext: '', //撥款預設銀行分行
        allot_bank_idname: '', //撥款預設銀行戶名
        allot_bank_id: '', //撥款預設銀行帳號
        service_mail: '', //客服 E-mail - vuex 帶入 OK
        service_phone: '', //服務電話或 - vuex 帶入 OK
        zh_tw_name: '', //-OK
        en_us_name: '', //-OK
        applicant_name: '', //申請人姓名 -OK
        applicant_id: '', //申請人身分證/統一證號/護照號碼 -OK
        applicant_birthday: '', //申請人生日(格式YYYYMMDD) -OK
        applicant_zip_code: '', //申請人戶籍地址-郵遞區號(主要辨識) -OK
        applicant_address: '', //申請人戶籍地址 (必須排除縣市與鄉鎮市區資訊)  -OK
        applicant_phone: '', //申請人電話 -OK
        applicant_cellphone: '', //申請人手機 -OK
        applicant_email: '', //申請人 EMail -OK
        real_zip_code: '', //實際營業郵遞區號  -OK
        real_address: '', //實際營業地址(必須排除縣市與鄉鎮市區資訊) -OK
        principal_id_front_img: {
          name: '身分證正面.png',
          size: 0,
          type: 'image/png',
          data: ''
        },
        principal_id_obverse_img: {
          name: '身分證反面.png',
          size: 0,
          type: 'image/png',
          data: ''
        },
        principal_driverlicense_healthidcard_img: {
          name: '申請人第二證件.png',
          size: 0,
          type: 'image/png',
          data: ''
        },
        business_photos_4_img: [
          {
            size: 0,
            type: 'image/png',
            data: '',
            name: '營業照片第一張.png'
          },
          {
            size: 0,
            type: 'image/png',
            data: '',
            name: '營業照片第二張.png'
          },
          {
            size: 0,
            type: 'image/png',
            data: '',
            name: '營業照片第三張.png'
          },
          {
            size: 0,
            type: 'image/png',
            data: '',
            name: '營業照片第四張.png'
          }
        ],
        bank_passbook_img: {
          name: '銀行存摺封面影本.png',
          size: 0,
          type: 'image/png',
          data: ''
        }
      }
    }
  },
  validations() {
    return {
      form: {
        zh_tw_name: { required },
        en_us_name: { required },
        applicant_name: { required },
        applicant_id: { required },
        applicant_phone: { required },
        applicant_cellphone: { required },
        applicant_email: { required, email },
        applicant_birthday: { required },

        applicant_address: { required },
        applicant_zip_code: { required },
        real_zip_code: { required },

        real_address: { required },
        service_mail: { required },
        service_phone: { required },
        allot_bank_name: { required },

        allot_bank_ext: { required },
        allot_bank_idname: { required },
        allot_bank_id: { required }
      }
    }
  },
  methods: {
    selectBank() {
      if (this.form.allot_bank_name === '') {
        this.form.allot_bank_ext = ''
        this.showExts = true
        return
      }
      this.blankExtsFilter = this.bankExts.data[this.form.allot_bank_name]
      this.showExts = false
    },
    cLoder() {
      this.commomData.btnLoader = true
      setTimeout(() => {
        this.commomData.btnLoader = false
      }, 3000)
    },
    imageuploaded(apiRes, res) {
      if (res.index === undefined) {
        this.form[res.type] = apiRes.file
      } else {
        this.form[res.type][res.index] = apiRes.file
      }
      this.commomData.btnLoader = false
    },
    imageuploading() {
      this.commomData.btnLoader = true
    },
    imagechanged(res) {
      console.log(res)
    },
    errorhandle(res) {
      alert(res)
    },
    // 這個函數用來檢驗台灣身分證字號是否正確
    isValidTaiwaneseId(idStr) {
      const userid = idStr
      const reg_1 = /^[A-Z]{1}[1-2]{1}[0-9]{8}$/ // 身份證的正規表示式
      const reg_2 = /^[A-Z]{1}[A-Z]{1}[0-9]{8}$/ // 舊外籍生，居留證號的正規表示式
      const s = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' // 把A取代成10,把B取代成11...的作法
      const ct = [
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '34',
        '18',
        '19',
        '20',
        '21',
        '22',
        '35',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '32',
        '30',
        '31',
        '33'
      ]

      if (reg_1.test(userid)) {
        let i = s.indexOf(userid.charAt(0))
        let tempuserid = ct[i] + userid.substr(1, 9) //若此身份證號若是A123456789=>10123456789
        let num = tempuserid.charAt(0) * 1
        for (i = 1; i <= 9; i++) {
          num = num + tempuserid.charAt(i) * (10 - i)
        }
        num += tempuserid.charAt(10) * 1
        if (num % 10 == 0) {
          return true
        } else {
          return false
        }
      } else if (reg_2.test(userid)) {
        let i = s.indexOf(userid.charAt(0))
        let j = s.indexOf(userid.charAt(1))

        let tempuserid = ct[i] + (ct[j] % 10) + userid.substr(2, 9)

        let num = tempuserid.charAt(0) * 1
        for (i = 1; i <= 9; i++) {
          num = num + tempuserid.charAt(i) * (10 - i)
        }
        num += tempuserid.charAt(10) * 1
        // console.log(num)
        if (num % 10 == 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: this.t('label.theInformationIsNotCompleted')
        })
        return
      }
      if (!this.isValidTaiwaneseId(this.form.applicant_id)) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: '身分證字號有誤！'
        })
        return
      }
      if (!this.form.business_photos_4_img[0].size) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: this.t('label.addressHousePhoto')
        })
        return
      }
      if (!this.form.business_photos_4_img[1].size) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: this.t('label.pUploadAPhotoOfYourSign')
        })
        return
      }
      if (!this.form.business_photos_4_img[2].size) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: this.t('label.uploadBusinessItems')
        })
        return
      }
      if (!this.form.business_photos_4_img[3].size) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: this.t('label.photosOfBusinessPremises')
        })
        return
      }
      if (!this.form.principal_id_front_img.size) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: this.t('label.IdCardPositiveInformation')
        })
        return
      }
      if (!this.form.principal_id_obverse_img.size) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: this.t('label.informationOnTheBackOfTheIDCard')
        })
        return
      }
      if (!this.form.principal_driverlicense_healthidcard_img.size) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: this.t('label.theSecondDocumentInformation')
        })
        return
      }
      if (!this.form.bank_passbook_img.size) {
        this.$swal.mixin().fire({
          icon: 'error',
          text: this.t('label.bankBookCover')
        })
        return
      }
      this.commomData.btnLoader = true
      this.form.applicant_birthday = this.form.applicant_birthday.replace(
        /-/g,
        ''
      ) // 2023-12-05 to 20231205
      console.log(this.form)
      createAgent(this.form)
        .then(res => {
          console.log('res', res)
          this.$swal.mixin().fire({
            icon: 'success',
            text: this.t('label.moderated')
          })
          this.commomData.btnLoader = false
          setTimeout(() => {
            this.$router.push({ name: 'social.list' })
          }, 3000)
        })
        .catch(err => {
          console.log('err', err)
          const originalDate = this.form.applicant_birthday
          this.form.applicant_birthday =
            originalDate.substring(0, 4) +
            '-' +
            originalDate.substring(4, 6) +
            '-' +
            originalDate.substring(6, 8)

          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            text: err?.response?.data?.message
          })
        })
    }
  },
  mounted() {
    this.form.applicant_phone = this.user.phone
    this.form.applicant_cellphone = this.user.phone
    this.form.service_phone = this.user.phone
    this.form.service_mail = this.user.email
    this.form.applicant_email = this.user.email
  }
}
</script>
<style lang="css">
.suer-l-img {
  width: 330px;
  height: 220px;
}
</style>