export function useBank() {
  const data = [
    { 1: '000 中央銀行' },
    { 2: '004 臺灣銀行' },
    { 3: '005 土地銀行' },
    { 4: '006 合作金庫' },
    { 5: '007 第一銀行' },
    { 6: '008 華南銀行' },
    { 7: '009 彰化銀行' },
    { 8: '011 上海銀行' },
    { 9: '012 台北富邦' },
    { 10: '013 國泰世華' },
    { 11: '016 高雄銀行' },
    { 12: '017 兆豐銀行' },
    { 13: '018 農業金庫' },
    { 14: '020 日商瑞穗銀行' },
    { 15: '021 花旗銀行' },
    { 16: '022 美國銀行' },
    { 17: '023 盤谷銀行' },
    { 18: '025 首都銀行' },
    { 19: '029 新加坡大華銀行' },
    { 20: '030 美商道富銀行' },
    { 316: '037 法商法國興業銀行台北分行' },
    { 21: '039 澳盛銀行' },
    { 22: '048 王道銀行' },
    { 23: '050 臺灣企銀' },
    { 24: '052 渣打銀行' },
    { 25: '053 台中銀行' },
    { 26: '054 京城銀行' },
    { 27: '060 兆豐票券' },
    { 28: '061 中華票券' },
    { 29: '062 國際票券' },
    { 30: '066 萬通票券' },
    { 31: '072 德意志銀行' },
    { 32: '075 東亞銀行' },
    { 33: '076 摩根大通銀行' },
    { 34: '081 滙豐銀行' },
    { 35: '082 巴黎銀行' },
    { 36: '085 新加坡華僑銀行' },
    { 37: '086 東方匯理銀行' },
    { 38: '092 瑞士銀行' },
    { 39: '093 安智銀行' },
    { 40: '098 三菱東京日聯銀行' },
    { 41: '101 瑞興銀行' },
    { 42: '102 華泰銀行' },
    { 43: '103 新光銀行' },
    { 44: '104 台北五信' },
    { 45: '108 陽信銀行' },
    { 46: '114 基隆一信' },
    { 47: '115 基隆二信' },
    { 48: '118 板信銀行' },
    { 49: '119 淡水一信' },
    { 50: '120 淡水信合社' },
    { 51: '124 宜蘭信合社' },
    { 52: '127 桃園信合社' },
    { 53: '130 新竹一信' },
    { 54: '132 新竹三信' },
    { 55: '146 台中二信' },
    { 56: '147 三信銀行' },
    { 57: '158 彰化一信' },
    { 58: '161 彰化五信' },
    { 59: '162 彰化六信' },
    { 60: '163 彰化十信' },
    { 61: '165 鹿港信合社' },
    { 62: '178 嘉義三信' },
    { 63: '188 台南三信' },
    { 64: '204 高雄三信' },
    { 65: '215 花蓮一信' },
    { 66: '216 花蓮二信' },
    { 67: '222 澎湖一信' },
    { 68: '223 澎湖二信' },
    { 69: '224 金門信合社' },
    { 70: '321 三井住友銀行' },
    { 317: '326 西班牙商西班牙對外銀行臺北分行' },
    { 71: '372 大慶票券' },
    { 72: '380 中國銀行' },
    { 73: '381 交通銀行' },
    { 74: '382 中國建設銀行' },
    { 318: '461 票交所四六一銀行(測試)' },
    { 146: '501 宜蘭縣蘇澳區漁會' },
    { 147: '502 宜蘭縣頭城區漁會' },
    { 75: '503 基隆漁會' },
    { 76: '504 瑞芳/萬里漁會' },
    { 77: '505 頭城/蘇澳漁會' },
    { 78: '506 桃園漁會' },
    { 79: '507 新竹漁會' },
    { 148: '508 通苑區漁會' },
    { 141: '510 農金資中心' },
    { 149: '511 彰化區漁會' },
    { 80: '512 雲林區漁會' },
    { 150: '513 新北市瑞芳區漁會' },
    { 151: '514 農金資訊所屬會員' },
    { 81: '515 嘉義區漁會' },
    { 152: '516 農金資訊所屬會員' },
    { 82: '517 南市區漁會' },
    { 83: '518 南縣區漁會' },
    { 153: '519 農金資訊所屬會員' },
    { 84: '520 小港區漁會；高雄區漁會' },
    { 85: '521 彌陀/永安/興達港/林園區漁會' },
    { 86: '523 東港/琉球/林邊區漁會' },
    { 87: '524 新港區漁會' },
    { 88: '525 澎湖區漁會' },
    { 145: '526 南農中心' },
    { 154: '538 農金資訊所屬會員' },
    { 155: '541 農金資訊所屬會員' },
    { 156: '542 農金資訊所屬會員' },
    { 157: '547 農金資訊所屬會員' },
    { 158: '549 農金資訊所屬會員' },
    { 159: '551 農金資訊所屬會員' },
    { 160: '552 農金資訊所屬會員' },
    { 161: '556 農金資訊所屬會員' },
    { 162: '557 農金資訊所屬會員' },
    { 163: '558 農金資訊所屬會員' },
    { 164: '559 農金資訊所屬會員' },
    { 165: '561 農金資訊所屬會員' },
    { 166: '562 農金資訊所屬會員' },
    { 167: '564 農金資訊所屬會員' },
    { 168: '565 農金資訊所屬會員' },
    { 169: '567 農金資訊所屬會員' },
    { 170: '568 農金資訊所屬會員' },
    { 171: '570 農金資訊所屬會員' },
    { 172: '573 農金資訊所屬會員' },
    { 173: '574 農金資訊所屬會員' },
    { 174: '575 農金資訊所屬會員' },
    { 175: '577 農金資訊所屬會員' },
    { 176: '578 農金資訊所屬會員' },
    { 177: '579 農金資訊所屬會員' },
    { 178: '580 農金資訊所屬會員' },
    { 179: '581 農金資訊所屬會員' },
    { 180: '582 農金資訊所屬會員' },
    { 181: '583 農金資訊所屬會員' },
    { 182: '585 農金資訊所屬會員' },
    { 183: '586 農金資訊所屬會員' },
    { 184: '587 農金資訊所屬會員' },
    { 185: '588 農金資訊所屬會員' },
    { 186: '589 農金資訊所屬會員' },
    { 187: '596 農金資訊所屬會員' },
    { 188: '598 農金資訊所屬會員' },
    { 189: '599 農金資訊所屬會員' },
    { 315: '600 農金資訊股份有限公司' },
    { 190: '602 農金資訊所' },
    { 89: '603 基隆地區農會' },
    { 90: '605 高雄市農會' },
    { 91: '606 新北市農會' },
    { 92: '607 宜蘭地區農會' },
    { 93: '608 桃園地區農會' },
    { 94: '609 中華民國農會' },
    { 95: '610 新竹地區農會' },
    { 96: '611 後龍農會' },
    { 97: '612 豐原市農會；神岡鄉農會' },
    { 98: '613 名間/集集農會' },
    { 99: '614 彰化地區農會' },
    { 191: '615 農金資訊所屬會員' },
    { 100: '616 雲林地區農會' },
    { 101: '617 嘉義地區農會' },
    { 102: '618 台南地區農會' },
    { 103: '619 高雄地區農會' },
    { 104: '620 屏東地區農會' },
    { 105: '621 花蓮地區農會' },
    { 106: '622 台東地區農會' },
    { 107: '623 台北市農會' },
    { 108: '624 澎湖農會' },
    { 109: '625 台中市農會' },
    { 110: '627 連江縣農會' },
    { 192: '628 農金資訊所屬會員' },
    { 193: '629 農金資訊所屬會員' },
    { 194: '631 農金資訊所屬會員' },
    { 195: '632 農金資訊所屬會員' },
    { 196: '633 農金資訊所屬會員' },
    { 197: '635 農金資訊所屬會員' },
    { 198: '636 農金資訊所屬會員' },
    { 199: '638 農金資訊所屬會員' },
    { 200: '639 農金資訊所屬會員' },
    { 201: '642 農金資訊所屬會員' },
    { 202: '643 農金資訊所屬會員' },
    { 203: '646 農金資訊所屬會員' },
    { 204: '647 農金資訊所屬會員' },
    { 205: '649 農金資訊所屬會員' },
    { 206: '650 農金資訊所屬會員' },
    { 207: '651 農金資訊所屬會員' },
    { 208: '683 農金資訊所屬會員' },
    { 209: '685 農金資訊所屬會員' },
    { 210: '693 農金資訊所屬會員' },
    { 211: '696 農金資訊所屬會員' },
    { 212: '697 農金資訊所屬會員' },
    { 213: '698 農金資訊所屬會員' },
    { 214: '699 農金資訊所屬會員' },
    { 111: '700 中華郵政' },
    { 215: '749 農金資訊所屬會員' },
    { 216: '762 農金資訊所屬會員' },
    { 217: '763 農金資訊所屬會員' },
    { 218: '764 農金資訊所屬會員' },
    { 219: '765 農金資訊所屬會員' },
    { 220: '766 農金資訊所屬會員' },
    { 221: '767 農金資訊所屬會員' },
    { 222: '768 農金資訊所屬會員' },
    { 223: '769 農金資訊所屬會員' },
    { 224: '770 農金資訊所屬會員' },
    { 225: '771 農金資訊所屬會員' },
    { 226: '772 農金資訊所屬會員' },
    { 227: '773 農金資訊所屬會員' },
    { 228: '775 農金資訊所屬會員' },
    { 229: '776 農金資訊所屬會員' },
    { 230: '777 農金資訊所屬會員' },
    { 231: '778 農金資訊所屬會員' },
    { 232: '779 農金資訊所屬會員' },
    { 233: '780 農金資訊所屬會員' },
    { 234: '781 農金資訊所屬會員' },
    { 235: '785 農金資訊所屬會員' },
    { 236: '786 農金資訊所屬會員' },
    { 237: '787 農金資訊所屬會員' },
    { 238: '788 農金資訊所屬會員' },
    { 239: '789 農金資訊所屬會員' },
    { 240: '790 農金資訊所屬會員' },
    { 241: '791 農金資訊所屬會員' },
    { 242: '792 農金資訊所屬會員' },
    { 243: '793 農金資訊所屬會員' },
    { 244: '795 農金資訊所屬會員' },
    { 245: '796 農金資訊所屬會員' },
    { 246: '797 農金資訊所屬會員' },
    { 247: '798 農金資訊所屬會員' },
    { 248: '799 農金資訊所屬會員' },
    { 112: '803 聯邦銀行' },
    { 113: '805 遠東銀行' },
    { 114: '806 元大銀行' },
    { 115: '807 永豐銀行' },
    { 116: '808 玉山銀行' },
    { 117: '809 凱基銀行' },
    { 118: '810 星展銀行' },
    { 119: '812 台新銀行' },
    { 120: '814 大眾銀行' },
    { 121: '815 日盛銀行' },
    { 122: '816 安泰銀行' },
    { 123: '822 中國信託' },
    { 320: '823 將來銀行' },
    { 319: '824 LINE Bank' },
    { 249: '860 農金資訊所屬會員' },
    { 250: '866 農金資訊所屬會員' },
    { 251: '868 農金資訊所屬會員' },
    { 252: '869 農金資訊所屬會員' },
    { 253: '870 農金資訊所屬會員' },
    { 254: '871 農金資訊所屬會員' },
    { 255: '872 農金資訊所屬會員' },
    { 256: '874 農金資訊所屬會員' },
    { 257: '875 農金資訊所屬會員' },
    { 258: '876 農金資訊所屬會員' },
    { 259: '877 農金資訊所屬會員' },
    { 260: '878 農金資訊所屬會員' },
    { 261: '879 農金資訊所屬會員' },
    { 262: '880 農金資訊所屬會員' },
    { 263: '881 農金資訊所屬會員' },
    { 264: '882 農金資訊所屬會員' },
    { 265: '883 農金資訊所屬會員' },
    { 266: '884 農金資訊所屬會員' },
    { 267: '885 農金資訊所屬會員' },
    { 268: '886 農金資訊所屬會員' },
    { 269: '891 農金資訊所屬會員' },
    { 270: '895 農金資訊所屬會員' },
    { 271: '896 農金資訊所屬會員' },
    { 272: '897 農金資訊所屬會員' },
    { 273: '898 農金資訊所屬會員' },
    { 124: '901 大里市農會' },
    { 274: '902 農金資訊所屬會員' },
    { 125: '903 汐止農會' },
    { 126: '904 新莊農會' },
    { 275: '906 農金資訊所屬會員' },
    { 276: '907 農金資訊所屬會員' },
    { 277: '908 農金資訊所屬會員' },
    { 278: '909 農金資訊所屬會員' },
    { 127: '910 聯資中心' },
    { 128: '912 冬山農會' },
    { 279: '913 農金資訊所屬會員' },
    { 280: '914 農金資訊所屬會員' },
    { 281: '915 農金資訊所屬會員' },
    { 129: '916 草屯農會' },
    { 282: '917 農金資訊所屬會員' },
    { 283: '918 農金資訊所屬會員' },
    { 284: '919 農金資訊所屬會員' },
    { 285: '920 農金資訊所屬會員' },
    { 286: '921 農金資訊所屬會員' },
    { 130: '922 臺南農會' },
    { 287: '923 農金資訊所屬會員' },
    { 288: '924 農金資訊所屬會員' },
    { 289: '925 農金資訊所屬會員' },
    { 290: '926 農金資訊所屬會員' },
    { 132: '928 板橋農會' },
    { 291: '929 農金資訊所屬會員' },
    { 292: '930 農金資訊所屬會員' },
    { 293: '931 農金資訊所屬會員' },
    { 294: '932 農金資訊所屬會員' },
    { 295: '933 農金資訊所屬會員' },
    { 296: '934 農金資訊所屬會員' },
    { 297: '935 農金資訊所屬會員' },
    { 298: '936 農金資訊所屬會員' },
    { 299: '937 農金資訊所屬會員' },
    { 300: '938 農金資訊所屬會員' },
    { 301: '939 農金資訊所屬會員' },
    { 302: '940 農金資訊所屬會員' },
    { 133: '951 北農中心' },
    { 134: '952 南部地區農漁會' },
    { 303: '953 農金資訊所屬會員' },
    { 136: '954 中部地區農漁會' },
    { 142: '960 台灣樂天' },
    { 143: '975 美國運通' },
    { 144: '978 台灣永旺' },
    { 304: '984 農金資訊所屬會員' },
    { 305: '985 農金資訊所屬會員' },
    { 306: '986 農金資訊所屬會員' },
    { 307: '987 農金資訊所屬會員' },
    { 308: '988 農金資訊所屬會員' },
    { 309: '989 農金資訊所屬會員' },
    { 137: '995 關貿網路' },
    { 138: '996 財政部國庫署' },
    { 139: '997 南資中心' }
  ]

  return {
    data
  }
}
export default useBank
